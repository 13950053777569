import React from "react";

const ViewsFinishedIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 6.5V1.75C2.5 1.632 2.5 1.573 2.5365 1.5365C2.573 1.5 2.632 1.5 2.75 1.5H4.798C4.898 1.5 4.948 1.5 4.993 1.518C5.038 1.5365 5.0735 1.571 5.1455 1.6405L5.8895 2.3595C5.9615 2.429 5.9975 2.4635 6.0425 2.482C6.0875 2.5 6.1375 2.5 6.2375 2.5H9.335C9.4565 2.5 9.517 2.5 9.5385 2.539C9.5595 2.578 9.527 2.629 9.461 2.731L8.087 4.865C8.045 4.931 8.0235 4.9635 8.0235 5.0005C8.0235 5.037 8.045 5.0705 8.087 5.1355L9.461 7.2695C9.527 7.3715 9.5595 7.4225 9.5385 7.4615C9.517 7.5005 9.4565 7.5005 9.335 7.5005H6.237C6.137 7.5005 6.087 7.5005 6.042 7.4825C5.997 7.464 5.9615 7.4295 5.8895 7.36L5.1455 6.641C5.0735 6.5715 5.0375 6.537 4.9925 6.5185C4.9475 6.5005 4.8975 6.5005 4.7975 6.5005L2.5 6.5ZM2.5 6.5V9.5"
        stroke="#70777F"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ViewsFinishedIcon;
