import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, IconButton, Typography, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthCard from "../components/AuthCard";
import API from "../interceptor/API";

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    passwordTwo: "",
    showPasswordOne: true,
    showPasswordTwo: true,
  });

  const { username, password, passwordTwo, showPasswordOne, showPasswordTwo } =
    formData;

  const handleUserDetails = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const togglePasswordVisibility = (field) => {
    setFormData({ ...formData, [field]: !formData[field] });
  };

  const handleRegister = async () => {
    try {
      const payload = {
        username,
        password,
      };
      const { data, status } = await API.post("/register-admin", payload);
      if (data && status === 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("permissions", data.permissions);
        localStorage.setItem("userId", data.id);
        window.location.href = "/series";
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return (
    <>
      <AuthCard
        title="Sign Up"
        buttonName="Sign Up"
        description="Join us now for exclusive benefits, personalized services, and seamless experience!"
      >
        <TextField
          label="Username"
          onChange={(e) => handleUserDetails(e, "username")}
          value={username}
          data-testid="username"
        />
        <TextField
          label="Password"
          type={showPasswordOne ? "password" : "text"}
          onChange={(e) => handleUserDetails(e, "password")}
          value={password}
          data-testid="password"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => togglePasswordVisibility("showPasswordOne")}
                edge="end"
              >
                {showPasswordOne ? (
                  <VisibilityOff sx={{ fontSize: "14px" }} />
                ) : (
                  <Visibility sx={{ fontSize: "14px" }} />
                )}
              </IconButton>
            ),
          }}
        />
        <TextField
          label="Repeat Password"
          type={showPasswordTwo ? "password" : "text"}
          onChange={(e) => handleUserDetails(e, "passwordTwo")}
          value={passwordTwo}
          data-testid="repeat-password"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => togglePasswordVisibility("showPasswordTwo")}
                edge="end"
              >
                {showPasswordTwo ? (
                  <VisibilityOff sx={{ fontSize: "14px" }} />
                ) : (
                  <Visibility sx={{ fontSize: "14px" }} />
                )}
              </IconButton>
            ),
          }}
        />

        {(password !== passwordTwo || password === "" || username === "") && (
          <Box color="red" textAlign="center" fontSize="12px">
            {password === ""
              ? "Password cannot be empty"
              : password !== passwordTwo
              ? "Passwords do not match"
              : username === ""
              ? "Username cannot be empty"
              : ""}
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          className="primaryButton"
          onClick={handleRegister}
          disabled={
            password !== passwordTwo || password === "" || username === ""
          }
        >
          Register
        </Button>

        <Typography
          sx={{
            textAlign: "center",
            fontSize: "12px",
            fontWeight: 400,
            color: "var(--subHeadingColor)",
          }}
        >
          Already have an account?{" "}
          <span
            style={{ color: "var(--primaryBlue)", cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Log In
          </span>
        </Typography>
      </AuthCard>
    </>
  );
};

export default Register;
