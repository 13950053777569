import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import EditIcon from "../icons/EditIcon";
import Delete from "../icons/Delete";
import { fontSize } from "@mui/system";

const WheelTableBody = ({
  rowData,
  handleDeleteConfirmation,
  handleEdit,
  handleDeleteWheelConfig,
}) => {
  return (
    <TableBody>
      {rowData?.length > 0 &&
        rowData?.map?.((config, index) => (
          <TableRow
            key={index}
            style={{
              padding: "20px",
            }}
          >
            <TableCell>
              <TableData data-testid={`config-label-${index}`}>
                {config?.label}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData data-testid={`config-value-${index}`}>
                {config?.value}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData data-testid={`config-type-${index}`}>
                {config?.type}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData data-testid={`config-percentageDrop-${index}`}>
                {config?.percentageDrop}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData>
                {moment(config?.createdAt).format("YYYY-MM-DD")}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData>
                {moment(config?.updatedAt).format("YYYY-MM-DD")}
              </TableData>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography onClick={() => handleEdit({ ...config, index })}>
                  <EditIcon />
                </Typography>
                <Typography
                  data-testid={`delete-btn-${index}`}
                  onClick={() =>
                    handleDeleteWheelConfig
                      ? handleDeleteWheelConfig(index)
                      : handleDeleteConfirmation(config)
                  }
                >
                  <Delete />
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default WheelTableBody;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
