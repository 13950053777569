import { Box, Pagination } from "@mui/material";
import React from "react";

const CustomPagination = ({ totalPages, activePage, handleChange }) => {
  return (
    <Box display="flex" width="100%" justifyContent="center" mt={4} pb={4}>
      <Pagination
        color="primary"
        count={totalPages}
        page={activePage}
        onChange={handleChange}
        size="large"
      />
    </Box>
  );
};

export default CustomPagination;
