import { Box, Typography } from "@mui/material";
import React from "react";

const AuthCard = ({ title, children, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        backgroundImage: `url('/backgroundImage.png')`,
        backgroundSize: "cover", // or "contain" depending on your preference
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          mx: "auto",
          mt: 4,
          boxShadow: "0px 1px 2px 0px #A4ACB933",
          background: "#fff",
          padding: "60px 30px",
          border: "1px solid #E3E7EB",
          borderRadius: "5px",
        }}
      >
        <span className="pageHeading" style={{ textAlign: "center" }}>
          {title}
        </span>

        <Typography
          sx={{
            color: "var(--subHeadingColor)",
            fontSize: "12px",
            textAlign: "center",
            marginBottom: "16px",
            fontWeight: 400,
          }}
        >
          {description}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            mx: "auto",
            mt: 1,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthCard;
