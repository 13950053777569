import { Button, Grid, Typography } from "@mui/material";
import { Box, lineHeight, textTransform } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import API from "../../interceptor/API";
import heroImg1 from "../../resources/heroImg1.jpg";
import moment from "moment";
import Footer from "../../components/Footer";
import Loading from "./Loading";
import Typo from "../../components/Typography/Typo";

const InteractiveStories = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [interactiveStories, setInteractiveStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInteractiveStories = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(
        `/interactive-stories?page=${activePage}`
      );
      if (data && status === 200) {
        setInteractiveStories(data?.interactiveStories);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInteractiveStories();
  }, [activePage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ bgcolor: "white" }}>
      <Header />

      <Box sx={{ position: "relative" }}>
        <img
          src={heroImg1}
          alt="Cover"
          style={{ height: "573px", width: "100%" }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 3,
            left: 0,
            width: "40%",
            height: "fit-content",
            background: "rgba(88, 88, 88, 0.62)",
            color: "white",
            px: 8,
            py: 10,
            backdropFilter: "blur(15px)",
          }}
        >
          <Typo
            as="h1"
            fontType="58_Regular"
            style={{ marginBottom: 2, textTransform: "uppercase" }}
            data-testid="story-title"
          >
            {interactiveStories[0]?.title}
          </Typo>
          <Box
            sx={{ display: "flex", my: 2, fontSize: "16px", fontWeight: "500" }}
          >
            <Typo as="p" fontType="14_Regular">
              Language- {interactiveStories[0]?.language}
            </Typo>

            <Typo as="p" fontType="14_Regular" style={{ marginLeft: "16px" }}>
              {moment(interactiveStories[0]?.createdAt).format("YYYY-MM-DD")}{" "}
            </Typo>
          </Box>

          <Typo
            as="h3"
            fontType="14_Regular"
            style={{
              color: "#E3E7EB",
              marginBottom: "16px",
              lineHeight: "20px",
            }}
            data-testid="story-description"
          >
            {interactiveStories[0]?.description}
          </Typo>

          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              width: "50%",
              bgcolor: "var(--lightBlue)",
            }}
            onClick={() =>
              navigate(`/user/interactive-stories/${interactiveStories[0]?.id}`)
            }
          >
            WATCH NOW
          </Button>
        </Box>
      </Box>
      <Box p={8}>
        <Box>
          <Typo
            fontType="18_Regular"
            as="p"
            style={{
              color: "var(--lightGray)",
              borderLeft: "3px solid var(--primaryBlue)",
              padding: "6px 10px",
              borderRadius: "2px",
            }}
          >
            Recommended For You
          </Typo>
        </Box>
        <Grid container spacing={2}>
          {interactiveStories?.map((story, index) => (
            <Grid item xs={12} sm={6} md={3} key={story.id}>
              <Box
                sx={{ position: "relative", mt: 3, width: "100%" }}
                data-testid={`story-${index}`}
              >
                <img
                  src={story?.cover}
                  alt={story?.title}
                  style={{
                    height: "459px",
                    width: "100%",
                    display: "block",
                    margin: "0 auto",
                    objectFit: "cover",
                  }}
                  data-testid={`story-img-${story.title}`}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                    color: "white",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typo as="span" fontType="18_Regular">
                    {story?.title}
                  </Typo>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                  >
                    <Typo
                      as="span"
                      fontType="14_Regular"
                      style={{ color: "E3E7EB" }}
                    >
                      Language : {story?.language}
                    </Typo>
                    <Typo
                      as="span"
                      fontType="14_Regular"
                      style={{ color: "E3E7EB" }}
                    >
                      Created At :{" "}
                      {moment(story?.createdAt).format("YYYY-MM-DD")}
                    </Typo>
                  </Box>
                  <Button
                    sx={{
                      color: "white",
                      bgcolor: "var(--lightBlue)",
                      height: "44px",
                      width: "100%",
                      mt: 2,
                      borderRadius: "8px",
                    }}
                    onClick={() =>
                      navigate(`/user/interactive-stories/${story?.id}`)
                    }
                  >
                    WATCH NOW
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
};

export default InteractiveStories;
