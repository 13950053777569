import { TableCell, TableHead, Typography } from "@mui/material";
import React from "react";

const WheelTableHead = () => {
  return (
    <TableHead sx={{ backgroundColor: "lightgray" }}>
      <TableCell>Label</TableCell>
      <TableCell>Value</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Probability</TableCell>
      <TableCell>Created At</TableCell>
      <TableCell>Updated At</TableCell>
      <TableCell>Actions</TableCell>
    </TableHead>
  );
};

export default WheelTableHead;
