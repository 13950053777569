import React from "react";

const ExperimentsIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3332 1.41669V12.75C11.3332 13.5015 11.0347 14.2221 10.5033 14.7535C9.97195 15.2848 9.25128 15.5834 8.49984 15.5834C7.74839 15.5834 7.02772 15.2848 6.49637 14.7535C5.96501 14.2221 5.6665 13.5015 5.6665 12.75V1.41669"
        stroke={fill || "#4A4E5A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66659 6.06052C6.29629 5.24381 7.30284 5.83244 8.49992 6.60027C10.0738 7.61035 11.0188 6.83544 11.3333 6.10231M4.95825 1.41669H12.0416M7.79159 12.7514L7.79796 12.75M9.20825 9.9181L9.21463 9.91669"
        stroke={fill || "#4A4E5A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExperimentsIcon;
