import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import CustomSpinningWheel from "../components/CustomSpinningWheel";

const SpinTabsModal = ({
  openSpinModal,
  setOpenSpinModal,
  wheelConfigDataByTab,
  activeSpinItem,
  setActiveSpinItem,
  handleFinished,
  handleAddConfig,
  handleEdit,
  createSpinModalOpen,
  spinAll,
  setSpinAll,
  handleDeleteWheelConfig,
  closeSpinAllPopup,
  setCloseSpinAllPopup,
  setWheelConfigDataByTab,
}) => {
  return (
    <Modal
      open={openSpinModal}
      onClose={() => {
        setOpenSpinModal(false);
      }}
      style={{
        margin: "70px",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
        }}
      >
        <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
          Click on Spin Button At the Bottom
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {Object.keys(wheelConfigDataByTab).map((item) => (
            <button
              style={{
                border: "none",
                background: item === activeSpinItem ? "#6366F1" : "#fff",
                color: item === activeSpinItem ? "#fff" : "#000",
                height: "20px",
              }}
              onClick={() => setActiveSpinItem(item)}
            >
              {item.split("-").at(1)}
            </button>
          ))}
        </div>
        <div>
          {Object.keys(wheelConfigDataByTab).map((item, index) => (
            <div className="wheelDiv">
              <div
                style={{
                  marginTop: 10,
                  visibility: activeSpinItem !== item && "hidden",
                  height: activeSpinItem !== item && "0px",
                }}
              >
                <CustomSpinningWheel
                  wheelConfig={wheelConfigDataByTab[`tab-${index + 1}`]}
                  onFinished={handleFinished}
                  handleAddConfig={handleAddConfig}
                  handleEdit={handleEdit}
                  createSpinModalOpen={createSpinModalOpen}
                  id={`tab-${index + 1}`}
                  spinAll={spinAll}
                  setSpinAll={setSpinAll}
                  handleDeleteWheelConfig={handleDeleteWheelConfig}
                  closeSpinAllPopup={closeSpinAllPopup}
                  setCloseSpinAllPopup={setCloseSpinAllPopup}
                  setWheelConfigDataByTab={setWheelConfigDataByTab}
                />
              </div>
            </div>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default SpinTabsModal;
