import React from "react";

const SnoozeIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14603 8.47705H7.85436V7.66455H6.3377L7.85436 5.9583V5.22705H5.14603V6.03955H6.68978L5.14603 7.77289V8.47705ZM6.5002 11.9166C5.82311 11.9166 5.189 11.7881 4.59786 11.531C4.00672 11.2739 3.49214 10.9263 3.05411 10.4883C2.61608 10.0502 2.26851 9.53565 2.0114 8.94451C1.75429 8.35337 1.62556 7.71908 1.6252 7.04164C1.62483 6.36419 1.75357 5.73008 2.0114 5.1393C2.26924 4.54853 2.61663 4.03394 3.05357 3.59555C3.49051 3.15717 4.0051 2.8096 4.59732 2.55285C5.18954 2.2961 5.82383 2.16736 6.5002 2.16664C7.17656 2.16592 7.81085 2.29465 8.40307 2.55285C8.99529 2.81104 9.50988 3.15861 9.94682 3.59555C10.3838 4.0325 10.7313 4.54708 10.9895 5.1393C11.2477 5.73153 11.3763 6.36564 11.3752 7.04164C11.3741 7.71764 11.2456 8.35193 10.9895 8.94451C10.7335 9.5371 10.3859 10.0517 9.94682 10.4883C9.50771 10.9248 8.99313 11.2724 8.40307 11.531C7.81301 11.7895 7.17872 11.9181 6.5002 11.9166ZM3.03353 1.27289L3.79186 2.03122L1.48978 4.33331L0.731445 3.57497L3.03353 1.27289ZM9.96686 1.27289L12.2689 3.57497L11.5106 4.33331L9.20853 2.03122L9.96686 1.27289ZM6.5002 10.8333C7.55645 10.8333 8.45254 10.4655 9.18849 9.72993C9.92443 8.99435 10.2922 8.09825 10.2919 7.04164C10.2915 5.98503 9.92371 5.08911 9.18849 4.35389C8.45326 3.61867 7.55717 3.25069 6.5002 3.24997C5.44322 3.24925 4.54731 3.61722 3.81245 4.35389C3.07758 5.09056 2.70961 5.98647 2.70853 7.04164C2.70745 8.0968 3.07542 8.9929 3.81245 9.72993C4.54947 10.467 5.44539 10.8347 6.5002 10.8333Z"
        fill="#6466F1"
      />
    </svg>
  );
};

export default SnoozeIcon;
