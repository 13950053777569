import React from "react";

const ApiStats = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4583 5.66669C14.6319 5.66669 15.5833 4.71529 15.5833 3.54169C15.5833 2.36808 14.6319 1.41669 13.4583 1.41669C12.2846 1.41669 11.3333 2.36808 11.3333 3.54169C11.3333 4.71529 12.2846 5.66669 13.4583 5.66669Z"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95825 9.20831H8.49992"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95825 12.0417H11.3333"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91675 1.41669H6.37508C2.83341 1.41669 1.41675 2.83335 1.41675 6.37502V10.625C1.41675 14.1667 2.83341 15.5834 6.37508 15.5834H10.6251C14.1667 15.5834 15.5834 14.1667 15.5834 10.625V7.08335"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ApiStats;
