import { Box, Modal, Tooltip } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const SpinAnalyticsModal = ({
  openAnalyticsModal,
  setOpenAnalyticsModal,
  spinHistory,
}) => {
  return (
    <Modal
      open={openAnalyticsModal}
      onClose={() => {
        setOpenAnalyticsModal(false);
      }}
      style={{ overflow: "hidden" }}
      className="analyticsModal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
        }}
      >
        <h2 className="modalTitle">Spinning Wheel Analytics</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={spinHistory}
            margin={{ top: 20, right: 30, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="noOfSpin" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="diamond"
              stroke="#8884d8"
              name="Diamonds"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="coins"
              stroke="#fec658"
              name="Coins"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Modal>
  );
};

export default SpinAnalyticsModal;
