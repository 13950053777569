import AttractionsIcon from "@mui/icons-material/Attractions";
import {
  Button,
  Card,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ConfirmationBox from "../components/ConfirmationPopup";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import Delete from "../components/icons/Delete";
import EditIcon from "../components/icons/EditIcon";
import WheelConfigIcon from "../components/icons/WheelConfigIcon";
import API from "../interceptor/API";
import CreateSpinConfigurationModal from "../modals/CreateSpinConfigurationModal";
import SpinAnalyticsModal from "../modals/SpinAnalyticsModal";
import SpinWheelModal from "../modals/SpinWheelModal";
import { SPINNING_WHEEL_HEAD } from "../utils/constants";

const WheelSimulation = () => {
  const pageSize = 100;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [createSpinModalOpen, setCreateSpinModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [spinToDelete, setSpinToDelete] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [wheelConfig, setWheelConfig] = useState([]);
  const [isConfigCreating, setIsConfigCreating] = useState(false);
  const [openSpinModal, setOpenSpinModal] = useState(false);
  const [userID, setUserID] = useState("");
  const [openAnalyticsModal, setOpenAnalyticsModal] = useState(false);
  const [countOfDiamonds, setCountOfDiamonds] = useState(0);
  const [countOfCoins, setCountOfCoins] = useState(0);
  const [totalSpins, setTotalSpins] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [availableSpins, setAvailableSpins] = useState(100);
  const [spinHistory, setSpinHistory] = useState([]);
  const [finished, setFinished] = useState(false); // State variable to trigger re-render

  const [configFields, setConfigFields] = useState({
    label: "",
    value: 0,
    percentageDrop: 0,
    type: "",
    isDivisible: true,
  });

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      const user = localStorage.getItem("userId");
      setUserID(user);
    }
  }, []);

  const handleDeleteConfirmation = (reward) => {
    setSpinToDelete(reward);
    setDeleteConfirmation(true);
  };

  const fetchSpinningWheels = async () => {
    try {
      const { data, status } = await API.get(
        `/wheelConfig?page=${page}&pageSize=100`
      );

      if (data && status === 200) {
        setWheelConfig(data?.wheelConfigs);
        setTotalPages(Math.ceil(data?.pagination?.totalCount / pageSize));
      }
    } catch (error) {
      console.error("Error fetching series data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSpinningWheels();
  }, [page, refresh]);

  const createConfig = async () => {
    try {
      setIsConfigCreating(true);
      const { data, status } = await API.post("/wheelConfig", configFields);
      if (data && status === 200) {
        setRefresh({});
        setCreateSpinModalOpen(false);
        setConfigFields({});
        enqueueSnackbar("Wheel Configuration Created Successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfigCreating(false);
    }
  };

  const handleDeleteWheelConfig = async () => {
    try {
      const { data, status } = await API.delete(
        `/wheelConfig/${spinToDelete.id}`
      );
      if (data && status === 200) {
        setRefresh({});
        enqueueSnackbar(data.message, {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error deleting reward:", error);
      enqueueSnackbar("Failed to delete wheel configuration", {
        variant: "error",
      });
    } finally {
      setDeleteConfirmation(false);
    }
  };

  const fetchSpinningDetails = async (id) => {
    try {
      const { data, status } = await API.get(`/wheelConfig/${id}`);
      if (data && status === 200) {
        setConfigFields({
          label: data?.label,
          value: data?.value,
          percentageDrop: data?.percentageDrop,
          type: data?.type,
          isDivisible: data?.isDivisible,
        });
      }
    } catch (error) {
      console.error("Error fetching series data:", error);
    } finally {
    }
  };

  const updateSpinningDetails = async () => {
    try {
      setIsConfigCreating(true);
      const { data, status } = await API.put(
        `/wheelConfig/${isEdited}`,
        configFields
      );
      if (data && status === 200) {
        setRefresh({});
        setCreateSpinModalOpen(false);
        enqueueSnackbar("Wheel Configuration Updated successfully!", {
          variant: "success",
        });
        setConfigFields({});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfigCreating(false);
    }
  };

  // Define the segments array with custom probabilities

  const segments = wheelConfig?.map((config) => {
    return {
      name: config.label,
      probability: config.percentageDrop,
      type: config.type,
      value: config.value,
      isDivisible: config.isDivisible,
    };
  });

  const handleTypeChange = (e) => {
    setConfigFields({
      ...configFields,
      type: e.target.value,
    });
  };

  useEffect(() => {
    const storedTotalSpins = localStorage.getItem("totalSpins");
    const storedDiamondCounts = localStorage.getItem("diamondCounts");
    const storedCoinCounts = localStorage.getItem("coinCounts");
    const storedRemainingCounts = localStorage.getItem("remainingSpins");
    const spinHistories = JSON.parse(localStorage.getItem("spinHistory"));
    if (storedTotalSpins) {
      setTotalSpins(parseInt(storedTotalSpins));
    }
    if (storedDiamondCounts) {
      setCountOfDiamonds(parseInt(storedDiamondCounts));
    }
    if (storedCoinCounts) {
      setCountOfCoins(parseInt(storedCoinCounts));
    }
    if (storedRemainingCounts) {
      setAvailableSpins(parseInt(storedRemainingCounts));
    }
    if (spinHistories) {
      setSpinHistory(spinHistories);
    }
  }, []);

  const handleFinished = (segment) => {
    enqueueSnackbar(`You win ${segment?.name} ${segment?.type}`, {
      variant: "success",
    });

    const remainingSpins = availableSpins - 1;
    setAvailableSpins(parseInt(remainingSpins));
    localStorage.setItem("remainingSpins", remainingSpins);

    const newTotalSpins = totalSpins + 1;
    setTotalSpins(newTotalSpins);
    localStorage.setItem("totalSpins", newTotalSpins);

    let newDiamondSpins = countOfDiamonds;

    let newCoinSpins = countOfCoins;

    if (segment.type === "Duplicate") {
      setMultiplier(parseInt(segment.value));
    } else if (segment?.type === "Diamonds") {
      newDiamondSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    } else if (segment?.type === "Coins") {
      newCoinSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    } else if (segment?.type === "Experience") {
      newCoinSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    }

    const updatedSpinHistory = [
      ...spinHistory,
      {
        noOfSpin: newTotalSpins,
        diamond:
          segment.type === "Diamonds" ? newDiamondSpins : countOfDiamonds,
        coins: segment.type === "Coins" ? newCoinSpins : countOfCoins,
      },
    ];

    setSpinHistory(updatedSpinHistory);

    // Update counts of diamonds and coins
    setCountOfDiamonds(newDiamondSpins);
    localStorage.setItem("diamondCounts", newDiamondSpins);

    setCountOfCoins(newCoinSpins);
    localStorage.setItem("coinCounts", newCoinSpins);

    // Store spin history in localStorage
    localStorage.setItem("spinHistory", JSON.stringify(updatedSpinHistory));
    setFinished(true);
  };

  const typeValues = [
    "Coins",
    "Diamonds",
    "Repeat",
    "Free",
    "Duplicate",
    "Experience",
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout
      pageTitle="Spinning Wheel Configuration"
      icon={<WheelConfigIcon fill="#4A4E5A" />}
    >
      <Box>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <p className="pageHeading" data-testid="page-title">
            Spinning Wheel Configuration
          </p>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              className="primaryButton"
              onClick={() => {
                setOpenAnalyticsModal(!openAnalyticsModal);
              }}
              data-testid="analytics-btn"
            >
              <AnalyticsIcon fill="white" />
              &nbsp; Analytics
            </Button>
            <Button
              variant="contained"
              className="primaryButton"
              onClick={() => {
                setOpenSpinModal(!openSpinModal);
              }}
              data-testid="spin-wheel-btn"
            >
              <AttractionsIcon style={{ color: "white", fontSize: "20px" }} />
              &nbsp; Show Spin Wheel
            </Button>

            <Button
              variant="contained"
              className="primaryButton"
              onClick={() => {
                setCreateSpinModalOpen(true);
                setIsEdited(false);
              }}
              data-testid="configuration-btn"
            >
              <AddvariationIcon /> &nbsp; Create Configuration
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            borderRadius: "10px",
            mt: 3,
            overflow: "auto",
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "lightgray" }}>
              <TableRow>
                {SPINNING_WHEEL_HEAD.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {wheelConfig?.length > 0 &&
                wheelConfig?.map?.((config, index) => (
                  <TableRow
                    key={index}
                    style={{
                      padding: "20px",
                    }}
                  >
                    <TableCell>
                      <TableData data-testid={`config-label-${index}`}>
                        {config?.label}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData data-testid={`config-value-${index}`}>
                        {config?.value}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData data-testid={`config-type-${index}`}>
                        {config?.type}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData data-testid={`config-percentageDrop-${index}`}>
                        {config?.percentageDrop}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>
                        {config?.isDivisible ? "Yes" : "No"}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>
                        {moment(config?.createdAt).format("YYYY-MM-DD")}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>
                        {moment(config?.updatedAt).format("YYYY-MM-DD")}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="span"
                          data-testid={`edit-btn-icon-${index}`}
                          onClick={() => {
                            setCreateSpinModalOpen(!createSpinModalOpen);
                            setIsEdited(config.id);
                            fetchSpinningDetails(config?.id);
                            setSpinToDelete(config);
                          }}
                        >
                          <EditIcon />
                        </Typography>
                        <Typography
                          component="span"
                          data-testid={`delete-btn-icon-${index}`}
                          onClick={() => {
                            handleDeleteConfirmation(config);
                          }}
                          mt="2px"
                        >
                          <Delete />
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: "20px",
            }}
          />
        </Card>
      </Box>

      <CreateSpinConfigurationModal
        createSpinModalOpen={createSpinModalOpen}
        setCreateSpinModalOpen={setCreateSpinModalOpen}
        isEdited={isEdited}
        configFields={configFields}
        setConfigFields={setConfigFields}
        typeValues={typeValues}
        updateSpinningDetails={updateSpinningDetails}
        createConfig={createConfig}
        handleTypeChange={handleTypeChange}
        isConfigCreating={isConfigCreating}
      />

      <SpinWheelModal
        openSpinModal={openSpinModal}
        setOpenSpinModal={setOpenSpinModal}
        finished={finished}
        segments={segments}
        handleFinished={handleFinished}
        availableSpins={availableSpins}
      />

      <SpinAnalyticsModal
        openAnalyticsModal={openAnalyticsModal}
        setOpenAnalyticsModal={setOpenAnalyticsModal}
        spinHistory={spinHistory}
      />

      <ConfirmationBox
        open={deleteConfirmation}
        onConfirm={handleDeleteWheelConfig}
        onClose={() => setDeleteConfirmation(false)}
        message="Are you sure you want to delete this spin wheel configuration?"
      />
    </Layout>
  );
};

export default WheelSimulation;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
