import React, { useState } from "react";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { Box, color, textAlign } from "@mui/system";
import { Button, Card, CircularProgress, Typography } from "@mui/material";
import google from "../../resources/googleIcon.png";
import {
  IMAGE_SECTION_ONE,
  IMAGES_SECTION_THREE,
  IMAGES_SECTION_TWO,
} from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import API from "../../interceptor/API";
import Typo from "../../components/Typography/Typo";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSignIn = async (user, token) => {
    try {
      setIsLoading(true);
      const { data, status } = await API.post("/users/link", {
        ...user,
        service: "google",
        token: token.access_token,
      });
      if (data && status === 200) {
        enqueueSnackbar({ message: data?.message, variant: "success" });
        localStorage.setItem("token", data.token);
        localStorage.setItem("userDetails", JSON.stringify(data.user));
        localStorage.setItem("role", "user");
        navigate("/user/interactive-stories");
      }
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserInfo = async (tokenResponse) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        await handleGoogleSignIn(response.data, tokenResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithGoogle = useGoogleLogin({
    scope: "email",
    onSuccess: (tokenResponse) => {
      fetchUserInfo(tokenResponse);
    },
  });

  return (
    <Box m={0}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "60%",
            height: "100vh",
            overflow: "scroll",
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              overflow: "hidden",
              height: "100vh",
            }}
          >
            <div class="marquee-wrapper">
              {IMAGE_SECTION_ONE.map((img, index) => (
                <img
                  fetchpriority="high"
                  src={img}
                  alt="940f3f3922b4"
                  style={{ animationDelay: "2s" }}
                />
              ))}
            </div>
            <div class="column--middle marquee-wrapper">
              {IMAGES_SECTION_TWO.map((img, index) => (
                <img
                  fetchpriority="high"
                  src={img}
                  alt="940f3f3922b4"
                  style={{ animationDelay: "2s" }}
                />
              ))}
            </div>
            <div class="marquee-wrapper">
              {IMAGES_SECTION_THREE.map((img, index) => (
                <img
                  fetchpriority="high"
                  src={img}
                  alt="940f3f3922b4"
                  style={{ animationDelay: "2s" }}
                />
              ))}
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            width: "40%",
            display: "flex",
            height: "100vh",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 10,
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                borderRadius: "5px",
                border: "1px solid var(--borderColor)",
                p: 6,
                position: "relative",
              }}
            >
              <Typo
                as="h3"
                fontType="24_Regular"
                data-testid="page-title"
                style={{ textAlign: "center" }}
              >
                Sign In
              </Typo>

              <Typo
                as="h4"
                fontType="14_Regular"
                style={{ textAlign: "center" }}
                data-testid="description"
              >
                Join us now for exclusive benefits, personalized services, and
                seamless experience!
              </Typo>

              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  border: "1px solid var(--borderColor)",
                  mt: 4,
                  py: 1.5,
                }}
                data-testid="signin-button"
                onClick={signInWithGoogle}
              >
                {isLoading ? (
                  <CircularProgress size={"20px"} />
                ) : (
                  <>
                    <img src={google} alt="google-icon" />
                    <Typo
                      as="span"
                      fontType="14_Regular"
                      style={{ color: "black" }}
                    >
                      Continue with Google
                    </Typo>
                  </>
                )}
              </Button>

              <p
                style={{
                  fontSize: "12px",
                  color: "var(--subHeadingColor)",
                  textAlign: "center",
                  fontWeight: 500,
                  padding: "0 50px",
                  marginTop: "30px",
                  lineHeight: "18px",
                }}
                data-testid="terms-and-conditions"
              >
                If you continue you agree to the{" "}
                <u style={{ cursor: "pointer", color: "var(--primaryBlue)" }}>
                  {" "}
                  Terms of Service
                </u>{" "}
                &
                <u style={{ cursor: "pointer", color: "var(--primaryBlue)" }}>
                  {" "}
                  Privacy Policy
                </u>
              </p>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
