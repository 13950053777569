import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import fetchWithUserToken from "../utils/fetchWithUserToken";

const EditVideoStoryName = ({
  selectedVideo,
  setSelectedVideo,
  setRefresh,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={!!selectedVideo}
      onClose={() => setSelectedVideo(null)}
    >
      <DialogTitle>Edit name</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label="Video Title"
          id="standard-basic"
          defaultValue={selectedVideo?.name}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSelectedVideo(null)}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            fetchWithUserToken(
              `${process.env.REACT_APP_API_ENDPOINT}/videos/${
                selectedVideo.id ? selectedVideo?.id : selectedVideo
              }`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  title: document.getElementById("standard-basic").value,
                }),
              }
            ).then(() => {
              setSelectedVideo(null);
              setRefresh({});
            });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditVideoStoryName;
