import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import fetchWithUserToken from "../utils/fetchWithUserToken";
import AddvariationIcon from "./icons/AddvariationIcon";
import EditIcon from "./icons/EditIcon";
import SettingsIcon from "./icons/SettingsIcon";
import { enqueueSnackbar } from "notistack";
import EditInteractiveStoryModal from "../modals/EditInteractiveStoryModal";
import { useNavigate } from "react-router-dom";
import Typo from "./Typography/Typo";
import API from "../interceptor/API";

const InteractiveStorySidebar = ({
  interactiveStory,
  videos,
  fileInputRef,
  uploading,
  handleFileChange,
  handleAddStateNode,
  openVideoDetails,
  handleVideoDetails,
  setSelectedVideoUrl,
  handleAddVideo,
  setSelectedVideo,
  setRefresh,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Box width={"50%"} bgcolor={"white"}>
      <EditInteractiveStoryModal
        open={open}
        setOpen={setOpen}
        storyId={interactiveStory?.id}
        initialData={interactiveStory}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          gap: "8px",
          borderBottom: "1px solid var(--borderColor)",
        }}
        data-testid="settings"
      >
        <Box
          bgcolor={"#393939"}
          display={"flex"}
          borderRadius={"5px"}
          padding={"5px"}
        >
          <SettingsIcon />
        </Box>
        <Typography variant="h1" sx={{ fontSize: "14px" }}>
          Settings
        </Typography>
        <Button
          sx={{
            ml: "auto",
          }}
          startIcon={<EditIcon />}
          className="primaryButton"
          onClick={() => setOpen(true)}
        >
          Edit title and thumbnail
        </Button>
      </Box>
      {interactiveStory.minLevel && (
        <Box sx={{ padding: "20px" }}>
          <TextField
            label="Min Level to access"
            margin="dense"
            onChange={async (e) => {
              await API.put(`/interactive-stories/${interactiveStory.id}`, {
                minLevel: parseInt(e.target.value),
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Level</InputAdornment>
              ),
            }}
            defaultValue={interactiveStory.minLevel}
            type="number"
            sx={{ width: "100%" }}
          />
        </Box>
      )}

      <Box
        sx={{
          borderBottom: "1px solid var(--borderColor)",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typo
            fontType="10_Regular"
            as="h3"
            style={{ color: "var(--gray)", textTransform: "uppercase" }}
          >
            Statistics
          </Typo>

          <Button
            variant="contained"
            className="primaryButton"
            sx={{ width: "auto" }}
            onClick={() =>
              navigate(`/interactive-stories/view/${interactiveStory?.id}`)
            }
          >
            View story
          </Button>
        </Box>

        <Box sx={{ display: "flex", gap: 7, mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                bgcolor: "#E2FFE8",
                color: "#007F16",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "600",
                display: "flex",
                padding: "6px",
              }}
            >
              {interactiveStory?.nodes?.length || 0}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "600" }}
              variant="h5"
            >
              Win Nodes
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                bgcolor: "#E9E9FF",
                color: "#0004EB",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "600",
                display: "flex",
                padding: "4px 10px",
              }}
            >
              {videos?.length || 0}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "600" }}
              variant="h5"
            >
              Videos
            </Typography>
          </Box>
        </Box>

        <Button
          className="primaryButton"
          sx={{ width: "100%", mt: 3 }}
          variant="contained"
          onClick={() => fileInputRef.current.click()}
        >
          {uploading ? (
            <CircularProgress
              size={20}
              style={{ fontSize: "10px", color: "white" }}
            />
          ) : (
            "Input Video"
          )}
        </Button>
        <FileInput type="file" ref={fileInputRef} onChange={handleFileChange} />
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            className="primaryButton"
            sx={{ width: "100%", mt: 1, bgcolor: "#55B685" }}
            onClick={() => handleAddStateNode("WIN")}
          >
            Add Win Node
          </Button>
          <Button
            variant="contained"
            className="primaryButton"
            sx={{ width: "100%", mt: 1, bgcolor: "#DE5242" }}
            onClick={() => handleAddStateNode("LOSE")}
          >
            Add Lose Node
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}></Box>
      </Box>

      <Box p={"20px"}>
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: 500,
            color: "var(--gray)",
            textTransform: "uppercase",
          }}
        >
          Videos
        </Typography>

        <Box
          sx={{
            height: "450px",
            overflow: "scroll",
          }}
        >
          {videos?.map((video) => (
            <div key={video.id}>
              <Box
                display="flex"
                gap={1}
                mt={3}
                sx={{
                  cursor: "pointer",
                  border:
                    openVideoDetails === video.id
                      ? "1px solid var(--borderColor)"
                      : "none",
                  backgroundColor:
                    openVideoDetails === video.id ? "white" : "transparent",
                  borderRadius: openVideoDetails === video.id ? "8px" : "0",
                  padding: openVideoDetails === video.id ? "10px" : "0",
                  boxShadow:
                    openVideoDetails === video.id
                      ? "-2px 2px 10px 0px #A4ACB91A"
                      : "none",
                  alignItems:
                    openVideoDetails === video.id ? "flex-start" : "center",
                }}
                onClick={() => handleVideoDetails(video.id)}
              >
                <img
                  src={video?.thumbnail}
                  alt="video-thumbnail"
                  height={openVideoDetails[video.id] ? "42px" : "25px"}
                  width={openVideoDetails[video.id] ? "42px" : "25px"}
                  style={{ borderRadius: "5px", objectFit: "cover" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedVideoUrl(video.url);
                  }}
                />
                <Box width={"100%"}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {video?.title}
                    </Typography>

                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <Typography
                        onClick={(e) => {
                          handleAddVideo(video);
                          e.stopPropagation();
                        }}
                      >
                        <AddvariationIcon fill={"#6466F1"} />
                      </Typography>

                      <Typography
                        onClick={(e) => {
                          setSelectedVideo({
                            id: video.id,
                            name: video.title,
                          });
                          e.stopPropagation();
                        }}
                      >
                        <EditIcon height={14} width={14} />
                      </Typography>
                    </Box>
                  </Box>

                  {openVideoDetails === video?.id && (
                    <Select
                      sx={{ height: "35px", mt: 2, width: "100%" }}
                      defaultValue={video.rotation}
                      onChange={(e) => {
                        fetchWithUserToken(
                          `${process.env.REACT_APP_API_ENDPOINT}/videos/${video.id}`,
                          {
                            method: "PUT",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              rotation: e.target.value,
                            }),
                          }
                        ).then(() => {
                          setRefresh({});
                        });
                      }}
                    >
                      <MenuItem value="PORTRAIT">Portrait</MenuItem>
                      <MenuItem value="LANDSCAPE">Landscape</MenuItem>
                    </Select>
                  )}
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InteractiveStorySidebar;

const FileInput = styled.input`
  display: none;
`;

const FormLabels = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const Section = styled.div`
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 2px;
`;
