import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";

const SpinningWheel = ({ segments, onFinished }) => {
  let timerHandle = null;
  let angleCurrent = -Math.PI / 2;
  let angleDelta = 0;
  let canvasContext = null;
  let maxSpeed = 0;
  let upTime = 0;
  let downTime = 0;
  let spinStart = 0;
  const centerX = 300;
  const centerY = 300;
  let probWinningIndex = null;
  let winningIndex = null;

  const iconMap = {
    Coins: "coins.png",
    Diamonds: "diamond.png",
    Duplicate: "duplicate.png",
    Repeat: "repeat.png",
    Experience: "experience.png",
  };

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      canvasContext = context;
    }

    canvasContext.lineWidth = 1;
    canvasContext.strokeStyle = "black";
    canvasContext.textBaseline = "middle";
    canvasContext.textAlign = "center";
    canvasContext.font = "1em sans-serif";

    draw();

    return () => {
      if (timerHandle) {
        clearInterval(timerHandle);
      }
    };
  }, []);

  const spin = () => {
    if (timerHandle) {
      return;
    }
    angleCurrent = -Math.PI / 2;
    angleDelta = 0;
    maxSpeed = Math.PI / segments.length;
    upTime = 0;
    downTime = 0;
    spinStart = new Date().getTime();
    probWinningIndex = selectWinningSegment();

    const totalSpinDuration = 2000;
    upTime = totalSpinDuration / 2;
    downTime = totalSpinDuration / 2;

    // Reinitialize the drawing
    draw();

    timerHandle = setInterval(onTimerTick, 1000 / 60);
  };

  const onTimerTick = () => {
    let duration = new Date().getTime() - spinStart;

    draw();
    let progress = 0;

    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else if (duration >= upTime + downTime) {
      duration = 1900 - (upTime + downTime);

      if (winningIndex === probWinningIndex) {
        angleDelta *= 0.98;
        if (Math.abs(angleDelta) < 0.001) {
          clearInterval(timerHandle);
          timerHandle = null;
          onFinished(segments[winningIndex]);
          return;
        }
      }
    } else {
      progress = (duration - upTime) / downTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
    }

    angleCurrent += angleDelta;

    while (angleCurrent >= Math.PI * 2) {
      angleCurrent -= Math.PI * 2;
    }
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const clear = () => {
    if (!canvasContext) return;
    canvasContext.clearRect(0, 0, 1000, 800);
  };

  const drawSegment = (key, lastAngle, angle) => {
    if (!canvasContext) return;
    const value = segments[key].name;
    const iconSize = 30;
    const iconSpacing = 100;

    canvasContext.save();
    canvasContext.beginPath();
    canvasContext.moveTo(centerX, centerY);
    canvasContext.arc(centerX, centerY, 290, lastAngle, angle, false);
    canvasContext.lineTo(centerX, centerY);
    canvasContext.closePath();

    const fillColor = key % 2 === 0 ? "darkBlue" : "lightBlue";
    canvasContext.fillStyle = fillColor;
    canvasContext.fill();
    canvasContext.stroke();

    canvasContext.save();
    canvasContext.translate(centerX, centerY);
    canvasContext.rotate((lastAngle + angle) / 2);
    canvasContext.fillStyle = "white";
    canvasContext.font = "bold 1em sans-serif";
    canvasContext.fillText(value.substr(0, 21), 290 / 2 + 20, 0);
    canvasContext.restore();

    const iconType = segments[key].type;
    const iconURL = iconMap[iconType];
    const image = new Image();
    image.src = iconURL;

    image.onload = () => {
      canvasContext.drawImage(
        image,
        centerX +
          Math.cos((lastAngle + angle) / 2) * (290 / 2 + iconSpacing) -
          iconSize / 2,
        centerY +
          Math.sin((lastAngle + angle) / 2) * (290 / 2 + iconSpacing) -
          iconSize / 2,
        iconSize,
        iconSize
      );
    };
  };

  const drawWheel = () => {
    if (!canvasContext) return;

    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;

    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    canvasContext.beginPath();
    canvasContext.arc(centerX, centerY, 50, 0, PI2, false);
    canvasContext.closePath();
    canvasContext.fillStyle = "lightBlue";
    canvasContext.lineWidth = 10;
    canvasContext.strokeStyle = "darkBlue";
    canvasContext.fill();
    canvasContext.font = "bold 1em sans-serif";
    canvasContext.fillStyle = "white";
    canvasContext.textAlign = "center";
    canvasContext.fillText("", centerX, centerY + 3);
    canvasContext.stroke();
    canvasContext.beginPath();
    canvasContext.arc(centerX, centerY, 290, 0, PI2, false);
    canvasContext.closePath();
    canvasContext.lineWidth = 10;
    canvasContext.strokeStyle = "darkBlue";
    canvasContext.stroke();
  };

  const drawNeedle = () => {
    if (!canvasContext) return;

    canvasContext.lineWidth = 1;
    canvasContext.strokeStyle = "red";
    canvasContext.beginPath();
    canvasContext.moveTo(centerX + 20, centerY - 50);
    canvasContext.lineTo(centerX - 20, centerY - 50);
    canvasContext.lineTo(centerX, centerY - 70);
    canvasContext.closePath();
    canvasContext.fill();
    const change = angleCurrent + Math.PI / 2;
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1;
    if (i < 0) i = i + segments.length;
    winningIndex = i;
  };

  const selectWinningSegment = () => {
    const totalProbability = segments.reduce(
      (total, segment) => total + segment.probability,
      0
    );

    const randomNumber = Math.random() * totalProbability;

    let cumulativeProbability = 0;
    for (let i = 0; i < segments.length; i++) {
      cumulativeProbability += segments[i].probability;
      if (randomNumber <= cumulativeProbability) {
        return i;
      }
    }

    return segments.length - 1;
  };

  return (
    <div>
      <canvas
        id="canvas"
        width="1000"
        height="600"
        style={{
          pointerEvents: "auto",
        }}
      />
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className="primaryButton"
          onClick={spin}
          sx={{ mt: 2 }}
        >
          Spin
        </Button>
      </Box>
    </div>
  );
};

export default SpinningWheel;
