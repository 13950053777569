import React from "react";

const EventsIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66675 4.07294C5.37633 4.07294 5.1355 3.8321 5.1355 3.54169V1.41669C5.1355 1.12627 5.37633 0.885437 5.66675 0.885437C5.95716 0.885437 6.198 1.12627 6.198 1.41669V3.54169C6.198 3.8321 5.95716 4.07294 5.66675 4.07294Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M11.3333 4.07294C11.0428 4.07294 10.802 3.8321 10.802 3.54169V1.41669C10.802 1.12627 11.0428 0.885437 11.3333 0.885437C11.6237 0.885437 11.8645 1.12627 11.8645 1.41669V3.54169C11.8645 3.8321 11.6237 4.07294 11.3333 4.07294Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M14.5209 6.96997H2.47925C2.18883 6.96997 1.948 6.72914 1.948 6.43872C1.948 6.1483 2.18883 5.90747 2.47925 5.90747H14.5209C14.8113 5.90747 15.0522 6.1483 15.0522 6.43872C15.0522 6.72914 14.8113 6.96997 14.5209 6.96997Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M11.3333 16.1146H5.66667C3.08125 16.1146 1.59375 14.6271 1.59375 12.0417V6.02085C1.59375 3.43544 3.08125 1.94794 5.66667 1.94794H11.3333C13.9187 1.94794 15.4062 3.43544 15.4062 6.02085V12.0417C15.4062 14.6271 13.9187 16.1146 11.3333 16.1146ZM5.66667 3.01044C3.64083 3.01044 2.65625 3.99502 2.65625 6.02085V12.0417C2.65625 14.0675 3.64083 15.0521 5.66667 15.0521H11.3333C13.3592 15.0521 14.3438 14.0675 14.3438 12.0417V6.02085C14.3438 3.99502 13.3592 3.01044 11.3333 3.01044H5.66667Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M6.02083 10.2709C5.92875 10.2709 5.83666 10.2496 5.75166 10.2142C5.66666 10.1788 5.58876 10.1292 5.51792 10.0654C5.45417 9.99461 5.40457 9.9167 5.36916 9.8317C5.33374 9.7467 5.3125 9.65461 5.3125 9.56253C5.3125 9.37836 5.39042 9.1942 5.51792 9.05962C5.58876 8.99587 5.66666 8.94627 5.75166 8.91085C5.87916 8.85419 6.02084 8.84002 6.16251 8.86835C6.20501 8.87544 6.2475 8.8896 6.29 8.91085C6.3325 8.92502 6.375 8.94629 6.4175 8.97462C6.45291 9.00295 6.48833 9.03128 6.52374 9.05962C6.55208 9.09503 6.58749 9.13045 6.60874 9.16586C6.63707 9.20836 6.65834 9.25086 6.67251 9.29336C6.69376 9.33586 6.70792 9.37835 6.71501 9.42085C6.72209 9.47044 6.72917 9.51295 6.72917 9.56253C6.72917 9.7467 6.65124 9.93086 6.52374 10.0654C6.38916 10.1929 6.205 10.2709 6.02083 10.2709Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M8.50008 10.2708C8.31591 10.2708 8.13175 10.1929 7.99717 10.0654C7.96884 10.03 7.94051 9.99455 7.91217 9.95914C7.88384 9.91664 7.86257 9.87414 7.84841 9.83164C7.82716 9.78914 7.81299 9.74665 7.80591 9.70415C7.79882 9.65456 7.79175 9.61205 7.79175 9.56247C7.79175 9.47039 7.81299 9.3783 7.84841 9.2933C7.88382 9.2083 7.93342 9.13039 7.99717 9.05956C8.1955 8.86123 8.51425 8.79746 8.76925 8.9108C8.86133 8.94621 8.93216 8.99581 9.00299 9.05956C9.13049 9.19414 9.20841 9.3783 9.20841 9.56247C9.20841 9.61205 9.20134 9.65456 9.19426 9.70415C9.18717 9.74665 9.17301 9.78914 9.15176 9.83164C9.13759 9.87414 9.11632 9.91664 9.08799 9.95914C9.05966 9.99455 9.03133 10.03 9.00299 10.0654C8.93216 10.1291 8.86133 10.1787 8.76925 10.2141C8.68425 10.2496 8.59216 10.2708 8.50008 10.2708Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M6.02083 12.75C5.92875 12.75 5.83666 12.7288 5.75166 12.6933C5.66666 12.6579 5.58876 12.6083 5.51792 12.5446C5.45417 12.4737 5.40457 12.4029 5.36916 12.3108C5.33374 12.2258 5.3125 12.1337 5.3125 12.0417C5.3125 11.8575 5.39042 11.6733 5.51792 11.5387C5.58876 11.475 5.66666 11.4254 5.75166 11.39C6.01375 11.2766 6.32541 11.3404 6.52374 11.5387C6.55208 11.5742 6.58749 11.6096 6.60874 11.645C6.63707 11.6875 6.65834 11.73 6.67251 11.7725C6.69376 11.815 6.70792 11.8575 6.71501 11.9071C6.72209 11.9496 6.72917 11.9992 6.72917 12.0417C6.72917 12.2258 6.65124 12.41 6.52374 12.5446C6.38916 12.6721 6.205 12.75 6.02083 12.75Z"
        fill={fill || "#4A4E5A"}
      />
    </svg>
  );
};

export default EventsIcon;
