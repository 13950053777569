import {
  Avatar,
  Button,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Box, textTransform } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { enqueueSnackbar } from "notistack";
import Typo from "./Typography/Typo";

const items = ["Featured", "Interactive Stories", "Wishlist"];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const normalizedPath = location.pathname?.toLowerCase();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const data = JSON.parse(localStorage.getItem("userDetails"));
      setUserDetails(data);
    }
  }, []);

  const handleLogOut = async () => {
    localStorage.removeItem("role");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    enqueueSnackbar({
      variant: "success",
      message: "Logged out successfully!",
    });
    navigate("/login");
  };

  return (
    <Box
      px={6}
      py={1.5}
      display="flex"
      justifyContent="space-between"
      bgcolor="white"
      border="1px solid var(--borderColor)"
      zIndex={5}
      position={"relative"}
    >
      <Box display={"flex"} gap={4} alignItems={"center"}>
        <img src={"/images/logo.svg"} alt="logo" />
        {items.map((item, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: "15px",
              fontWeight: normalizedPath.includes(
                item.toLowerCase().replace(/ /g, "-")
              )
                ? 600
                : 500,
              color: normalizedPath.includes(
                item.toLowerCase().replace(/ /g, "-")
              )
                ? "var(--primaryBlue)"
                : "#000209",
            }}
            data-testid={`header-items-${index}`}
          >
            {item}
          </Typography>
        ))}
        <TextField
          variant="outlined"
          placeholder="Search here"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: "18px" }} />
              </InputAdornment>
            ),
            style: {
              height: "100%",
              padding: "8px 14px",
              boxSizing: "border-box",
            },
          }}
          style={{
            width: "320px",
            border: "1px solid var(--borderColor)",
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "#F4F4F4",
            marginLeft: "20px",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
        data-testid="user-profile"
      >
        <Avatar sx={{ bgcolor: "var(--primaryBlue)" }}>
          {userDetails?.name?.slice(0, 1).toUpperCase()}
        </Avatar>

        <Typo as="span" fontType="14_Regular" style={{ color: "#000209" }}>
          {userDetails?.name}
        </Typo>
        <ArrowDropDownSharpIcon />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1.5 }}
      >
        <Box sx={{ py: 2, px: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar sx={{ bgcolor: "var(--primaryBlue)" }}>
              {userDetails?.name?.slice(0, 1).toUpperCase()}
            </Avatar>
            <Box>
              <Typo
                as="p"
                fontType="14_Regular"
                style={{ color: "#000209", textTransform: "capitalize" }}
              >
                {userDetails?.name}
              </Typo>
              <Typo as="span" fontType="14_Regular">
                {userDetails?.email}
              </Typo>
            </Box>
          </Box>
          <hr style={{ marginTop: "18px" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mt: 2,
              ml: 1,
              cursor: "pointer",
            }}
            onClick={handleLogOut}
          >
            <LogoutIcon />

            <Typo as="p" fontType="14_Regular" style={{ color: "#000209" }}>
              Log Out
            </Typo>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Header;
