import React from "react";
import AdminIcon from "../components/icons/AdminIcon";
import AlertIcon from "../components/icons/AlertIcon";
import ApiStats from "../components/icons/ApiStats";
import ChannelsIcon from "../components/icons/ChannelsIcon";
import CommentIcon from "../components/icons/CommentIcon";
import EventsIcon from "../components/icons/EventsIcon";
import ExperimentsIcon from "../components/icons/ExperimentsIcon";
import LoginIcon from "../components/icons/LoginIcon";
import Logs from "../components/icons/Logs";
import NotificationsIcon from "../components/icons/NotificationsIcon";
import ReviewsIcon from "../components/icons/ReviewsIcon";
import RewardsIcon from "../components/icons/RewardsIcon";
import SeriesIcon from "../components/icons/SeriesIcon";
import SimulationIcon from "../components/icons/SimulationIcon";
import SpinStats from "../components/icons/SpinStats";
import StoriesIcon from "../components/icons/StoriesIcon";
import UsersIcon from "../components/icons/UsersIcon";
import WheelConfigIcon from "../components/icons/WheelConfigIcon";
import communityImg from "../resources/community.png";
import downloadZumiTwo from "../resources/donwloadZumiTwo.png";
import downloadZumiOne from "../resources/downloadZumiOne.png";
import featuresImg from "../resources/features.png";
import image1 from "../resources/image1.png";
import image2 from "../resources/image2.png";
import image3 from "../resources/image3.png";
import image5 from "../resources/image5.png";
import image7 from "../resources/image7.png";
import navigationImg from "../resources/images.png";

export const USER_SIDEBAR_ITEMS = [
  {
    title: "Interactive Stories",
    href: "/user/interactive-stories",
    isActive: "interactive-stories",
    icon: <StoriesIcon />,
  },
];

export const SIDEBAR_ITEMS = [
  {
    title: "Overview",
    items: [
      {
        allowed: ["createSeries"],
        title: "Series",
        href: "/series",
        icon: <SeriesIcon />,
      },
      {
        allowed: ["createSeries"],
        title: "Channels",
        href: "/channels",
        isActive: "channels",
        icon: <ChannelsIcon />,
      },
      {
        allowed: ["createInteractiveStories"],
        title: "Interactive Stories",
        href: "/interactive-stories",
        isActive: "interactive-stories",
        icon: <StoriesIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Users / Events",
        href: "/users",
        icon: <UsersIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Experiments",
        href: "/experiments",
        icon: <ExperimentsIcon />,
      },
    ],
  },
  {
    title: "Analysis",
    items: [
      {
        allowed: ["accessAdminPanel"],
        title: "Logins",
        href: "/logins",
        icon: <LoginIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Comments",
        href: "/comments",
        icon: <CommentIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "API Static",
        href: "/stats",
        icon: <ApiStats />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Wheel Configuration",
        href: "/spinning-wheel",
        icon: <WheelConfigIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Wheel Simulation",
        href: "/wheel-stimulation",
        icon: <SimulationIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Spin Stats",
        href: "/user-spin-stats",
        icon: <SpinStats />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Admin Logs",
        href: "/logs",
        icon: <Logs />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Rewards",
        href: "/daily-rewards",
        icon: <RewardsIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Admin Panel",
        href: "/admin",
        icon: <AdminIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Events",
        href: "/events",
        icon: <EventsIcon />,
      },
    ],
  },
  {
    title: "Account",
    items: [
      {
        allowed: ["accessAdminPanel"],
        title: "Alerts",
        href: "/alerts",
        icon: <AlertIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Notifications",
        href: "/notifications",
        icon: <NotificationsIcon />,
      },
      {
        allowed: ["accessAdminPanel"],
        title: "Reviews",
        href: "/reviews",
        icon: <ReviewsIcon />,
      },
    ],
  },
];

export const MOCK_LOGS = [
  {
    id: 6699,
    createdAt: "2024-05-20T11:01:14.897Z",
    updatedAt: "2024-05-20T11:01:14.897Z",
    action: "Upload video from URL",
    type: "CREATE",
    data: {
      url: "https://www.dropbox.com/scl/fi/nrkxr2fec5ftgim4nniy0/09_10-Steps-To-Get-Rid-Of-Your-Crazy-BF_French.mp4?rlkey=dg64p9y8na31xayxqotyf10n6&st=3m4oam1s&dl=0",
      seriesId: 148,
    },
    adminId: "dbb20961-cb35-4608-beda-52e2709bb067",
    admin: {
      id: "dbb20961-cb35-4608-beda-52e2709bb067",
      createdAt: "2024-03-12T09:39:29.201Z",
      updatedAt: "2024-05-14T09:51:30.971Z",
      username: "Socelle",
      password: "$2b$10$GjzYQxUFV8dpyQhv4fquruPc/ncdJrocegWedEBAyihfQSEegS99m",
      deleted: false,
      permissions: [
        "addVideos",
        "editVideos",
        "createSeries",
        "deleteSeries",
        "editSeries",
        "editVariations",
        "addTrailer",
        "deleteVideos",
      ],
    },
  },
];

export const MOCK_REVIEWS = [
  {
    id: "cltcwu9qq00054k97kbd6dpek",
    createdAt: "2024-03-04T12:21:27.890Z",
    userId: "097eda1b-9ff7-4f93-a7a3-70ae1992c00e",
    rating: 4,
    comment: "good",
    user: {
      id: "097eda1b-9ff7-4f93-a7a3-70ae1992c00e",
      name: null,
      image: null,
      createdAt: "2024-03-01T11:49:19.108Z",
      updatedAt: "2024-03-01T11:49:19.108Z",
      ip: "172.71.94.235",
      country: "Netherlands",
      deleted: false,
      hasGameEnabled: false,
      fcmToken: null,
    },
  },
];

export const MOCK_DAILY_REWARDS = [
  {
    id: 3,
    name: "test",
    description: "testing description",
    coins: 2,
    createdAt: "2024-03-31T07:33:18.181Z",
    updatedAt: "2024-03-31T07:33:18.181Z",
    deleted: false,
  },
  {
    id: 5,
    name: "test daily task",
    description: "test daily description",
    coins: 4,
    createdAt: "2024-04-02T10:03:39.645Z",
    updatedAt: "2024-04-02T10:03:39.645Z",
    deleted: false,
  },
];

export const MOCK_ALERTS = [
  {
    id: 3,
    name: "reward",
    platform: "IOS",
    event: "User Finished The Ad Got Reward",
    period: 10,
    percentageDrop: 90,
    triggeredAt: null,
    acknowledgedAt: null,
    createdAt: "2024-04-02T16:33:10.154Z",
    updatedAt: "2024-05-04T18:58:07.115Z",
    deleted: false,
    snoozed: "2024-05-04T18:58:07.114Z",
    eventType: "Error",
  },
];

export const MOCK_EVENT_TYPES = [
  {
    _count: {
      name: 1859743,
    },
    name: "App Is Alive Event",
  },
];

export const MOCK_SERIES = [
  {
    id: 68,
    title: "Wife Pretends To Be In A Coma What She Heard Made Her Cry",
    cover: "https://videolist.ams3.digitaloceanspaces.com/1709731710932.jpg",
    createdAt: "2024-02-28T12:41:56.746Z",
    updatedAt: "2024-05-17T08:14:32.768Z",
    badgeColor: null,
    description:
      "Mary overhears her husband talking to her sister, Claudia, on the phone suspiciously. With her doubts, she pretends to be in coma and what she would hear after is more shocking.",
    duration: null,
    badgeText: null,
    language: "EN",
    rotation: "PORTRAIT",
    index: 0,
    deleted: false,
    trailerId: null,
    channelId: 1,
    unlockAfterMin: null,
    interactiveStoryId: null,
    Trailer: null,
    videos: [
      {
        id: 917,
        title:
          "Wife_Pretends_To_Be_In_A_Coma_What_She_Heard_Made_Her_Cry_1-1709124226437",
        url: "https://videolist.ams3.digitaloceanspaces.com/Wife_Pretends_To_Be_In_A_Coma_What_She_Heard_Made_Her_Cry_1-1709124226437_master.m3u8",
        createdAt: "2024-02-28T12:45:21.381Z",
        updatedAt: "2024-05-16T09:40:37.287Z",
        seriesId: 68,
        channelId: null,
        coins: 0,
        index: 1,
        note: null,
        adsCount: 0,
        rotation: "LANDSCAPE",
        deleted: false,
        thumbnail:
          "https://videolist.ams3.digitaloceanspaces.com/Wife_Pretends_To_Be_In_A_Coma_What_She_Heard_Made_Her_Cry_1-1709124226437_master_thumbnail.jpg",
        duration: 90,
        seriesTrailerForId: null,
        trailerForId: null,
        unlockAfterMin: 2,
        interactiveStoryId: null,
        _count: {
          Like: 94,
        },
      },
    ],
  },
];

export const MOCK_USERS = [
  {
    id: "00058e71-e020-4600-8c26-1ad7ff360c4a",
    name: "crisis706",
    image: null,
    createdAt: "2024-05-19T10:52:17.053Z",
    updatedAt: "2024-05-19T10:52:33.256Z",
    ip: "172.71.94.79",
    country: "Netherlands",
    deleted: false,
    hasGameEnabled: false,
    fcmToken:
      "f-aJHS3IDE6XgtC6uINE5J:APA91bGH28Z8I2sCsCeyw3gtxqRD0J0izPPTSccV6X1j6U0bJRasKHvfqY9fpVNtIsN_FBvI281lFc0MdXFYDIctvG6c6ZnF3YFu4vt7yduIRG6GXqtsL9pGJg8X2QjLsodjck2BjfWa",
    Event: [
      {
        id: 13539543,
        name: "Video Start Buffering",
        createdAt: "2024-05-19T11:08:33.828Z",
        updatedAt: "2024-05-19T11:08:51.669Z",
        userId: "00058e71-e020-4600-8c26-1ad7ff360c4a",
        data: {
          partId: 1592,
          userId: "00058e71-e020-4600-8c26-1ad7ff360c4a",
          seriesId: 49,
          variationId: 275,
          variationName: "55Z6TA7",
          videoDuration: 85,
          videoPosition: 85,
        },
        buildNumber: "2",
        deviceType: "IOS",
        versionName: "1.0.57",
        deleted: false,
        variationIdInt: 275,
        partIdInt: 1592,
      },
    ],
    _count: {
      Event: 744,
    },
  },
];

export const MOCK_EXPERIMENTS = [
  {
    id: 32,
    name: "DividedByFor68&70",
    key: "",
    createdAt: "2024-05-08T16:20:32.649Z",
    updatedAt: "2024-05-08T16:20:32.649Z",
    deleted: false,
    active: false,
    keys: ["divideBy"],
    hidden: false,
    ExperimentVariation: [
      {
        id: 92,
        experimentId: 32,
        createdAt: "2024-05-17T16:07:19.506Z",
        updatedAt: "2024-05-17T19:30:28.828Z",
        deleted: false,
        percentage: 14,
        value: null,
        active: true,
        name: "I6RNPK5",
        values: {
          divideBy: "1",
        },
      },
    ],
  },
];

export const MOCK_ADMINS = [
  {
    id: "d6d071f1-203b-4724-9ce6-8e046d2abc09",
    createdAt: "2024-04-21T10:48:02.691Z",
    updatedAt: "2024-06-28T10:03:16.217Z",
    username: "msasda",
    password: "$2b$10$liNY0YLrdAEZrbj5YQQWtOMtNebD0Hu3eLoGoe9JZDf8BtNmX/6E2",
    deleted: false,
    permissions: ["accessLogs"],
    channelIds: [1],
    subscriptionId: null,
  },
];

export const MOCK_WHEEL_CONFIG = [
  {
    id: 1,
    label: "10",
    value: 10,
    percentageDrop: 0.07,
    createdAt: "2024-04-18T06:32:13.230Z",
    updatedAt: "2024-05-01T13:21:35.027Z",
    deleted: false,
    type: "Coins",
  },
];

export const MOCK_CHANNELS = [
  { id: 1, name: "Channel 1" },
  { id: 2, name: "Channel 2" },
];

export const MOCK_DEFAULT_VARIABLES = [
  {
    value: "true",
    type: "Boolean",
    key: "show_subscription_1",
    id: 214,
  },
];

export const MOCK_SPIN_STATS = [
  {
    id: "00e44f12-be07-4b15-8c29-ab3bab4880ae",
    spinCount: 211,
  },
];

export const MOCK_EVENTS = [
  {
    id: 25621104,
    name: "On App In Background Event",
    createdAt: "2024-06-15T08:44:20.033Z",
    updatedAt: "2024-06-15T08:44:21.146Z",
    userId: "0003b4f4-1fa9-4dc3-a210-8a46f53e6a6f",
    data: {
      partId: null,
      userId: "0003b4f4-1fa9-4dc3-a210-8a46f53e6a6f",
      seriesId: null,
      variationId: null,
      variationName: null,
    },
    buildNumber: "133",
    deviceType: "Android",
    versionName: "1.0.84",
    deleted: false,
    variationIdInt: null,
    partIdInt: null,
  },
];

export const MOCK_STORIES = [
  {
    id: 2,
    title: "Anime test story ",
    createdAt: "2024-05-28T07:33:57.370Z",
    updatedAt: "2024-07-23T10:44:17.481Z",
    cover: "https://videolist.ams3.digitaloceanspaces.com/1716881637282.jpg",
    deleted: false,
    channelId: 1,
    language: "EN",
    startNodeId: "0ed48a9e-8abf-4b11-95f7-36f808efa640",
  },
];

export const MOCK_COMMENTS = [
  {
    id: 5479,
    videoId: 1525,
    userId: "668777f2-a908-41bb-9d30-f9e97820f3da",
    createdAt: "2024-07-29T12:21:27.639Z",
    updatedAt: "2024-07-29T12:21:27.639Z",
    deleted: false,
    text: "cc",
    parentId: null,
    user: {
      id: "668777f2-a908-41bb-9d30-f9e97820f3da",
      name: "Mariame CISSE",
      image: null,
      createdAt: "2024-07-11T15:17:48.931Z",
      updatedAt: "2024-07-11T15:18:11.835Z",
      ip: "172.71.98.85",
      country: "Netherlands",
      deleted: false,
      hasGameEnabled: false,
      fcmToken:
        "d4HRMoHZSNibnSvYvQ_3Lr:APA91bGve6jgTDSgVo6Zqz9uFxGbirklKZBqWEbAZB4BROEKge8k9OXJTcbZoXxm-Iu-PmYK-s2OvT1qLFUbnn8meM-o7lBQviZBlhRrq2xalXji5cwIe-CBy1a5cRxpv5TAQ8eo_5dc",
      appleId: null,
      googleId: null,
      facebookId: null,
      email: null,
    },
    video: {
      id: 1525,
      title: "P1of3My-Dad-Is-A-Hacker-He-Taught-Me-Everything-1715590153665",
      url: "https://videolist.ams3.digitaloceanspaces.com/P1of3My-Dad-Is-A-Hacker-He-Taught-Me-Everything-1715590153665_master.m3u8",
      createdAt: "2024-05-13T08:53:42.053Z",
      updatedAt: "2024-05-14T10:24:01.040Z",
      seriesId: 143,
      channelId: null,
      coins: 0,
      index: 13,
      note: null,
      adsCount: 0,
      rotation: "LANDSCAPE",
      deleted: false,
      thumbnail:
        "https://videolist.ams3.digitaloceanspaces.com/P1of3My-Dad-Is-A-Hacker-He-Taught-Me-Everything-1715590153665_thumbnail.jpg",
      duration: 325,
      seriesTrailerForId: null,
      trailerForId: null,
      unlockAfterMin: 0,
      interactiveStoryId: null,
    },
  },
];

export const MOCK_LOGINS = [
  {
    id: "60cc5c6b-15e0-4315-be69-1599c5a96291",
    name: "Salomon Agodio",
    image: null,
    createdAt: "2024-07-30T04:21:09.968Z",
    updatedAt: "2024-07-30T04:21:09.985Z",
    ip: "172.71.99.13",
    country: "Netherlands",
    deleted: false,
    hasGameEnabled: false,
    fcmToken: null,
    appleId: null,
    googleId: "100657176181311495256",
    facebookId: null,
    email: "salomonagodio7@gmail.com",
  },
];

export const MOCK_USER_STORIES = [
  {
    id: 4,
    title: "Help me get kissed",
    createdAt: "2024-07-04T14:37:34.482Z",
    description:
      "Tina, shy and insecure since 15, longs for admiration and her first kiss. She daydreams about Nick, a handsome new student. When Nick approaches her, she panics and bumps into a wall.",
    updatedAt: "2024-08-22T07:24:06.544Z",
    cover: "https://videolist.ams3.digitaloceanspaces.com/1722080631199.jpeg",
    deleted: false,
    channelId: 1,
    language: "EN",
    startNodeId: "6a4bdc82-db61-4fed-aaa1-3be1cd009b48",
  },
];

export const NOTIFICATIONS_EVENTS_NAMES = [
  "Adapty Native Get Paywall Error",
  "Ad Clicked Event",
  "Ad Dismissed Event",
  "Ad Displayed For User Event",
  "Ad Is Loading Event",
  "Ad Ready Event",
  "Ad Requested Event",
  "Ad Requested Failed",
  "All Experiments Event",
  "Api Calling Error",
  "App Is Alive Event",
  "App Opened By Notification",
  "App Opened Event",
  "Back Button Clicked Event",
  "Cancel Popup Button Clicked",
  "Change Language Clicked",
  "CheckIn Streak Reward",
  "Close Series Video Locked Game Dialog",
  "Close Wheel Game Reward Screen",
  "Coins Item Selected Event",
  "Coins Item Start Purchasing Event",
  "Coins Purchased Event",
  "Coins Purchasing Cancelled Event",
  "Coins Screen Closed Event",
  "Coins Screen Opened Because user doesn't have coins Event",
  "Comment Added Event",
  "Comment Card Clicked Event",
  "Comment Delete Button Clicked Event",
  "Comment Dislike Button Clicked Event",
  "Comment Edit Button Clicked Event",
  "Comment Edited Event",
  "Comment Like Button Clicked Event",
  "Comments Clicked Event",
  "Comments Close Button Clicked Event",
  "Confirm Popup Button Clicked",
  "Consent Error",
  "Consent Showing Up",
  "Consent While Showing Up Error",
  "Create Adapty Ui Error",
  "Created Adapty Paywall",
  "Daily Bonus Tab Clicked Event",
  "Delete Account Clicked",
  "Display First Launch Onboarding",
  "Display First Launch Subscription",
  "Display First Launch Subscription From Home Screen",
  "Double User Wheel Game Reward",
  "Duplicate User Wheel Game Reward",
  "Error",
  "Experiment Event",
  "Failed To Load Ad Event",
  "Feedback Clicked",
  "Free Coins Clicked Event",
  "Free Coins Not Clicked Event",
  "Free Coins Showed Up Event",
  "Free Diamonds Showed Up Event",
  "Get Adapty Products Ui Error",
  "Get Adapty Ui Error",
  "Go Next Video Event",
  "Home Tab Clicked Event",
  "Init Interactive Story By Last Saved video",
  "Init Interactive Story By Start Node video",
  "Interactive Story Clicked",
  "Interactive Story Closed",
  "Interactive Story Retry Call Api Button Clicked",
  "Interactive Story Video Played",
  "Locked Screen Game Mode Showed Up Event",
  "Locked Screen Game Mode With Ads Showed Up Event",
  "Locked Screen Showed Up Event",
  "Login Button Clicked",
  "Login By Apple Button Clicked",
  "Login By Facebook Button Clicked",
  "Login By Google Button Clicked",
  "Logout Clicked",
  "On App Close Event",
  "On App In Background Event",
  "On App In Foreground Event",
  "On Episode Unlocked Event",
  "Present Adapty Ui Error",
  "Privacy Clicked",
  "Profile Refill Clicked Event",
  "Profile Tab Clicked Event",
  "Purchasing Coins Failed Event",
  "Reply To Comment Clicked Event",
  "Review App No Clicked",
  "Review App Yes Clicked",
  "Reward Tab Clicked Event",
  "Sent Notification",
  "Series Clicked Event",
  "Series Locked Videos Ads & Game Back Button Clicked Event",
  "Series Locked Videos Game Back Button Clicked Event",
  "Series Video Locked Game Dialog",
  "Series Video Part Paused Event",
  "Series Video Part Played Event",
  "Series Video Part Seeker Used Event",
  "Series Video Part Watched Event",
  "Series Videos Back Button Clicked Event",
  "Series Videos List Clicked Event",
  "Series Videos List Item Clicked Event",
  "Series Videos List Locked Item Clicked Event",
  "Show Confirm Popup",
  "Showing Discount Offer subscription Popup",
  "Show Wheel Game Reward Screen",
  "Show Wheel Game reward Success Dialog",
  "Start Creating Adapty Paywall",
  "submit Rating App Button Clicked",
  "Subscribe Discount Offer Button Clicked",
  "Subscription Failed",
  "Subscription Failed Rendering",
  "Terms Of Service Clicked",
  "Trailer Video Back Button Clicked Event",
  "Trailer Video Clicked",
  "Trailer Video Is Started Playing",
  "Trailer Video Loading",
  "Trailer Video Paused Event",
  "Trailer Video Played Event",
  "Trailer Video Start Buffering",
  "Trailer Video Start Playing After Buffering",
  "Trailer Video Watched Event",
  "Try Again Button Clicked No Videos",
  "Unlock Videos Item By Showing Ad Clicked Event",
  "Unlock Videos Item Clicked Event",
  "User Canceled Exit The Whole Interactive Story",
  "User Canceled Subscription",
  "User Canceled Subscription Purchase",
  "User Changed Language Of App Event",
  "User Clicked Back Interactive Story Button",
  "User Clicked Block User Button",
  "User Clicked Change Profile Photo",
  "User Clicked Close Button",
  "User Clicked Close Subscription",
  "User Clicked Confrim Block User Button",
  "User Clicked Duplicate Wheel Game Reward Spin",
  "User Clicked Episodes Button In Locked Screen",
  "User Clicked Exit The Whole Interactive Story",
  "User Clicked Free Diamonds Showed Up Notification",
  "User Clicked Go Url In Subscription",
  "User Clicked Next Interactive Story Choice",
  "User Clicked Next Onboarding Step",
  "User Clicked On Ad Event",
  "User Clicked On Unlock Button In Locked Video Game Screen Event",
  "User Clicked On Unlock Button In Locked Video Screen Event",
  "User Clicked Pause Button Event",
  "User Clicked Pause Interactive Story Button",
  "User Clicked Play Button Event",
  "User Clicked Play Episode From Series Details",
  "User Clicked Play Interactive Story Button",
  "User Clicked Play Series Button",
  "User Clicked Play The Game To Get Diamonds",
  "User Clicked Recommended Story",
  "User Clicked Reply Story Interactive Story",
  "User Clicked Return To Previous Choices Interactive Story",
  "User Clicked Series Details",
  "User Clicked Share Button",
  "User Clicked Skip Onboarding",
  "User Clicked Spin Wheel Button",
  "User Clicked Spin Wheel Button No Sufficient Diamond (Cash)",
  "User Clicked Subscribe Button",
  "User Clicked Subscribe Button From Coins Screen",
  "User Clicked Unlock Episode",
  "User Clicked Unlock Episode From Video Screen",
  "User Clicked Unlock Series",
  "User Clicked Watch Ad To Get Extra Reward",
  "User Closed CheckIn Streak Reward",
  "User Closed Series Details",
  "User Closed Wheel Game Reward Success Dialog",
  "User Coins And Diamonds Changed Event",
  "User Completed Onboarding",
  "User Finished Watching Series",
  "User Didn't Finish Watching Series",
  "User Do Not Have Enough Diamonds To Spin",
  "User Finished The Ad Got Reward",
  "User Got Error No Ad Available",
  "User Got Error While Clicking show Ad",
  "User Got No Videos",
  "User is Watching The Ad Event",
  "User Obtained Consent",
  "User Opened App By Deeplink",
  "User Scrolled Home Screen Item List",
  "User Selected Subscription Product",
  "User Showed All Ads Event",
  "User Showed The Ad Event",
  "User Started subscription purchasing",
  "User Subscribed",
  "User Swiped Video Event",
  "User Updated Profile Photo",
  "User Went To Home Screen",
  "User Wheel Game reward",
  "Video Finished Interactive Story",
  "Video Is Started Playing",
  "Video Liked",
  "Video Loading",
  "Video Start Buffering",
  "Video Start Playing After Buffering",
  "Video Un Liked",
  "Watch Ad Button From Bottom Sheet Clicked Event",
  "Watch Ad Button From List Clicked Event",
  "Watch Ad By Double Game Reward Clicked",
  "Watch Ad By Free Coins Clicked",
  "Wheel Game Spinning",
  "Wheel Game Stop",
  "Wheel Multi Game Spinning",
  "Wheel Multi Game Stop",
];

export const NOTIFICATION_ACTION_LIST = [
  {
    id: "COINS",
    name: "Give coins",
    unit: "coins",
  },
  {
    id: "DIAMONDS",
    name: "Give diamonds",
    unit: "diamonds",
  },
  {
    id: "PUSH",
    name: "Send a push notification",
    unit: "message",
  },
];

export const NOTIFICATIONS_CONDITION_LIST = [
  {
    id: "USER_ID",
    name: "User ID is",
    inputType: "text",
  },
  {
    id: "ANY_EVENT",
    name: "Hasn't used the app for",
    unit: "minutes",
    inputType: "number",
  },
  {
    id: "COUNTRY",
    name: "Country name is",
    inputType: "text",
  },
  {
    id: "VERSION",
    name: "App version is",
    inputType: "text",
  },
  {
    id: "BUILD",
    name: "App build is ",
    inputType: "number",
  },

  {
    id: "HAS_ANY_EVENT",
    name: "Has any event",
    inputType: "text",
  },
  {
    id: "DOESNT_HAVE_ANY_EVENT",
    name: "Doesn't have any event",
    inputType: "text",
  },
];

export const ADMIN_PERMISSIONS = {
  accessAdminPanel: "Access Admin Panel",
  accessLogs: "Access Logs",
  addVideos: "Add Videos",
  deleteVideos: "Delete Videos",
  editVideos: "Edit Videos",
  createSeries: "Create Series",
  createInteractiveStories: "Create Interactive Stories",
  deleteSeries: "Delete Series",
  editSeries: "Edit Series",
  editVariations: "Edit Variations",
  addTrailer: "Add Trailer",
  accessEvents: "Access Events",
};

export const ADMIN_TABLE_HEAD = [
  "Admins",
  "Access Admin Panel",
  "Access Logs",
  "Add Videos",
  "Delete Videos",
  "Edit Videos",
  "Create Series",
  "Delete Series",
  "Edit Series",
  "Edit Variations",
  "Add Trailer",
  "Access Events",
  "Create Interactive Stories",
  "Actions",
];

export const ALERTS_TABLE_HEAD = [
  "Event Type",
  "Platform",
  "Period",
  "Drop By",
  "Name",
  "Created At",
  "Actions",
];

export const USERS_EVENTS = [
  "Video Is Started Playing",
  "User Clicked On Ad Event",
  "On App In Background Event",
  "User is Watching The Ad Event",
  "Go Next Video Event",
  "User Finished The Ad Got Reward",
  "Ad Dismissed Event",
  "Series Video Part Watched Event",
  "Experiment Event",
  "Series Clicked Event",
  "Series Video Part Played Event",
  "Series Video Part Paused Event",
  "Series Videos Back Button Clicked Event",
  "App Opened Event",
  "User Showed All Ads Event",
  "Series Videos List Clicked Event",
  "User Got Error No Ad Available",
  "Free Coins Showed Up Event",
  "Video Start Playing After Buffering",
  "Profile Tab Clicked Event",
  "Home Tab Clicked Event",
  "Failed To Load Ad Event",
  "Series Videos List Item Clicked Event",
  "Video Start Buffering",
  "Free Coins Not Clicked Event",
  "Video Liked",
  "Series Videos List Locked Item Clicked Event",
  "Free Coins Clicked Event",
  "Coins Screen Closed Event",
  "Api Calling Error",
  "On App Close Event",
  "User Swiped Video Event",
  "User Clicked On Unlock Button In Locked Video Screen",
  "Profile Refill Clicked Event",
  "Video Loading",
  "Try Again Button Clicked No Videos",
  "User Changed Language Of App Event",
  "Video Un Liked",
  "Series Video Part Seeker Used Event",
  "User Got No Videos",
  "Consent Showing Up",
  "Consent While Showing Up Error",
  "Ad Clicked Event",
  "User Obtained Consent",
  "Back Button Clicked Event",
  "Ad Displayed For User Event",
  "Watch Ad By Free Coins Clicked",
  "User Showed The Ad Event",
  "Watch Ad Button From List Clicked Event",
  "Consent Error",
  "Review App Yes Clicked",
  "Coins Purchased Event",
  "Purchasing Coins Failed Event",
  "Ad Requested Event",
  "Review App No Clicked",
  "Unlock Videos Item By Showing Ad Clicked Event",
  "Watch Ad Button From Bottom Sheet Clicked Event",
  "User Selected Subscription Product",
  "Ad Ready Event",
  "Display First Launch Subscription",
  "User Clicked Close Subscription",
  "User Clicked Subscribe Button",
  "User Got Error While Clicking show Ad",
  "User Canceled Subscription",
  "submit Rating App Button Clicked",
  "Unlock Videos Item Clicked Event",
];

export const EXPERIMENT_KEY_LABELS = {
  adsCount: "Ads Count",
  waitBetweenAds: "Wait Between Ads",
  freeCoins: "Free Coins",
  free_coins_timer_min: "Free Coins Timer Min",
  free_coins_timer_max: "Free Coins Timer Max",
  freeCoinsTimesPerAd: "Free Coins Times Per Ad",
  landscapeUnlockScreen: "Landscape Unlock Screen",
  free_coins_normal: "Free Coins Normal",
  videos_base_url: "Videos Base URL",
  android_version: "Android Version",
  ios_version: "iOS Version",
  force_update_ios: "Force Update iOS",
  force_update_android: "Force Update Android",
  cover_variation_name: "Cover Variation Name",
  discount_offer_description: "Discount Offer Description",
  discount_offer_expire_time: "Discount Offer Expire Time (in minutes)",
  show_subscription_page: "Show Subscription Page",
  hasGameEnabled: "Has Game Enabled",
};

export const SPINNING_WHEEL_HEAD = [
  "Label",
  "Value",
  "Type",
  "Probability",
  "Is Divisible",
  "Created At",
  "Updated At",
  "Actions",
];

export const CHANNELS_TABLE_HEAD = [
  "ID",
  "Name",
  "Slug",
  "Admin Id",
  "User Id",
  "Created At",
  "Actions",
];

export const SUBSCRIPTION_PLANS = [
  {
    icon: "/images/standardPlan.svg",
    title: "Standard Plan",
    description: "Enjoy essential features for content creation.",
    price: "$20",
    benefits: ["Create Series"],
    type: "standard",
  },
  {
    icon: "/images/premiumPlan.svg",
    title: "Premium Plan",
    description: "Experience premium features for enhanced creativity.",
    price: "$30",
    benefits: ["Create Series", "Create Interactive Story"],
    type: "premium",
  },
];

export const STORIES_TABLE_HEAD = [
  "ID",
  "Title",
  "language",
  "Created At",
  "Actions",
];

export const initialNodes = [
  {
    id: "start",
    type: "start",
    position: { x: 40, y: 0 },
  },
];

export const WHY_CHOOSE_US = [
  {
    img: navigationImg,
    title: "User-Friendly Navigation",
    description:
      "Dive into the world of anime with ease through our platform's user-friendly navigation. Our intuitive menus and clear categories let you quickly find your favorite shows, characters, and genres. Whether you're a seasoned otaku or just starting your anime journey, our streamlined interface ensures a seamless and enjoyable experience for all anime enthusiasts.",
  },
  {
    img: communityImg,
    title: "Community Engagement",
    description:
      "Join a vibrant community of anime enthusiasts where your voice matters. Engage in lively discussions, share fan art, participate in polls, and attend virtual events with fellow fans. Our platform fosters a sense of belonging, allowing you to connect, interact, and grow with others who share your passion for anime. Whether you're here to make new friends or delve deep into anime lore, our community is the perfect place to be.",
  },
  {
    img: featuresImg,
    title: "Wide Array of Features",
    description:
      "Immerse yourself in the ultimate anime experience with our wide array of features. From detailed character bios and episode guides to personalized watchlists and real-time notifications for new releases, we've got everything to enhance your anime journey. Explore forums, join discussions, and connect with fellow fans to share your love for all things anime.",
  },
];

export const TESTIMONIALS = [
  {
    name: "Akira Tanaka",
    content:
      "This platform has the best collection of anime series. I’ve enjoyed every episode of my favorite shows!",
    image: "https://i.pravatar.cc/150?img=11",
    role: "Anime Enthusiast",
    rating: 5,
  },
  {
    name: "Yuki Nakamura",
    content:
      "The interactive stories are so immersive! It feels like I'm a part of the anime world.",
    image: "https://i.pravatar.cc/150?img=12",
    role: "Interactive Story Lover",
    rating: 4.8,
  },
  {
    name: "Sora Ishikawa",
    content:
      "I love the character designs and the variety of series available. There's something new every time I log in!",
    image: "https://i.pravatar.cc/150?img=13",
    role: "Character Artist",
    rating: 4.9,
  },
  {
    name: "Hana Suzuki",
    content:
      "The platform's interface is so intuitive, and the streaming quality is top-notch!",
    image: "https://i.pravatar.cc/150?img=14",
    role: "UX Designer",
    rating: 4.7,
  },
  {
    name: "Kazuki Yamamoto",
    content:
      "I've binge-watched multiple series in a single weekend. The recommendations are spot on!",
    image: "https://i.pravatar.cc/150?img=15",
    role: "Binge-Watcher",
    rating: 5,
  },
  {
    name: "Mika Hoshino",
    content:
      "The interactive stories offer unique endings based on choices. It’s an incredible way to experience anime!",
    image: "https://i.pravatar.cc/150?img=16",
    role: "Interactive Story Fan",
    rating: 4.6,
  },
];

export const LOGINS_TABLE_HEAD = [
  "Id",
  "User",
  "IP Address",
  "Country",
  "First Log In At",
  "Last Activity At",
];

export const IMAGE_SECTION_ONE = [
  downloadZumiOne,
  image2,
  image3,
  downloadZumiOne,
];
export const IMAGES_SECTION_TWO = [
  image1,
  image5,
  image3,
  downloadZumiTwo,
  image5,
];
export const IMAGES_SECTION_THREE = [image7, image3, downloadZumiOne, image1];
