import { Box, Modal } from "@mui/material";
import React from "react";
import SpinningWheel from "../components/SpinningWheel";

const SpinWheelModal = ({
  openSpinModal,
  setOpenSpinModal,
  finished,
  segments,
  handleFinished,
  availableSpins,
}) => {
  return (
    <Modal
      open={openSpinModal}
      onClose={() => {
        setOpenSpinModal(false);
      }}
      style={{ overflow: "hidden" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
        }}
      >
        <p style={{ textAlign: "center" }} className="modalTitle">
          Click on Spin Button At the Bottom
        </p>

        <div className="wheelDiv">
          <div style={{ marginTop: 10 }}>
            <SpinningWheel
              key={finished ? "reRenderKey" : "initialKey"}
              segments={segments}
              onFinished={handleFinished}
              availableSpins={availableSpins}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SpinWheelModal;
