import React from "react";

const UsersIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0417 14.1667C12.0417 12.993 10.4557 12.0417 8.5 12.0417C6.54429 12.0417 4.95833 12.993 4.95833 14.1667M14.875 12.0417C14.875 11.1704 14.0009 10.4217 12.75 10.0938M2.125 12.0417C2.125 11.1704 2.99908 10.4217 4.25 10.0938M12.75 7.25051C12.9599 7.065 13.131 6.83982 13.2535 6.58794C13.376 6.33606 13.4475 6.06244 13.4639 5.78282C13.4803 5.5032 13.4412 5.2231 13.349 4.95863C13.2568 4.69415 13.1131 4.45053 12.9264 4.24176C12.7397 4.03299 12.5135 3.86319 12.2609 3.74214C12.0083 3.62109 11.7343 3.55117 11.4546 3.5364C11.1749 3.52163 10.895 3.56231 10.6311 3.65609C10.3671 3.74987 10.1243 3.8949 9.91667 4.08285M4.25 7.25051C3.83371 6.87379 3.58343 6.34762 3.55383 5.78696C3.52423 5.22629 3.71771 4.67669 4.09201 4.25822C4.46632 3.83975 4.99102 3.58642 5.5515 3.55356C6.11198 3.5207 6.66269 3.71098 7.08333 4.08285M8.5 9.91668C8.22094 9.91668 7.94461 9.86172 7.6868 9.75493C7.42898 9.64813 7.19472 9.49161 6.9974 9.29428C6.80007 9.09696 6.64355 8.8627 6.53676 8.60488C6.42996 8.34707 6.375 8.07074 6.375 7.79168C6.375 7.51262 6.42996 7.2363 6.53676 6.97848C6.64355 6.72066 6.80007 6.4864 6.9974 6.28908C7.19472 6.09175 7.42898 5.93523 7.6868 5.82844C7.94461 5.72165 8.22094 5.66668 8.5 5.66668C9.06358 5.66668 9.60409 5.89056 10.0026 6.28908C10.4011 6.68759 10.625 7.2281 10.625 7.79168C10.625 8.35527 10.4011 8.89577 10.0026 9.29428C9.60409 9.6928 9.06358 9.91668 8.5 9.91668Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
