import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import {
  IconInfoCircle,
  IconLanguage,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import NotificationsIcon from "../components/icons/NotificationsIcon";
import Layout from "../components/Layout/Layout";
import useNotifications from "../customHooks/useNotifications";
import {
  NOTIFICATIONS_CONDITION_LIST,
  NOTIFICATIONS_EVENTS_NAMES,
  NOTIFICATION_ACTION_LIST,
} from "../utils/constants";
import API from "../interceptor/API";

const steps = ["Campaign Info", "Conditions", "Actions"];

const CreateNotificationsDialog = () => {
  const {
    id,
    conditions,
    actions,
    variants,
    trigger,
    campaignName,
    activeStep,
    currentVariant,
    fetchNotificationDetails,
    handleSave,
    handleNext,
    handleBack,
    setCampaignName,
    setTrigger,
    setConditions,
    setActions,
    setCurrentVariant,
    setVariants,
    triggerOptions,
    setTriggerOptions,
  } = useNotifications();

  const [openModal, setOpenModal] = useState(null);
  const [modalData, setModalData] = useState({
    EN: { title: "", text: "", image: "" },
    FR: { title: "", text: "", image: "" },
    DE: { title: "", text: "", image: "" },
  });
  const [translating, setTranslating] = useState([]);

  useEffect(() => {
    if (id) {
      fetchNotificationDetails();
    }
  }, [id]);

  const handleOpenModal = (action, i) => {
    setModalData(
      action.localisedValues || {
        EN: action.value,
        FR: { title: "", text: "", image: "" },
        DE: { title: "", text: "", image: "" },
      }
    );
    setOpenModal(i);
  };

  const handleSaveModal = (i) => {
    const newActions = [...actions];
    newActions[openModal].localisedValues = modalData;
    newActions[openModal].value = modalData.EN;
    setActions(newActions);
    setOpenModal(null);
  };

  return (
    <Layout
      pageTitle={"Notifications > New Campaign"}
      icon={<NotificationsIcon />}
    >
      <Card
        sx={{
          mx: 16,
          px: 3,
          py: 3,
          borderRadius: "5px",
          border: "1px solid var(--borderColor)",
        }}
      >
        <p className="pageHeading" style={{ textAlign: "center" }}>
          New campaign
        </p>

        <Stepper activeStep={activeStep} sx={{ mt: 6 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={4}>
          {activeStep === 0 && (
            <Box display="flex" flexDirection="column" gap={2} flexWrap="wrap">
              <FormLabel
                sx={{ fontSize: "12px", fontWeight: 400, color: "black" }}
              >
                Campaign Name
              </FormLabel>
              <TextField
                fullWidth
                onChange={(e) => setCampaignName(e.target.value)}
                value={campaignName}
                margin="none"
                sx={{
                  textAlign: "center",
                  height: "40px",
                  "& input": {
                    padding: "8px",
                    height: "40px",
                  },
                }}
              />
              <Box>
                <FormLabel
                  sx={{ fontSize: "12px", fontWeight: 400, color: "black" }}
                >
                  Trigger
                </FormLabel>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="center"
                >
                  <Select
                    value={trigger}
                    onChange={(e) => {
                      setTrigger(e.target.value);
                      if (e.target.value === "EVENT_TRIGGER") {
                        setTriggerOptions({
                          ...triggerOptions,
                          timeAfterEventUnit: "seconds",
                          timeAfterEventSeconds: 0,
                        });
                      } else {
                        setTriggerOptions({});
                      }
                    }}
                    sx={{ height: "40px", width: "100%", mt: 1 }}
                  >
                    <MenuItem value="MANUAL_TRIGGER">Manual trigger</MenuItem>
                    <MenuItem value="EVENT_TRIGGER">Event trigger</MenuItem>
                    <MenuItem value="SCHEDULED">Scheduled</MenuItem>
                  </Select>
                  {trigger === "SCHEDULED" && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="center"
                    >
                      <Typography>Every</Typography>
                      <TextField
                        label="Frequency"
                        type="number"
                        value={(() => {
                          let frequency = triggerOptions.frequency;
                          if (triggerOptions.frequencyUnit === "days") {
                            frequency = triggerOptions.frequency / 24;
                          } else if (triggerOptions.frequencyUnit === "weeks") {
                            frequency = triggerOptions.frequency / 24 / 7;
                          } else if (
                            triggerOptions.frequencyUnit === "months"
                          ) {
                            frequency = triggerOptions.frequency / 24 / 30;
                          } else if (triggerOptions.frequencyUnit === "hours") {
                            frequency = triggerOptions.frequency;
                          }
                          return frequency;
                        })()}
                        onChange={(e) => {
                          let frequency = e.target.value;
                          // convert frequency to hours always
                          if (triggerOptions.frequencyUnit === "days") {
                            frequency = e.target.value * 24;
                          } else if (triggerOptions.frequencyUnit === "weeks") {
                            frequency = e.target.value * 24 * 7;
                          } else if (
                            triggerOptions.frequencyUnit === "months"
                          ) {
                            frequency = e.target.value * 24 * 30;
                          } else if (triggerOptions.frequencyUnit === "hours") {
                            frequency = e.target.value;
                          }

                          setTriggerOptions({
                            ...triggerOptions,
                            frequency,
                          });
                        }}
                        sx={{ width: "100px" }}
                      />
                      <Select
                        value={triggerOptions.frequencyUnit || "days"}
                        onChange={(e) =>
                          setTriggerOptions({
                            ...triggerOptions,
                            frequencyUnit: e.target.value,
                          })
                        }
                        sx={{ height: "40px", width: "120px" }}
                      >
                        <MenuItem value="hours">Hours</MenuItem>
                        <MenuItem value="days">Days</MenuItem>
                        <MenuItem value="weeks">Weeks</MenuItem>
                        <MenuItem value="months">Months</MenuItem>
                      </Select>
                    </Box>
                  )}
                  {trigger === "EVENT_TRIGGER" && (
                    <Autocomplete
                      sx={{ minWidth: 200 }}
                      options={NOTIFICATIONS_EVENTS_NAMES}
                      renderInput={(params) => (
                        <TextField {...params} label="Event name" />
                      )}
                      value={triggerOptions.eventName || ""}
                      onChange={(e, newValue) => {
                        setTriggerOptions({
                          ...triggerOptions,
                          eventName: newValue,
                        });
                      }}
                    />
                  )}
                  {/* choose time after event (seconds, minutes, hours, days, weeks) */}
                  {trigger === "EVENT_TRIGGER" && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                    >
                      <TextField
                        label="Time after event"
                        sx={{ minWidth: 200 }}
                        value={(() => {
                          let divideByValue = 1;
                          if (triggerOptions.timeAfterEventUnit === "minutes") {
                            divideByValue = 60;
                          }
                          if (triggerOptions.timeAfterEventUnit === "hours") {
                            divideByValue = 60 * 60;
                          }
                          if (triggerOptions.timeAfterEventUnit === "days") {
                            divideByValue = 60 * 60 * 24;
                          }
                          if (triggerOptions.timeAfterEventUnit === "weeks") {
                            divideByValue = 60 * 60 * 24 * 7;
                          }
                          return (
                            triggerOptions.timeAfterEventSeconds / divideByValue
                          );
                        })()}
                        type="number"
                        onChange={(e) => {
                          let multiplyByValue = 1;
                          if (triggerOptions.timeAfterEventUnit === "minutes") {
                            multiplyByValue = 60;
                          }
                          if (triggerOptions.timeAfterEventUnit === "hours") {
                            multiplyByValue = 60 * 60;
                          }
                          if (triggerOptions.timeAfterEventUnit === "days") {
                            multiplyByValue = 60 * 60 * 24;
                          }
                          if (triggerOptions.timeAfterEventUnit === "weeks") {
                            multiplyByValue = 60 * 60 * 24 * 7;
                          }

                          setTriggerOptions({
                            ...triggerOptions,
                            timeAfterEventSeconds:
                              e.target.value * multiplyByValue,
                          });
                        }}
                      />
                      <Select
                        sx={{ minWidth: 100 }}
                        value={triggerOptions.timeAfterEventUnit || ""}
                        onChange={(e) =>
                          setTriggerOptions({
                            ...triggerOptions,
                            timeAfterEventUnit: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="seconds">Seconds</MenuItem>
                        <MenuItem value="minutes">Minutes</MenuItem>
                        <MenuItem value="hours">Hours</MenuItem>
                        <MenuItem value="days">Days</MenuItem>
                        <MenuItem value="weeks">Weeks</MenuItem>
                      </Select>
                    </Box>
                  )}
                </Box>
              </Box>
              {trigger === "EVENT_TRIGGER" &&
                !triggerOptions.notificationsAfter && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Button
                      startIcon={<IconPlus size={20} />}
                      onClick={() => {
                        setTriggerOptions({
                          ...triggerOptions,
                          notificationsAfter: {
                            number: 1,
                            frequency: 3600, // Default to 1 hour in seconds
                            unit: "hours",
                          },
                        });
                      }}
                    >
                      <Typography>Add more notifications after</Typography>
                    </Button>
                    <Tooltip title="Add more notification in a scheduled frequency after the triggered notification">
                      <IconInfoCircle size={12} />
                    </Tooltip>
                  </Box>
                )}
              {trigger === "EVENT_TRIGGER" &&
                triggerOptions.notificationsAfter && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                    sx={{ border: "1px solid var(--borderColor)" }}
                    borderRadius={1}
                    p={2}
                  >
                    <Typography>Notifications after the first one</Typography>
                    <TextField
                      label="How many notifications?"
                      type="number"
                      value={triggerOptions.notificationsAfter.number}
                      onChange={(e) => {
                        setTriggerOptions({
                          ...triggerOptions,
                          notificationsAfter: {
                            ...triggerOptions.notificationsAfter,
                            number: e.target.value,
                          },
                        });
                      }}
                    />
                    <Typography>How frequently?</Typography>
                    <TextField
                      label="Every"
                      type="number"
                      value={(() => {
                        const frequency =
                          triggerOptions.notificationsAfter.frequency;
                        const unit = triggerOptions.notificationsAfter.unit;
                        switch (unit) {
                          case "minutes":
                            return frequency / 60;
                          case "hours":
                            return frequency / 3600;
                          case "days":
                            return frequency / 86400;
                          default:
                            return frequency;
                        }
                      })()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const unit = triggerOptions.notificationsAfter.unit;
                        let frequency;
                        switch (unit) {
                          case "minutes":
                            frequency = value * 60;
                            break;
                          case "hours":
                            frequency = value * 3600;
                            break;
                          case "days":
                            frequency = value * 86400;
                            break;
                          default:
                            frequency = value;
                        }
                        setTriggerOptions({
                          ...triggerOptions,
                          notificationsAfter: {
                            ...triggerOptions.notificationsAfter,
                            frequency,
                          },
                        });
                      }}
                    />
                    <Select
                      value={triggerOptions.notificationsAfter.unit}
                      onChange={(e) => {
                        setTriggerOptions({
                          ...triggerOptions,
                          notificationsAfter: {
                            ...triggerOptions.notificationsAfter,
                            unit: e.target.value,
                          },
                        });
                      }}
                    >
                      <MenuItem value="minutes">Minutes</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                      <MenuItem value="days">Days</MenuItem>
                    </Select>
                    <IconButton
                      color="error"
                      onClick={() => {
                        const newTriggerOptions = { ...triggerOptions };
                        delete newTriggerOptions.notificationsAfter;
                        setTriggerOptions(newTriggerOptions);
                      }}
                    >
                      <IconX size={16} />
                    </IconButton>
                  </Box>
                )}
            </Box>
          )}
          {activeStep === 1 && (
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              border="1px solid var(--borderColor)"
              borderRadius={1}
              py={2}
              height={conditions?.length > 2 && "350px"}
              overflow="auto"
            >
              {conditions.map((condition, i) => (
                <Box
                  key={i}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding={2}
                  gap={2}
                >
                  <Select
                    sx={{ width: 300, height: "40px" }}
                    value={condition.id}
                    onChange={(e) => {
                      const newConditions = [...conditions];
                      newConditions[i].id = e.target.value;
                      newConditions[i].value = "";
                      setConditions(newConditions);
                    }}
                  >
                    {NOTIFICATIONS_CONDITION_LIST.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                  >
                    {condition.id === "HAS_ANY_EVENT" ||
                    condition.id === "DOESNT_HAVE_ANY_EVENT" ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        sx={{ width: 400 }}
                      >
                        <Autocomplete
                          options={NOTIFICATIONS_EVENTS_NAMES}
                          renderInput={(params) => (
                            <TextField {...params} label="Event name" />
                          )}
                          value={condition.value.name || ""}
                          onChange={(e, newValue) => {
                            const newConditions = [...conditions];
                            newConditions[i].value = {
                              ...newConditions[i].value,
                              name: newValue,
                            };
                            setConditions(newConditions);
                          }}
                        />

                        <TextField
                          label="In the past"
                          InputProps={{
                            endAdornment: "minutes",
                          }}
                          value={condition.value.minutes || ""}
                          type="number"
                          onChange={(e) => {
                            const newConditions = [...conditions];
                            newConditions[i].value = {
                              ...newConditions[i].value,
                              minutes: e.target.value,
                            };
                            setConditions(newConditions);
                          }}
                        />

                        <TextField
                          label="Video ID (optional)"
                          value={condition.value.videoId || ""}
                          type="number"
                          onChange={(e) => {
                            const newConditions = [...conditions];
                            newConditions[i].value = {
                              ...newConditions[i].value,
                              videoId: e.target.value,
                            };
                            setConditions(newConditions);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                      >
                        <TextField
                          sx={{
                            textAlign: "center",
                            "& input": {
                              padding: "8px",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  NOTIFICATIONS_CONDITION_LIST.find(
                                    (c) => c.id === condition.id
                                  )?.unit
                                }
                              </InputAdornment>
                            ),
                          }}
                          type={
                            NOTIFICATIONS_CONDITION_LIST.find(
                              (c) => c.id === condition.id
                            )?.inputType
                          }
                          value={condition.value}
                          onChange={(e) => {
                            const newConditions = [...conditions];
                            newConditions[i].value = e.target.value;
                            setConditions(newConditions);
                          }}
                        />
                      </Box>
                    )}
                    <IconButton
                      color="error"
                      onClick={() => {
                        const newConditions = [...conditions];
                        newConditions.splice(i, 1);
                        setConditions(newConditions);
                      }}
                    >
                      <IconX size={16} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  className="secondaryButton"
                  onClick={() =>
                    setConditions([
                      ...conditions,
                      { id: "ANY_EVENT", value: 0 },
                    ])
                  }
                >
                  + Add condition
                </Button>
              </Box>
            </Box>
          )}
          {activeStep === 2 && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Tabs value={currentVariant}>
                <Tab
                  label="Variant 1"
                  value={0}
                  onClick={() => setCurrentVariant(0)}
                />
                {variants.map((_, i) => (
                  <Tab
                    value={i + 1}
                    key={i}
                    label={
                      <Box display="flex" gap={1} alignItems="center">
                        Variant {i + 2}{" "}
                        <IconButton
                          size="small"
                          color="error"
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setVariants(variants.filter((_, j) => i !== j));
                            setCurrentVariant(0);
                          }}
                        >
                          <IconX size={16} />
                        </IconButton>
                      </Box>
                    }
                    onClick={() => {
                      setCurrentVariant(i + 1);
                    }}
                  />
                ))}
                <Tab
                  label={<IconPlus size={20} />}
                  sx={{
                    color: "primary.main",
                    fontWeight: "bold",
                    width: 60,
                  }}
                  onClick={() => {
                    setVariants([...variants, []]);
                  }}
                />
              </Tabs>

              <Box
                sx={{
                  borderRadius: "10px",
                  border: actions?.length > 0 && "1px solid var(--borderColor)",
                  height: actions?.length >= 2 && "350px",
                  overflow: "auto",
                  pb: 2,
                }}
              >
                {" "}
                {(currentVariant === 0
                  ? actions
                  : variants[currentVariant - 1] || []
                ).map((action, i) => {
                  const value =
                    currentVariant === 0
                      ? actions
                      : variants[currentVariant - 1];
                  const setValue = (newActions) => {
                    if (currentVariant === 0) {
                      setActions(newActions);
                    } else {
                      const newVariants = [...variants];
                      newVariants[currentVariant - 1] = newActions;
                      setVariants(newVariants);
                    }
                  };

                  return (
                    <Box
                      key={i}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      padding={2}
                      gap={2}
                    >
                      <Select
                        sx={{ width: 400, height: "40px" }}
                        value={action.id}
                        onChange={(e) => {
                          const newActions = [...value];
                          newActions[i].id = e.target.value;
                          newActions[i].value = "";
                          setValue(newActions);
                        }}
                      >
                        {NOTIFICATION_ACTION_LIST.map((a) => (
                          <MenuItem key={a.id} value={a.id}>
                            {a.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {action.id === "PUSH" ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          sx={{ width: 400 }}
                        >
                          <TextField
                            sx={{
                              textAlign: "center",
                              height: "40px",
                              "& input": {
                                padding: "8px",
                                height: "40px",
                              },
                              "input::placeholder": {
                                color: "var(--placeholderTextColor)",
                              },
                            }}
                            placeholder="Title"
                            value={action.value.title || ""}
                            onChange={(e) => {
                              const newActions = [...value];
                              newActions[i].value = {
                                ...newActions[i].value,
                                title: e.target.value,
                              };
                              setValue(newActions);
                            }}
                          />
                          <TextField
                            sx={{
                              textAlign: "center",
                              height: "40px",
                              "& input": {
                                padding: "8px",
                                height: "40px",
                              },
                              "input::placeholder": {
                                color: "var(--placeholderTextColor)",
                              },
                            }}
                            placeholder="Text"
                            value={action.value.text || ""}
                            onChange={(e) => {
                              const newActions = [...value];
                              newActions[i].value = {
                                ...newActions[i].value,
                                text: e.target.value,
                              };
                              setValue(newActions);
                            }}
                          />
                          <TextField
                            sx={{
                              textAlign: "center",
                              height: "40px",
                              "& input": {
                                padding: "8px",
                                height: "40px",
                              },
                              "input::placeholder": {
                                color: "var(--placeholderTextColor)",
                              },
                            }}
                            placeholder="Image URL (optional)"
                            value={action.value.image || ""}
                            onChange={(e) => {
                              const newActions = [...value];
                              newActions[i].value = {
                                ...newActions[i].value,
                                image: e.target.value,
                              };
                              setValue(newActions);
                            }}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenModal(action, i)}
                            startIcon={<IconLanguage size={16} />}
                          >
                            Localised Values
                          </Button>
                        </Box>
                      ) : (
                        <TextField
                          sx={{
                            textAlign: "center",
                            width: 400,
                            height: "40px",
                            "& input": {
                              padding: "8px",
                              height: "40px",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  NOTIFICATION_ACTION_LIST.find(
                                    (a) => a.id === action.id
                                  )?.unit
                                }
                              </InputAdornment>
                            ),
                          }}
                          type={
                            NOTIFICATION_ACTION_LIST.find(
                              (a) => a.id === action.id
                            )?.inputType
                          }
                          value={action.value}
                          onChange={(e) => {
                            const newActions = [...value];
                            newActions[i].value = e.target.value;
                            setValue(newActions);
                          }}
                        />
                      )}
                      <IconButton
                        color="error"
                        onClick={() => {
                          const newActions = [...actions];
                          newActions.splice(i, 1);
                          setValue(newActions);
                        }}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </Box>
                  );
                })}{" "}
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  className="secondaryButton"
                  onClick={() => {
                    if (currentVariant === 0) {
                      setActions([
                        ...actions,
                        {
                          id: "PUSH",
                          value: {
                            title: "",
                            text: "",
                            image: "",
                          },
                        },
                      ]);
                    } else {
                      const newVariants = [...variants];
                      newVariants[currentVariant - 1] = [
                        ...newVariants[currentVariant - 1],
                        {
                          id: "PUSH",
                          value: {
                            title: "",
                            text: "",
                            image: "",
                          },
                          localisedValues: {
                            EN: {
                              title: "",
                              text: "",
                              image: "",
                            },
                            FR: {
                              title: "",
                              text: "",
                              image: "",
                            },
                            DE: {
                              title: "",
                              text: "",
                              image: "",
                            },
                          },
                        },
                      ];
                      setVariants(newVariants);
                    }
                  }}
                >
                  + Add action
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            gap: 3,
          }}
        >
          {activeStep > 0 && (
            <Button
              className="secondaryButton"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="primaryButton"
            >
              {id ? "Update campaign" : "Create campaign"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className="primaryButton"
              disabled={
                activeStep === 0 &&
                (!campaignName ||
                  (trigger === "EVENT_TRIGGER" && !triggerOptions.eventName))
              }
            >
              Next
            </Button>
          )}
        </Box>
      </Card>

      <Dialog
        open={openModal !== null}
        onClose={() => setOpenModal(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Localised Values</DialogTitle>
        <DialogContent>
          {["EN", "FR", "DE"].map((lang) => (
            <Box
              key={lang}
              display="flex"
              flexDirection="column"
              gap={2}
              mb={2}
            >
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center"
              >
                <Typography variant="h6">
                  {lang === "EN"
                    ? "English"
                    : lang === "FR"
                    ? "French"
                    : "German"}
                </Typography>
                {lang !== "EN" && (
                  <Tooltip
                    title={
                      !modalData["EN"].title || !modalData["EN"].text
                        ? "You need to translate the title and text in English first"
                        : "Translate the title and text into " +
                          (lang === "FR" ? "French" : "German")
                    }
                  >
                    <span>
                      <Button
                        disabled={
                          translating.includes(lang) ||
                          !modalData["EN"].title ||
                          !modalData["EN"].text
                        }
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={async () => {
                          setTranslating((translating) => [
                            ...translating,
                            lang,
                          ]);
                          const response = await API.post(
                            `/notifications/translate`,
                            {
                              language:
                                lang === "EN"
                                  ? "English"
                                  : lang === "FR"
                                  ? "French"
                                  : "German",
                              title: modalData["EN"].title,
                              text: modalData["EN"].text,
                              image: modalData["EN"].image,
                            }
                          );
                          setModalData({
                            ...modalData,
                            [lang]: {
                              ...modalData[lang],
                              title: response.data.title,
                              text: response.data.text,
                              image: response.data.image,
                            },
                          });
                          setTranslating((translating) =>
                            translating.filter((t) => t !== lang)
                          );
                        }}
                        startIcon={
                          translating.includes(lang) ? (
                            <CircularProgress size={16} />
                          ) : (
                            <IconLanguage size={16} />
                          )
                        }
                      >
                        Translate
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Box>
              {translating.includes(lang) ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                </>
              ) : (
                <>
                  <TextField
                    label="Title"
                    value={modalData[lang].title}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        [lang]: { ...modalData[lang], title: e.target.value },
                      })
                    }
                  />
                  <TextField
                    label="Text"
                    value={modalData[lang].text}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        [lang]: { ...modalData[lang], text: e.target.value },
                      })
                    }
                  />
                  <TextField
                    label="Image URL"
                    value={modalData[lang].image}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        [lang]: { ...modalData[lang], image: e.target.value },
                      })
                    }
                  />
                </>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveModal}
            disabled={
              !modalData["EN"].title ||
              !modalData["EN"].text ||
              !modalData["FR"].title ||
              !modalData["FR"].text ||
              !modalData["DE"].title ||
              !modalData["DE"].text
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default CreateNotificationsDialog;
