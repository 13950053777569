import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const AddLoseNodeModal = ({
  open,
  setOpen,
  videos,
  selectedVideoId,
  setSelectedVideoId,
  handleAddStateNode,
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Choose Video</DialogTitle>
      <DialogContent>
        <Select
          sx={{ height: "50px", mt: 2 }}
          onChange={(e) => setSelectedVideoId(e.target.value)}
          fullWidth
        >
          {videos?.map((video) => (
            <MenuItem key={video.id} value={video.id}>
              {video.title}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleAddStateNode("LOSE", selectedVideoId);
            setOpen(false);
          }}
          variant="contained"
          color="error"
          className="primaryButton"
        >
          Add Lose node
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLoseNodeModal;
