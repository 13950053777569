import React from "react";

const SimulationIcon = ({ fill }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5622 18.75C21.5622 18.8991 21.503 19.0422 21.3975 19.1477C21.292 19.2532 21.1489 19.3125 20.9997 19.3125H2.99972C2.85053 19.3125 2.70746 19.2532 2.60197 19.1477C2.49648 19.0422 2.43722 18.8991 2.43722 18.75C2.43722 18.6008 2.49648 18.4577 2.60197 18.3522C2.70746 18.2467 2.85053 18.1875 2.99972 18.1875H20.9997C21.1489 18.1875 21.292 18.2467 21.3975 18.3522C21.503 18.4577 21.5622 18.6008 21.5622 18.75ZM2.56753 14.0625C2.45785 13.8367 2.41518 13.5843 2.44459 13.335C2.474 13.0858 2.57425 12.8502 2.73347 12.6562L10.3891 3.20621C10.5833 2.96618 10.8287 2.7726 11.1074 2.63962C11.3861 2.50664 11.6909 2.43762 11.9997 2.43762C12.3085 2.43762 12.6134 2.50664 12.892 2.63962C13.1707 2.7726 13.4161 2.96618 13.6103 3.20621L21.266 12.6562C21.4241 12.8499 21.524 13.0845 21.554 13.3328C21.5841 13.581 21.5431 13.8327 21.4357 14.0585C21.3284 14.2843 21.1592 14.4751 20.9478 14.6085C20.7363 14.742 20.4913 14.8127 20.2413 14.8125H3.75815C3.50897 14.8134 3.26468 14.7433 3.05384 14.6105C2.843 14.4777 2.67432 14.2876 2.56753 14.0625ZM3.58284 13.5787C3.59823 13.612 3.62304 13.64 3.6542 13.6594C3.68536 13.6787 3.7215 13.6885 3.75815 13.6875H20.2413C20.2781 13.6883 20.3144 13.6783 20.3456 13.6586C20.3768 13.6389 20.4015 13.6105 20.4166 13.5768C20.434 13.5434 20.4409 13.5054 20.4364 13.4679C20.4319 13.4305 20.4161 13.3952 20.3913 13.3668L12.7366 3.91496C12.6478 3.80498 12.5356 3.71626 12.4081 3.65531C12.2806 3.59435 12.141 3.56272 11.9997 3.56272C11.8584 3.56272 11.7189 3.59435 11.5913 3.65531C11.4638 3.71626 11.3516 3.80498 11.2628 3.91496L3.60815 13.3668C3.58329 13.3952 3.56758 13.4305 3.56306 13.4679C3.55854 13.5054 3.56544 13.5434 3.58284 13.5768V13.5787Z"
        fill={fill || "black"}
      />
    </svg>
  );
};

export default SimulationIcon;
