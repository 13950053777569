import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../interceptor/API";
import { enqueueSnackbar } from "notistack";

const CreateChannel = ({
  open,
  setOpen,
  fetchChannels,
  selectedChannel,
  fetchAdminChannels,
}) => {
  const [channelName, setChannelName] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      const id = localStorage.getItem("userId");
      setUserId(id);
    }
  }, []);

  const createChannel = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        name: channelName,
        adminId: userId,
      };

      const { data, status } = await API.post("/channels", payload);
      if (data && status === 200) {
        setOpen(false);
        enqueueSnackbar({
          message: "Channel Created Successfully!",
          variant: "success",
        });
        setChannelName("");
        fetchChannels();
        fetchAdminChannels();
      }
    } catch (error) {
      enqueueSnackbar({
        message: error?.response?.data?.error || "Failed to Create Channel",
        variant: "error",
      });
      setOpen(false);
      setChannelName("");
    }
  };

  const updateChannel = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        name: channelName,
        adminId: userId,
      };
      const { data, status } = await API.put(
        `/channels/${selectedChannel?.id}`,
        payload
      );
      if (data && status === 200) {
        fetchChannels();
        fetchAdminChannels();
        setOpen(false);
        enqueueSnackbar({
          message: "Channel Updated Successfully!",
          variant: "success",
        });
        setChannelName("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedChannel) {
      setChannelName(selectedChannel?.name);
    }
  }, [selectedChannel]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          width: 470,
          bgcolor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 3,
        }}
        component="form"
        onSubmit={selectedChannel?.id ? updateChannel : createChannel}
        noValidate
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          {selectedChannel?.id ? "Update Channel" : "Create Channel"}
        </Typography>

        <TextField
          required
          fullWidth
          id="name"
          name="name"
          autoComplete="name"
          placeholder="Channel Name"
          sx={{
            mt: 3,
            textAlign: "center",
            "& input": {
              padding: "8px",
              height: "30px",
            },
            "input::placeholder": {
              color: "var(--placeholderTextColor)",
            },
          }}
          onChange={(e) => setChannelName(e.target.value)}
          value={channelName}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="primaryButton"
          sx={{ mt: 3, mb: 2 }}
          data-testid="create-series"
        >
          {selectedChannel?.id ? "Update Channel" : "Create Channel"}
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateChannel;
