import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";

const Loader = () => {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "90vh",
        }}
      >
        <CircularProgress thickness={3} size={40} />
      </Box>
    </Layout>
  );
};

export default Loader;
