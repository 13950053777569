import React from "react";

const ViewsIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z"
        stroke="#70777F"
      />
      <path
        d="M10.094 5.467C10.288 5.703 10.385 5.8205 10.385 6C10.385 6.1795 10.288 6.297 10.094 6.533C9.38399 7.395 7.81799 9 5.99999 9C4.18199 9 2.61599 7.395 1.90599 6.533C1.71199 6.297 1.61499 6.1795 1.61499 6C1.61499 5.8205 1.71199 5.703 1.90599 5.467C2.61599 4.605 4.18199 3 5.99999 3C7.81799 3 9.38399 4.605 10.094 5.467Z"
        stroke="#70777F"
      />
    </svg>
  );
};

export default ViewsIcon;
