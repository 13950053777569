import React from "react";

const RewardsIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9013 3.69044L11.9001 5.68793C12.0347 5.96418 12.3959 6.22626 12.7005 6.28293L14.5068 6.58043C15.6613 6.77168 15.9305 7.60751 15.1018 8.44335L13.6922 9.85293C13.4584 10.0867 13.3238 10.5471 13.4018 10.88L13.8055 12.6225C14.1242 13.9967 13.3876 14.535 12.1763 13.8125L10.4834 12.8067C10.1788 12.6225 9.66884 12.6225 9.36425 12.8067L7.67133 13.8125C6.46008 14.5279 5.72342 13.9967 6.04217 12.6225L6.44592 10.88C6.52384 10.5542 6.38926 10.0938 6.15551 9.85293L4.74593 8.44335C3.91718 7.6146 4.18634 6.77876 5.34093 6.58043L7.14717 6.28293C7.45175 6.23335 7.813 5.96418 7.94759 5.68793L8.94634 3.69044C9.47759 2.60669 10.3559 2.60669 10.9013 3.69044Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M5.6665 3.54169H1.4165"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M3.5415 13.4583H1.4165"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M2.12484 8.5H1.4165"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RewardsIcon;
