import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Input,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import fetchWithUserToken from "../utils/fetchWithUserToken";
import Layout from "../components/Layout/Layout";

const CreateInteractiveStory = () => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);

  useEffect(() => {
    if (
      !localStorage.getItem("permissions")?.includes("createInteractiveStories")
    ) {
      window.location.href = "/series";
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append(
      "language",
      new URLSearchParams(window.location.search).get("language")
    );

    try {
      await fetchWithUserToken(
        `${process.env.REACT_APP_API_ENDPOINT}/interactive-story`,
        {
          method: "POST",
          body: formData,
        }
      ).then(() => {
        window.location.href = `/series?language=${formData.get("language")}`;
      });
    } catch (error) {
      console.error("Error creating interactive story:", error);
    }
  };

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const response = await fetchWithUserToken(
          `${process.env.REACT_APP_API_ENDPOINT}/admin/channels`
        );
        const data = await response.json();
        setChannels(data);
        setSelectedChannel(data?.at(0)?.id);
      } catch (error) {
        console.error("Error fetching interactive story:", error);
        enqueueSnackbar("Failed to fetch interactive story", {
          variant: "error",
        });
      }
    };
    fetchChannel();
  }, []);

  return (
    <Layout>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <h1 data-testid="component-title">Create Interactive story</h1>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          autoFocus
        />

        <Select
          value={selectedChannel}
          placeholder="Select Channel"
          onChange={(e) => setSelectedChannel(e.target.value)}
          sx={{ maxHeight: 50 }}
          fullWidth
        >
          {channels?.map((channel) => (
            <MenuItem key={channel.id} value={channel.id}>
              {channel.name}
            </MenuItem>
          ))}
        </Select>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="cover">Cover</InputLabel>
          <Input required id="cover" name="cover" type="file" />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Create Interactive Story
        </Button>
      </Box>
    </Layout>
  );
};

export default CreateInteractiveStory;
