import React from "react";

const FileUploadIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_132_312)">
        <path
          d="M18 3C26.2845 3 33 9.7155 33 18C33.0019 20.3289 32.4607 22.6261 31.4195 24.7092C30.3782 26.7923 28.8655 28.6039 27.0015 30H30C30.3978 30 30.7794 30.158 31.0607 30.4393C31.342 30.7206 31.5 31.1022 31.5 31.5C31.5 31.8978 31.342 32.2794 31.0607 32.5607C30.7794 32.842 30.3978 33 30 33H18C9.7155 33 3 26.2845 3 18C3 9.7155 9.7155 3 18 3ZM18 21C17.2044 21 16.4413 21.3161 15.8787 21.8787C15.3161 22.4413 15 23.2044 15 24C15 24.7956 15.3161 25.5587 15.8787 26.1213C16.4413 26.6839 17.2044 27 18 27C18.7956 27 19.5587 26.6839 20.1213 26.1213C20.6839 25.5587 21 24.7956 21 24C21 23.2044 20.6839 22.4413 20.1213 21.8787C19.5587 21.3161 18.7956 21 18 21ZM12 15C11.2044 15 10.4413 15.3161 9.87868 15.8787C9.31607 16.4413 9 17.2044 9 18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18C15 17.2044 14.6839 16.4413 14.1213 15.8787C13.5587 15.3161 12.7956 15 12 15ZM24 15C23.2044 15 22.4413 15.3161 21.8787 15.8787C21.3161 16.4413 21 17.2044 21 18C21 18.7956 21.3161 19.5587 21.8787 20.1213C22.4413 20.6839 23.2044 21 24 21C24.7956 21 25.5587 20.6839 26.1213 20.1213C26.6839 19.5587 27 18.7956 27 18C27 17.2044 26.6839 16.4413 26.1213 15.8787C25.5587 15.3161 24.7956 15 24 15ZM18 9C17.2044 9 16.4413 9.31607 15.8787 9.87868C15.3161 10.4413 15 11.2044 15 12C15 12.7956 15.3161 13.5587 15.8787 14.1213C16.4413 14.6839 17.2044 15 18 15C18.7956 15 19.5587 14.6839 20.1213 14.1213C20.6839 13.5587 21 12.7956 21 12C21 11.2044 20.6839 10.4413 20.1213 9.87868C19.5587 9.31607 18.7956 9 18 9Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_312">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileUploadIcon;
