import React from "react";

const PlayIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.37691 11.1719C3.22102 11.1716 3.06789 11.1307 2.93258 11.0533C2.62789 10.8806 2.43848 10.5455 2.43848 10.1816V2.81834C2.43848 2.45348 2.62789 2.11934 2.93258 1.94668C3.07111 1.86707 3.22848 1.82612 3.38825 1.82813C3.54802 1.83014 3.70432 1.87503 3.8408 1.95811L10.1339 5.72506C10.265 5.8073 10.3731 5.9215 10.4481 6.05695C10.523 6.1924 10.5623 6.34467 10.5623 6.49948C10.5623 6.65428 10.523 6.80655 10.4481 6.942C10.3731 7.07745 10.265 7.19165 10.1339 7.27389L3.83978 11.0419C3.70011 11.1263 3.54013 11.1712 3.37691 11.1719Z"
        fill="#6466F1"
      />
    </svg>
  );
};

export default PlayIcon;
