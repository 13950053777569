import React from "react";
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { InputStyle } from "../pages/Alerts";
import styled from "styled-components";
import API from "../interceptor/API";

const CreateNewAlert = ({ open, setOpen }) => {
  const [eventTypes, setEventTypes] = useState([]);
  const [values, setValues] = useState({
    name: "",
    event: "",
    platform: "",
    period: "",
    percentage: "",
  });

  const fetchEventTypes = async () => {
    try {
      const { data, status } = await API.get("/events/types");
      if (data && status === 200) {
        setEventTypes(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  const handleCreateAlert = async () => {
    try {
      const payload = {
        name: values.name,
        event: values.event,
        platform: values.platform,
        period: values.period,
        compare: values.compare,
        percentage: values.percentage,
      };
      const { data, status } = await API.post("/alerts", payload);
      if (data && status === 200) {
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          py: 3,
        }}
      >
        <h2 className="modalTitle" data-testid="create-new-alert">
          Create New Alert
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
          }}
        >
          <div>
            <Label>Name</Label>
            <TextField
              sx={InputStyle}
              fullWidth
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>
          <div>
            <Label>Select event type:</Label>
            <Select
              sx={InputStyle}
              fullWidth
              onChange={(e) => setValues({ ...values, event: e.target.value })}
            >
              {eventTypes?.map?.((event) => (
                <MenuItem key={event.name} value={event.name}>
                  <ListItemText
                    primary={event.name + " (" + event._count.name + ")"}
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <Label>Select OS: </Label>
            <Select
              sx={InputStyle}
              fullWidth
              onChange={(e) =>
                setValues({ ...values, platform: e.target.value })
              }
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="IOS">iOS</MenuItem>
              <MenuItem value="Android">Android</MenuItem>
            </Select>
          </div>

          <div>
            <Label>Select period:</Label>
            <Select
              sx={InputStyle}
              fullWidth
              onChange={(e) => setValues({ ...values, period: e.target.value })}
            >
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={360}>6 hours</MenuItem>
              <MenuItem value={720}>12 hours</MenuItem>
            </Select>
            <Label>Will be compared to same period 24 hrs ago</Label>
          </div>
        </div>
        <div>
          <Label>Percentage</Label>
          <TextField
            sx={InputStyle}
            fullWidth
            type="number"
            inputProps={{ min: 0, max: 100 }}
            onChange={(e) =>
              setValues({ ...values, percentage: e.target.value })
            }
          />
          <Label>Trigger if the average events/min drop is more than: </Label>
        </div>
        <Button
          sx={{ my: 2 }}
          variant="contained"
          className="primaryButton"
          color="primary"
          onClick={handleCreateAlert}
          disabled={
            !values.name ||
            !values.event ||
            !values.period ||
            !values.percentage
          }
        >
          Create Alert
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateNewAlert;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #70777f;
`;
