import { ThemeProvider } from "@mui/system";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import styled from "styled-components";
import InteractiveStory from "./components/InteractiveStory";
import SpinningWheel from "./components/SpinningWheel";
import Alerts from "./pages/Alerts";
import ApiStats from "./pages/ApiStats";
import Channels from "./pages/Channels";
import CreateInteractiveStory from "./pages/CreateInteractiveStory";
import DailyRewards from "./pages/DailyRewards";
import EditSeries from "./pages/EditSeries";
import InteractiveStories from "./pages/InteractiveStories";
import Login2 from "./pages/Login2";
import NotificationCampaign from "./pages/NotificationCampaign";
import Notifications from "./pages/Notifications";
import Register from "./pages/Register";
import SpinningWheelConfiguration from "./pages/SpinningWheelConfiguration";
import UserInteractiveStories from "./pages/user/InteractiveStories";
import InteractiveStoryDetails from "./pages/user/InteractiveStoryDetails";
import Login from "./pages/user/Login";
import UsersEvents from "./pages/UsersEvents";
import UserSpinStats from "./pages/UserSpinStats";
import WheelStimulation from "./pages/WheelStimulation";
import { createTheme } from "./theme";
import fetchWithUserToken from "./utils/fetchWithUserToken";
import ViewInteractiveStory from "./pages/ViewInteractiveStory";
import API from "./interceptor/API";

const Experiments = React.lazy(() => import("./pages/Experiments"));
const Payment = React.lazy(() => import("./pages/Payment"));
const SeriesList = React.lazy(() => import("./pages/SeriesList"));
const Landing = React.lazy(() => import("./pages/Landing"));
const Users = React.lazy(() => import("./pages/Users"));
const Video = React.lazy(() => import("./components/Video"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Logs = React.lazy(() => import("./pages/Logs"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Events = React.lazy(() => import("./pages/Events"));
const Comments = React.lazy(() => import("./pages/Comments"));
const Logins = React.lazy(() => import("./pages/Logins"));

const router = createBrowserRouter([
  { path: "/", element: <Landing /> },
  { path: "/series", element: <SeriesList /> },
  { path: "/comments", element: <Comments /> },
  { path: "/channels", element: <Channels /> },
  { path: "/payment", element: <Payment /> },
  { path: "/videos/:id", element: <Video /> },
  { path: "/interactive-story", element: <CreateInteractiveStory /> },
  { path: "/users", element: <Users /> },
  { path: "/users/:userId", element: <Users /> },
  { path: "/stats", element: <ApiStats /> },
  { path: "/login", element: <Login2 /> },
  { path: "/register", element: <Register /> },
  { path: "/experiments", element: <Experiments /> },
  { path: "/admin", element: <Admin /> },
  { path: "/logs", element: <Logs /> },
  { path: "/reviews", element: <Reviews /> },
  { path: "/events", element: <Events /> },
  { path: "/alerts", element: <Alerts /> },
  { path: "/daily-rewards", element: <DailyRewards /> },
  { path: "/spinning-wheel", element: <SpinningWheelConfiguration /> },
  { path: "/spin", element: <SpinningWheel /> },
  { path: "/user-spin-stats", element: <UserSpinStats /> },
  { path: "/wheel-stimulation", element: <WheelStimulation /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/edit/series/:id", element: <EditSeries /> },
  { path: "/notifications/campaign", element: <NotificationCampaign /> },
  { path: "/users/userEvents/:id", element: <UsersEvents /> },
  { path: "/interactive-stories", element: <InteractiveStories /> },
  { path: "/interactive-stories/:id", element: <InteractiveStory /> },
  { path: "/interactive-stories/view/:id", element: <ViewInteractiveStory /> },
  { path: "/logins", element: <Logins /> },
  { path: "/user/login", element: <Login /> },
  { path: "/user/interactive-stories", element: <UserInteractiveStories /> },
  {
    path: "/user/interactive-stories/:id",
    element: <InteractiveStoryDetails />,
  },
]);

function App() {
  const theme = createTheme();
  const [role, setRole] = useState("");

  useEffect(() => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.location.href =
        "https://apps.apple.com/us/app/zumi-movies-and-action/id6476808915";
    } else if (navigator.userAgent.match(/Android/i)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.herogram.animereel&hl=en&pli=1";
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (
      token &&
      (window.location.pathname === "/login" ||
        window.location.pathname === "/register" ||
        window.location.pathname === "/")
    ) {
      // window.location.href = "/series";
    } else if (
      !token &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/"
    ) {
      // window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("role")) {
      const userRole = localStorage.getItem("role");
      setRole(userRole);
    }
  }, [role]);

  // every 30 seconds get the user from the id in local storage
  useEffect(() => {
    const fn = () => {
      if (localStorage.getItem("userId")) {
        fetchWithUserToken(
          `${process.env.REACT_APP_API_ENDPOINT}/admins/${localStorage.getItem(
            "userId"
          )}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (
              data.permissions.length === 0 &&
              window.location.pathname !== "/payment"
            ) {
              window.location.href = "/payment";
            }
            localStorage.setItem("permissions", data.permissions);
          });
      }
    };
    const interval = setInterval(fn, 30000);
    fn();
    return () => clearInterval(interval);
  }, []);

  // users/userEvents/9135310c-979b-4692-8b18-d61b4633b7fd

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Main
          isSidebarHidden={
            window.location.pathname === "/login" ||
            window.location.pathname === "/register" ||
            window.location.pathname === "/payment" ||
            window.location.pathname === "/" ||
            window.location.pathname.startsWith("/user/") ||
            !localStorage.getItem("token")
              ? true
              : false
          }
          role={role}
        >
          <SnackbarProvider />
          <RouterProvider router={router} />
        </Main>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;

// const Main = styled.div.attrs((props) => ({
//   issidebarhidden: undefined,
//   role: props.role,
// }))`
//   height: 100%;
//   min-height: auto;

//   ${(props) =>
//     props.issidebarhidden
//       ? ` margin-left: 0;
//           width: 100%;`
//       : props.role === "user"
//       ? `margin-left: 0px;`
//       : `margin-left: 300px;`}
// `;

const Main = styled.div`
  height: 100%;
  min-height: auto;

  ${(props) =>
    props.isSidebarHidden
      ? ` margin-left: 0;
    width: 100%;`
      : `margin-left:300px;`}
`;
