import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

import { enqueueSnackbar } from "notistack";
import "reactflow/dist/style.css";
import API from "../interceptor/API";
import AddLoseNodeModal from "../modals/AddLoseNodeModal";
import EditVideoStoryName from "../modals/EditVideoStoryName";
import InteractiveStoryVideoModal from "../modals/InteractiveStoryVideoModal";
import { initialNodes } from "../utils/constants";
import InteractiveStorySidebar from "./InteractiveStorySidebar";
import Layout from "./Layout/Layout";
import { CustomEdge, StartNode, StateNode } from "./ReactFlowFunctions";
import StoriesIcon from "./icons/StoriesIcon";

const customNodeTypes = {
  start: StartNode,
  state: StateNode,
};

const customEdgeTypes = {
  default: CustomEdge,
};

export default function InteractiveStory() {
  const { id } = useParams();
  const fileInputRef = useRef();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [refresh, setRefresh] = useState({});
  const [videos, setVideos] = useState([]);
  const [interactiveStory, setInteractiveStory] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoName, setVideoName] = useState("");
  const [chooseVideoModalOpen, setChooseVideoModalOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [openVideoDetails, setOpenVideoDetails] = useState(false);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            // set arrow
            markerEnd: {
              type: MarkerType.Arrow,
            },
            data: { label: "", coins: 0, diamonds: 0 },
          },
          eds
        )
      ),
    [setEdges]
  );

  const handleAddVideo = ({ id: videoId, title }) => {
    const id = String(nodes.length);
    const newVideo = {
      id: crypto.randomUUID(),
      position: { x: 0, y: 0 },
      data: { label: title, id: videoId },
    };
    setNodes([...nodes, newVideo]);
  };

  const handleAddStateNode = (state, loseVideoId) => {
    if (loseVideoId === undefined && state === "LOSE") {
      setChooseVideoModalOpen(true);
      return;
    }
    // Generate a more unique ID
    const id = `node-${Date.now()}-${Math.random()}`;
    const title = videos.find((video) => video.id === loseVideoId)?.title;
    const newNode = {
      id,
      position: { x: 0, y: 0 },
      type: "state",
      data: { state, id: loseVideoId, label: title },
    };
    setNodes([...nodes, newNode]);
  };

  const handleFileChange = (evt) => {
    const { files } = evt.target;
    if (files && files[0]) {
      uploadVideo(files[0]);
    }
  };

  const uploadVideo = async (file) => {
    try {
      if (!file) return;

      setUploading(true);
      const formData = new FormData();
      formData.append("interactiveStoryId", id);
      formData.append("videos", file);
      formData.append("name", videoName);
      setVideoName("");

      const { data, status } = await API.post(`/videos/upload`, formData);
      if (data && status === 200) {
        enqueueSnackbar({
          message: "Uploaded successfully!",
          variant: "success",
        });
        setTimeout(() => {
          setRefresh({});
        }, 1000);
      }
    } catch (err) {
      console.error("uploadVideo", { err });
    } finally {
      setUploading(false);
      setVideoName("");
    }
  };

  const fetchVideos = async () => {
    try {
      const { data, status } = await API.get(
        `/interactive-stories/${id}/videos`
      );
      if (data && status === 200) {
        setVideos(data);
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch videos", {
        variant: "error",
      });
    }
  };

  const fetchInteractiveStory = async () => {
    const { data, status } = await API.get(`/interactive-stories/${id}`);
    if (data && status === 200) {
      setInteractiveStory(data);

      const mappedNodes = data?.nodes?.map((node) => {
        return {
          id: node.id,
          position: node.position,
          type: node.state ? "state" : undefined,
          data: {
            id: node.videoId,
            label: node.video?.title,
            state: node.state,
          },
        };
      });

      const mappedEdges = data?.edges?.map((edge) => {
        return {
          id: edge.id,
          type: "default",
          source: edge.fromId,
          target: edge.toId,
          data: {
            label: edge.label,
            coins: edge.coins,
            diamonds: edge.diamonds,
          },
        };
      });

      if (!mappedNodes.length) return;

      // extra edge for node with id start
      const startNodeId = data?.startNode?.id;

      if (startNodeId) {
        mappedEdges?.push({
          id: "start",
          source: "start",
          target: startNodeId,
        });

        mappedNodes?.push({
          id: "start",
          type: "start",
          position: { x: 40, y: 0 },
        });
      }

      setNodes(mappedNodes);
      setEdges(mappedEdges);
    }
    try {
    } catch (error) {
      enqueueSnackbar("Failed to fetch interactive story", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [refresh, id]);

  useEffect(() => {
    if (id) {
      fetchInteractiveStory();
    }
  }, [id]);

  const checkForDuplicateIds = (nodes) => {
    const ids = nodes?.map((node) => node.id);
    return new Set(ids).size !== ids.length;
  };

  //move mapNodes out of the useEffect for better readability
  const mapNodes = (nodes) => {
    return nodes
      .filter((node) => node.id !== "start")
      .map((node) => ({
        id: node.id,
        position: node.position,
        videoId: node.data.id,
        state: node.data.state,
      }));
  };

  //move mapEdges out of the useEffect for better readability
  const mapEdges = (edges) => {
    return edges
      .filter((edge) => edge.source !== "start")
      .map((edge) => ({
        id: edge.id,
        source: edge.source,
        destination: edge.target,
        label: edge.data.label,
        coins: edge.data.coins,
        diamonds: edge.data.diamonds,
      }));
  };

  const fetchInteractiveStoryData = async (id, payload) => {
    try {
      const { data, status } = await API.put(
        `/interactive-stories/${id}`,
        payload
      );
      if (data && status === 200) {
        enqueueSnackbar({
          message: "Flow Updated successfully!",
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error updating interactive story", error);
    }
  };

  useEffect(() => {
    const updateInteractiveStory = async () => {
      if (!interactiveStory) return;

      const mappedNodes = mapNodes(nodes);
      const mappedEdges = mapEdges(edges);
      const startNodeId = edges.find((edge) => edge.source === "start")?.target;

      if (!mappedNodes.length) return;

      if (checkForDuplicateIds(mappedNodes)) {
        console.error("Duplicate node IDs detected");
        return;
      }

      const payload = {
        nodes: mappedNodes,
        edges: mappedEdges,
        startId: startNodeId,
      };

      await fetchInteractiveStoryData(id, payload);
    };

    updateInteractiveStory();
  }, [nodes.length, edges.length, interactiveStory, id]);

  const handleVideoDetails = (id) => {
    setOpenVideoDetails((prev) => (prev === id ? null : id));
  };

  return (
    <Layout pageTitle={"Interactive Story Details"} icon={<StoriesIcon />}>
      <div
        style={{
          height: "93vh",
          display: "flex",
          flexDirection: "row",
          border: "1px solid var(--borderColor)",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={customNodeTypes}
          edgeTypes={customEdgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>

        <AddLoseNodeModal
          open={chooseVideoModalOpen}
          setOpen={setChooseVideoModalOpen}
          videos={videos}
          setSelectedVideoId={setSelectedVideo}
          selectedVideoId={selectedVideoId}
          handleAddStateNode={handleAddStateNode}
        />

        <EditVideoStoryName
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          setRefresh={setRefresh}
        />

        <InteractiveStoryVideoModal
          selectedVideoUrl={selectedVideoUrl}
          setSelectedVideoUrl={setSelectedVideoUrl}
        />

        <InteractiveStorySidebar
          interactiveStory={interactiveStory}
          fileInputRef={fileInputRef}
          uploading={uploading}
          handleFileChange={handleFileChange}
          handleAddStateNode={handleAddStateNode}
          openVideoDetails={openVideoDetails}
          handleVideoDetails={handleVideoDetails}
          setSelectedVideoUrl={setSelectedVideoUrl}
          handleAddVideo={handleAddVideo}
          setSelectedVideo={setSelectedVideo}
          setRefresh={setRefresh}
          videos={videos}
        />
      </div>
    </Layout>
  );
}
