import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import CreateSeriesModal from "../modals/CreateSeriesModal";
import API from "../interceptor/API";
import { STORIES_TABLE_HEAD } from "../utils/constants";
import styled from "styled-components";
import moment from "moment";
import EditIcon from "../components/icons/EditIcon";
import Delete from "../components/icons/Delete";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "../components/ConfirmationPopup";
import { enqueueSnackbar } from "notistack";
import StoriesIcon from "../components/icons/StoriesIcon";
import Loader from "../components/Loader";
import CustomPagination from "../components/CustomPagination";

const InteractiveStories = () => {
  const navigate = useNavigate();
  const [openStoryModal, setOpenStoryModal] = useState(false);
  const [stories, setStories] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChange = (event, value) => {
    setActivePage(value);
  };

  useEffect(() => {
    if (
      !localStorage.getItem("permissions")?.includes("createInteractiveStories")
    ) {
      window.location.href = "/series";
    }
  }, []);

  const fetchStories = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(
        `/interactive-stories?page=${activePage}`
      );
      if (data && status === 200) {
        setStories(data?.interactiveStories);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStories();
  }, [activePage]);

  const deleteInteractiveStory = async () => {
    try {
      const { status, data } = await API.delete(
        `/interactive-stories/${storyToDelete?.id}`
      );
      if (data && status === 200) {
        enqueueSnackbar({
          variant: "success",
          message: "Interactive Story Deleted Successfully!",
        });
        setDeleteConfirmation(false);
        fetchStories();
      }
    } catch (error) {
      console.error(error, "errror");
    }
  };

  const handleDeleteConfirmation = (channel) => {
    setStoryToDelete(channel);
    setDeleteConfirmation(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout pageTitle={"Interactive Stories"} icon={<StoriesIcon />}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <h1 className="pageHeading" data-testid="page-heading">
          Interactive Stories
        </h1>
        <Button
          variant="contained"
          className="primaryButton"
          onClick={() => setOpenStoryModal(true)}
          data-testid="create-interactive-story"
        >
          <AddvariationIcon /> &nbsp; Create Interactive Story
        </Button>
      </Box>

      <Card
        sx={{
          borderRadius: "5px",
          mt: 3,
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "lightgray" }}>
            <TableRow>
              {STORIES_TABLE_HEAD.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stories?.length > 0 &&
              stories?.map?.((story, index) => (
                <TableRow
                  key={index}
                  style={{
                    padding: "20px",
                  }}
                >
                  <TableCell>
                    <TableData data-testid={`storyId-${index}`}>
                      {story?.id}
                    </TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>
                      <Box display={"flex"} alignItems={"center"} gap={2}>
                        {story?.cover?.includes("mp4") ? (
                          <video
                            src={story?.cover}
                            style={{
                              height: "40px",
                              width: "30px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        ) : (
                          <img
                            src={story?.cover}
                            style={{
                              height: "40px",
                              width: "30px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        )}

                        {story?.title}
                      </Box>
                    </TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>{story?.language}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>
                      {moment(alert?.createdAt).format("YYYY-MM-DD")}
                    </TableData>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Typography
                        component="span"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/interactive-stories/${story.id}`)
                        }
                      >
                        <EditIcon />
                      </Typography>

                      <Typography
                        component="span"
                        sx={{ cursor: "pointer" }}
                        data-testid={`delete-btn-${index}`}
                        onClick={() => handleDeleteConfirmation(story)}
                      >
                        <Delete />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>

      <CustomPagination
        activePage={activePage}
        totalPages={totalPages}
        handleChange={handleChange}
      />
      <CreateSeriesModal
        open={openStoryModal}
        setOpen={setOpenStoryModal}
        modalType="Interactive Story"
      />
      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={deleteInteractiveStory}
        message="Are you sure you want to delete this interactive story?"
      />
    </Layout>
  );
};

export default InteractiveStories;

const TableData = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 10px 0px;
`;
