import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import styled from "styled-components";
import FileUploadIcon from "../icons/FileUploadIcon";
import API from "../../interceptor/API";
import { enqueueSnackbar } from "notistack";
import { TextareaAutosize } from "../Mui/TextareaAutosize";

const Information = ({
  checkPermission,
  series,
  updateSeriesTitle,
  updateRotation,
  handleDeleteConfirmation,
  seriesTrailerLoading,
  handleTrailerClick,
  setSeriesTrailerLoading,
  setRefresh,
  fileName,
}) => {
  const trailerRef = useRef();
  if (!series) return null;
  return (
    <Grid sm={6} item>
      <Box>
        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
          Information
        </Typography>
        <FormControl
          className="inputFormControl"
          sx={{ mt: 4, display: "flex", gap: 2 }}
        >
          {/* update title */}
          {/* update visible */}
          {series?.visible !== undefined ? (
            <FormControl
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Switch
                defaultChecked={series.visible}
                onChange={(e) =>
                  API.put(`/series/${series.id}`, {
                    visible: e.target.checked,
                  })
                }
              />
              <FormLabels>Visible on the app</FormLabels>
            </FormControl>
          ) : (
            <Skeleton width="100%" height="40px" />
          )}
          <Box>
            <FormLabels>Title</FormLabels>
            {(() => {
              const isUserAllowedEditSeries = checkPermission("editSeries");

              if (!isUserAllowedEditSeries) {
                return (
                  //read only input
                  <TextField
                    id="outlined-read-only-input"
                    variant="outlined"
                    margin="dense"
                    value={series?.title}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "8px",
                      "& .MuiInputBase-root": {
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                );
              } else {
                if (series?.title) {
                  return (
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        margin="dense"
                        id="title"
                        type="text"
                        defaultValue={series.title}
                        sx={{
                          fontSize: "35px",
                          width: "100%",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#fff",
                          },
                          "& .MuiInputBase-root:hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography
                                variant="contained"
                                onClick={updateSeriesTitle}
                                fontSize="12px"
                                fontWeight={600}
                                color={"#6466F1"}
                                sx={{ cursor: "pointer" }}
                              >
                                Save
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  );
                }
              }
            })()}
          </Box>

          {/* update visible */}

          {/* update description */}
          <Box>
            <FormLabels>Description</FormLabels>
            <TextareaAutosize
              className="w-full"
              placeholder="Description"
              minRows={3}
              defaultValue={series.description}
              onChange={async (e) => {
                await API.put(`/series/${series.id}`, {
                  description: e.target.value,
                });
              }}
            />
          </Box>

          {/* update unlocks after */}
          <Box>
            <FormLabels>Unlocks</FormLabels>
            <TextField
              margin="dense"
              onChange={async (e) => {
                await API.put(`/series/${series.id}`, {
                  unlockAfterMin: parseInt(e.target.value),
                });
              }}
              defaultValue={series?.unlockAfterMin || 0}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Minutes</InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "8px",
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </Box>
          {/* minLevel */}
          {series?.minLevel !== undefined && (
            <Box>
              <FormLabels>Min Level to access</FormLabels>
              <TextField
                margin="dense"
                onChange={async (e) => {
                  await API.put(`/series/${series.id}`, {
                    minLevel: parseInt(e.target.value),
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Level</InputAdornment>
                  ),
                }}
                type="number"
                defaultValue={series.minLevel}
                sx={{ width: "100%" }}
              />
            </Box>
          )}

          {/* update duration */}
          <Box>
            <FormLabels>Duration</FormLabels>
            <TextField
              margin="dense"
              onChange={async (e) => {
                await API.put(`/series/${series.id}`, {
                  duration: e.target.value,
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Minutes</InputAdornment>
                ),
              }}
              type="number"
              defaultValue={series.duration}
              sx={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "8px",
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </Box>

          {/* update resolution */}
          <Box>
            {(() => {
              const isUserAllowedEditSeries = checkPermission("editSeries");

              if (!isUserAllowedEditSeries) {
                return null;
              }

              return (
                <>
                  <FormLabels
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    Resolution
                  </FormLabels>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#70777F",
                        lineHeight: "15px",
                      }}
                    >
                      If the switch is on, the device is in landscape mode; if
                      off, it is in portrait mode.
                    </Typography>
                    <Switch
                      label="Hello"
                      checked={series?.rotation === "LANDSCAPE"}
                      onChange={() => updateRotation(series)}
                    />
                  </Box>
                </>
              );
            })()}

            <Box sx={{ mt: 2 }}>
              <FormLabels>Trailer</FormLabels>
              <Section>
                {(() => {
                  const isUserAllowedAddTrailer = checkPermission("addTrailer");

                  if (!isUserAllowedAddTrailer) {
                    return null;
                  }

                  if (series?.Trailer) {
                    return (
                      <Box display="flex" flexDirection="column" gap={1} ml="0">
                        <Typography
                          variant="caption"
                          sx={{
                            maxWidth: "120px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {series?.Trailer?.title}
                        </Typography>

                        <Button
                          variant="contained"
                          onClick={() =>
                            handleDeleteConfirmation("seriesTrailer", series)
                          }
                          sx={{
                            padding: 1,
                            maxHeight: "30px",
                            ml: "auto",
                          }}
                        >
                          Remove trailer
                        </Button>
                      </Box>
                    );
                  }

                  if (seriesTrailerLoading) {
                    return (
                      <CircularProgress
                        sx={{
                          marginLeft: "auto",
                          mr: 1,
                        }}
                      />
                    );
                  }

                  return (
                    <>
                      <Box
                        sx={{
                          border: "1px dashed black",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          py: 4,
                          mt: 1,
                        }}
                      >
                        <FileUploadIcon />

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 600,
                            mt: 2,
                            cursor: "pointer",
                          }}
                          onClick={handleTrailerClick}
                        >
                          Drag and drop video file{" "}
                          <span style={{ color: "#605BFF" }}>
                            or select files to upload
                          </span>
                        </Typography>
                        <input
                          type="file"
                          ref={trailerRef}
                          style={{ display: "none" }}
                          onChange={async (e) => {
                            const file = e.target.files[0];

                            const formData = new FormData();
                            formData.append("trailer", file);

                            setSeriesTrailerLoading(series?.id);
                            enqueueSnackbar(
                              "Uploading trailer... Don't close the page",
                              {
                                variant: "info",
                              }
                            );
                            const { data, status } = await API.post(
                              `/series/${series.id}/trailer`,
                              formData
                            );
                            if (data && status === 200) {
                              setRefresh({});
                            }
                          }}
                        />

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 500,
                            color: "#505762",
                            mt: 1,
                            textAlign: "center",
                            opacity: "50%",
                          }}
                        >
                          Any file up to 1GB : {fileName}
                        </Typography>
                      </Box>
                    </>
                  );
                })()}
              </Section>
            </Box>
          </Box>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default Information;

export const FormLabels = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const Section = styled.div`
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 2px;
`;
