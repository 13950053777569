import React from "react";

const ProgressIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#C6FFD6" />
      <path
        d="M8 4.79163L10.5 7.54163M8 4.79163L5.5 7.54163M8 4.79163V11.2083"
        stroke="#29936D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProgressIcon;
