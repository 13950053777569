import React from "react";

const StoriesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2266_338)">
        <path
          d="M38.06 7.11961C34.72 4.33961 30.58 2.51961 26 2.09961V6.11961C29.46 6.49961 32.62 7.87961 35.22 9.95961L38.06 7.11961Z"
          fill="black"
        />
        <path
          d="M22.0004 6.11961V2.09961C17.4204 2.49961 13.2804 4.33961 9.94043 7.11961L12.7804 9.95961C15.3804 7.87961 18.5404 6.49961 22.0004 6.11961Z"
          fill="black"
        />
        <path
          d="M9.95961 12.7795L7.11961 9.93945C4.33961 13.2795 2.51961 17.4195 2.09961 21.9995H6.11961C6.49961 18.5395 7.87961 15.3795 9.95961 12.7795Z"
          fill="black"
        />
        <path
          d="M41.88 21.9995H45.9C45.48 17.4195 43.66 13.2795 40.88 9.93945L38.04 12.7795C40.12 15.3795 41.5 18.5395 41.88 21.9995Z"
          fill="black"
        />
        <path
          d="M14 24L20.88 27.12L24 34L27.12 27.12L34 24L27.12 20.88L24 14L20.88 20.88L14 24Z"
          fill="black"
        />
        <path
          d="M24 41.9995C17.78 41.9995 12.3 38.8195 9.08 33.9995H14V29.9995H2V41.9995H6V36.5995C9.98 42.2795 16.54 45.9995 24 45.9995C33.74 45.9995 42 39.6595 44.88 30.8795L40.96 29.9795C38.5 36.9595 31.84 41.9995 24 41.9995Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2266_338">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoriesIcon;
