import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import FileUploadIcon from "../components/icons/FileUploadIcon";
import fetchWithUserToken from "../utils/fetchWithUserToken";
import API from "../interceptor/API";

const CreateSeriesModal = ({ open, setOpen, modalType }) => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const apiEndpoint =
    modalType === "Create Series" ? "series" : "interactive-story";

  const handleTypographyClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append(
      "language",
      new URLSearchParams(window.location.search).get("language") || "EN"
    );
    formData.append("cover", fileInputRef.current.files[0]);

    try {
      await fetchWithUserToken(
        `${process.env.REACT_APP_API_ENDPOINT}/${apiEndpoint}`,
        {
          method: "POST",
          body: formData,
        }
      ).then(() => {
        // window.location.href = `/series?language=${formData.get("language")}`;
        enqueueSnackbar("Created Successfully!", { variant: "success" });
      });
    } catch (error) {
      console.error("Error creating series:", error);
    } finally {
      setOpen(false);
    }
  };

  const fetchChannels = async () => {
    try {
      const { data, status } = await API.get("/admin/channels");
      if (data && status === 200) {
        setChannels(data);
        setSelectedChannel(data?.[0]?.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          width: 470,
          bgcolor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 3,
        }}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Typography
          sx={{ fontSize: "14px", fontWeight: 500 }}
          data-testid="modal-title"
        >
          {modalType}
        </Typography>

        <Box
          sx={{
            border: "1px dashed black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
            mt: 4,
          }}
        >
          <FileUploadIcon />
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: 600,
              color: "#605BFF",
              mt: 2,
              cursor: "pointer",
            }}
            onClick={handleTypographyClick}
          >
            Select file to upload
          </Typography>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {/* edit channel name //can create only one channel */}
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: 500,
              color: "#505762",
              mt: 1,
            }}
          >
            Any file up to 1GB : {fileName}
          </Typography>
        </Box>

        <TextField
          required
          fullWidth
          id="title"
          name="title"
          autoComplete="title"
          placeholder="Title"
          sx={{
            mt: 3,
            textAlign: "center",
            "& input": {
              padding: "8px",
              height: "30px",
            },
            "input::placeholder": {
              color: "var(--placeholderTextColor)",
            },
          }}
        />

        <Select
          value={selectedChannel}
          placeholder="Select Channel"
          onChange={(e) => setSelectedChannel(e.target.value)}
          sx={{ maxHeight: 50, mt: 3 }}
          fullWidth
        >
          {channels?.length > 0 &&
            channels?.map((channel) => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.name}
              </MenuItem>
            ))}
        </Select>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="primaryButton"
          sx={{ mt: 3, mb: 2 }}
          data-testid="create-series"
        >
          Create {modalType}
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateSeriesModal;
