import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

const SpinAllModal = ({
  closeSpinAllPopup,
  setCloseSpinAllPopup,
  setSpinAll,
}) => {
  return (
    <Modal
      open={closeSpinAllPopup}
      onClose={() => {
        setCloseSpinAllPopup(false);
        setSpinAll(false);
      }}
      style={{ overflow: "hidden" }}
      className="analyticsModal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          borderRadius: "22px",
        }}
      >
        <Typography variant="h6" textAlign="center">
          Are you sure to spin all wheels?
        </Typography>
        <Button
          onClick={() => {
            setSpinAll(true);
            setCloseSpinAllPopup(false);
          }}
          sx={{ mt: 3 }}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setCloseSpinAllPopup(false);
            setSpinAll(false);
          }}
          color="error"
        >
          No
        </Button>
      </Box>
    </Modal>
  );
};

export default SpinAllModal;
