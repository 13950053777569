import axios from "axios";

const API = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  };

  let axiosInstance = axios.create(defaultOptions);

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const token = localStorage.getItem("token");
      if (error?.response?.status === 401) {
        localStorage.removeItem(token);
        window.location.assign("/login");
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default API();
