import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import DownArrow from "../components/icons/DownArrow";
import UpArrow from "../components/icons/UpArrow";
import { USERS_EVENTS } from "../utils/constants";
import API from "../interceptor/API";

const UsersEvents = () => {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [expandedMainIndices, setExpandedMainIndices] = useState([]);
  const [expandedInnerIndices, setExpandedInnerIndices] = useState({});

  const handleToggleMainExpand = (index) => {
    setExpandedMainIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleToggleInnerExpand = (mainIndex, innerIndex) => {
    setExpandedInnerIndices((prev) => {
      const updated = { ...prev };
      if (!updated[mainIndex]) {
        updated[mainIndex] = [];
      }
      updated[mainIndex] = updated[mainIndex].includes(innerIndex)
        ? updated[mainIndex].filter((i) => i !== innerIndex)
        : [...updated[mainIndex], innerIndex];
      return updated;
    });
  };

  const fetchEvents = async () => {
    try {
      const { data, status } = await API.get(`/users/${id}/events`);
      if (data && status === 200) {
        setEvents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEvents();
    }
  }, [id]);

  const sessions = useMemo(() => {
    if (events.length === 0) return [];
    const sortedEvents = events.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    const sessions = [];
    let currentSession = [];
    let lastEvent = events[0];
    for (let i = 0; i < sortedEvents.length; i++) {
      const event = sortedEvents[i];
      const timeDifference =
        new Date(event.createdAt) - new Date(lastEvent.createdAt);
      if (timeDifference > 5 * 60 * 1000) {
        sessions.push(currentSession);
        currentSession = [];
      }
      currentSession.push(event);
      lastEvent = event;
    }
    sessions.push(currentSession);
    return sessions;
  }, [events]);

  const filteredSessions = sessions?.filter(
    (session) =>
      session?.filter(
        (event) =>
          [
            "On App In Background Event",
            "App Is Alive Event",
            "User Scrolled Home Screen Item List",
            "On App In Foreground Event",
            "Video Is Started Playing",
          ].indexOf(event.name) === -1
      ).length > 1
  );

  return (
    <Layout pageTitle={"Users > Events"}>
      <Box sx={{ mt: 4, pb: 4 }}>
        <h1 className="pageHeading">{id}</h1>

        <Tabs
          role="tabs"
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          style={{
            background: "transparent",
            width: "20%",
            marginTop: "20px",
          }}
        >
          <Tab label="Simple" data-testid="simple-tab" role="tab" />
          <Tab label="Advanced" role="tab" />
        </Tabs>

        {selectedTab === 0 ? (
          <>
            <Card
              sx={{
                borderRadius: "5px",
                border: "1px solid var(--borderColor)",
                mt: 3,
                overflow: "auto",
              }}
            >
              <Table
                sx={{
                  overflow: "auto",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableCell data-testid="events-head">Events</TableCell>
                  {filteredSessions?.map((_, index) => (
                    <TableCell key={index}>Session {index + 1}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {USERS_EVENTS.map((eventName, eventIndex) => (
                    <TableRow key={eventIndex}>
                      <TableCell>
                        <TableData>{eventName}</TableData>
                      </TableCell>
                      {filteredSessions.map((session, sessionIndex) => {
                        const event = session.find((e) => e.name === eventName);
                        return (
                          <TableCell key={sessionIndex}>
                            {event ? (
                              <Box sx={{ cursor: "pointer" }}>
                                <TableData>
                                  {new Date(event.createdAt)
                                    .toLocaleTimeString()
                                    .slice(0, -3)}
                                </TableData>
                              </Box>
                            ) : (
                              <span style={{ color: "blue" }}>
                                <TableData>null</TableData>
                              </span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </>
        ) : (
          <Box>
            {filteredSessions?.map((session, index) => (
              <Card
                key={index}
                sx={{
                  borderRadius: "5px",
                  border: "1px solid var(--borderColor)",
                  p: 2,
                  mt: 2,
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  {expandedMainIndices.includes(index) ? (
                    <UpArrow
                      style={{
                        color: "#6466f1",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleToggleMainExpand(index)}
                      aria-label="expand-less"
                      data-testid={`expand-less-${index}`}
                    />
                  ) : (
                    <DownArrow
                      style={{
                        color: "#6466f1",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleToggleMainExpand(index)}
                      aria-label="expand-more"
                      data-testid={`expand-more-${index}`}
                    />
                  )}
                  Session {index + 1} &nbsp; &nbsp;
                  {new Date(session[0]?.createdAt).toLocaleString()} -{" "}
                  {new Date(
                    session[session.length - 1]?.createdAt
                  ).toLocaleTimeString()}
                </span>

                <Collapse
                  in={expandedMainIndices.includes(index)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container mt={2}>
                    {session.map((event, eventIndex) => (
                      <Grid item xs={12} key={eventIndex}>
                        <CardContent
                          sx={{
                            cursor: "pointer",
                            padding: 0,
                          }}
                          onClick={() =>
                            handleToggleInnerExpand(index, eventIndex)
                          }
                        >
                          <Box
                            display="flex"
                            gap={3}
                            alignItems="center"
                            sx={{
                              backgroundColor:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "#F4F4F4",
                              borderTopRightRadius:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "5px",
                              borderTopLeftRadius:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "5px",
                              borderTop:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "1px solid var(--borderColor)",
                              borderRight:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "1px solid var(--borderColor)",
                              borderLeft:
                                expandedInnerIndices[index]?.includes(
                                  eventIndex
                                ) && "1px solid var(--borderColor)",
                            }}
                          >
                            {expandedInnerIndices[index]?.includes(
                              eventIndex
                            ) ? (
                              <ExpandLess style={{ color: "#959595" }} />
                            ) : (
                              <ExpandMore style={{ color: "#959595" }} />
                            )}

                            <h2 className="cardSummary">
                              {new Date(event.createdAt).toLocaleString()}
                            </h2>
                            <h2
                              style={{ color: "var(--green)" }}
                              className="cardSummary"
                            >
                              [{event?.deviceType}]
                            </h2>

                            <h2
                              style={{ color: "var(--orange)" }}
                              className="cardSummary"
                            >
                              {event.name}
                            </h2>
                          </Box>
                          <Collapse
                            in={expandedInnerIndices[index]?.includes(
                              eventIndex
                            )}
                            timeout="auto"
                            unmountOnExit
                            sx={{
                              background: "#F4F4F4",
                              mb: 2,
                              borderBottomLeftRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderBottom: "1px solid var(--borderColor)",
                              borderRight: "1px solid var(--borderColor)",
                              borderLeft: "1px solid var(--borderColor)",
                            }}
                          >
                            <Box px={6}>
                              <CardKeys>{event?.name}</CardKeys>
                              <CardKeys>
                                "User ID" :
                                <span style={{ color: "var(--orange)" }}>
                                  &nbsp;"{event?.userId}"
                                </span>
                              </CardKeys>
                              <CardKeys>
                                "Device" :
                                <span style={{ color: "var(--orange)" }}>
                                  &nbsp;"{event?.deviceType}"
                                </span>
                              </CardKeys>
                              <CardKeys>
                                "Build Number" :
                                <span style={{ color: "var(--orange)" }}>
                                  &nbsp;"{event?.buildNumber}"
                                </span>
                              </CardKeys>
                              <CardKeys>
                                "Version" :
                                <span style={{ color: "var(--orange)" }}>
                                  &nbsp;"{event?.versionName}"
                                </span>
                              </CardKeys>
                              <CardKeys>
                                "Date" :
                                <span style={{ color: "var(--orange)" }}>
                                  &nbsp;"{event?.createdAt}"
                                </span>
                              </CardKeys>
                            </Box>
                          </Collapse>
                        </CardContent>
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default UsersEvents;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

const CardKeys = styled.h2`
  font-family: Fira Code, monospace !important;
  font-size: 12px;
  font-weight: 500;
  color: black;
`;
