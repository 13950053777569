import React from "react";

const BadgeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.34183 2.67515C7.12309 2.45634 6.82639 2.33338 6.517 2.33331H2.33333V6.51698C2.3334 6.82637 2.45636 7.12307 2.67517 7.34181L6.7585 11.4251C6.97728 11.6439 7.27398 11.7667 7.58333 11.7667C7.89269 11.7667 8.18938 11.6439 8.40817 11.4251L11.4252 8.40815C11.6439 8.18936 11.7668 7.89267 11.7668 7.58331C11.7668 7.27396 11.6439 6.97726 11.4252 6.75848L7.34183 2.67515Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 5.25H5.25583V5.25583H5.25V5.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BadgeIcon;
