import React from "react";

const AddvariationIcon = ({ fill }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1.3125C3.864 1.3125 1.3125 3.864 1.3125 7C1.3125 10.136 3.864 12.6875 7 12.6875C10.136 12.6875 12.6875 10.136 12.6875 7C12.6875 3.864 10.136 1.3125 7 1.3125ZM7 2.1875C9.66306 2.1875 11.8125 4.33694 11.8125 7C11.8125 9.66306 9.66306 11.8125 7 11.8125C4.33694 11.8125 2.1875 9.66306 2.1875 7C2.1875 4.33694 4.33694 2.1875 7 2.1875ZM6.5625 4.375V6.5625H4.375V7.4375H6.5625V9.625H7.4375V7.4375H9.625V6.5625H7.4375V4.375H6.5625Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default AddvariationIcon;
