import React from "react";

const StatsIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_454_314)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 11.375V8.125H7.3125V11.375H11.375V4.0625H12.1875V11.375C12.1875 11.5905 12.1019 11.7971 11.9495 11.9495C11.7972 12.1019 11.5905 12.1875 11.375 12.1875H1.625C1.40951 12.1875 1.20285 12.1019 1.05048 11.9495C0.898102 11.7971 0.8125 11.5905 0.8125 11.375V9.75H1.625V11.375H6.5ZM10.4431 3.13137L8.00638 5.56887C8.13671 5.84019 8.16204 6.15013 8.07748 6.43901C7.99292 6.72788 7.80445 6.97525 7.54838 7.13345C7.29231 7.29165 6.98676 7.3495 6.69058 7.29585C6.3944 7.2422 6.12855 7.08084 5.94425 6.84287L3.73913 7.57737L3.25 7.74068C3.24487 8.02816 3.13827 8.30457 2.94904 8.52105C2.75982 8.73753 2.50014 8.88013 2.21593 8.92366C1.93172 8.96718 1.64127 8.90882 1.39593 8.75889C1.15059 8.60897 0.966159 8.37712 0.875236 8.10435C0.784313 7.83158 0.792755 7.53545 0.899071 7.26831C1.00539 7.00116 1.20273 6.7802 1.45621 6.64449C1.70969 6.50879 2.003 6.46707 2.28427 6.52671C2.56554 6.58636 2.81666 6.74353 2.99325 6.97043L5.6875 6.07181C5.69113 5.86781 5.74591 5.66799 5.84682 5.49067C5.94773 5.31334 6.09155 5.16419 6.26508 5.05688C6.43861 4.94958 6.6363 4.88756 6.84003 4.8765C7.04376 4.86545 7.247 4.90572 7.43112 4.99362L9.86862 2.55612C9.74471 2.29669 9.71649 2.00181 9.78897 1.72359C9.86145 1.44537 10.0299 1.20173 10.2647 1.03574C10.4994 0.869742 10.7853 0.792076 11.0718 0.816469C11.3582 0.840862 11.6268 0.965743 11.8301 1.16904C12.0334 1.37233 12.1583 1.64095 12.1827 1.92742C12.2071 2.21389 12.1294 2.49976 11.9634 2.7345C11.7974 2.96924 11.5538 3.13774 11.2756 3.21021C10.9974 3.28269 10.7025 3.25448 10.4431 3.13056"
          fill="#70777F"
        />
      </g>
      <defs>
        <clipPath id="clip0_454_314">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatsIcon;
