import React from "react";

const Logs = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 4.95841V12.0417C14.875 14.1667 13.8125 15.5834 11.3333 15.5834H5.66667C3.1875 15.5834 2.125 14.1667 2.125 12.0417V4.95841C2.125 2.83341 3.1875 1.41675 5.66667 1.41675H11.3333C13.8125 1.41675 14.875 2.83341 14.875 4.95841Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2708 3.1875V4.60417C10.2708 5.38333 10.9083 6.02083 11.6874 6.02083H13.1041"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08341 9.20825L5.66675 10.6249L7.08341 12.0416"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91675 9.20825L11.3334 10.6249L9.91675 12.0416"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logs;
