import React from "react";

const WheelConfigIcon = ({ fill }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L9 5H5V9L2 12L5 15V19H9L12 22L15 19H19V15L22 12L19 9V5H15L12 2Z"
        stroke={fill || "black"}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
        stroke={fill || "black"}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WheelConfigIcon;
