import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AnalyticsIcon from "./icons/AnalyticsIcon";
import styled from "styled-components";
import AddvariationIcon from "./icons/AddvariationIcon";
import Variation from "./Variation";
import { enqueueSnackbar } from "notistack";
import fetchWithUserToken from "../utils/fetchWithUserToken";
import FileUploadIcon from "./icons/FileUploadIcon";
import VideoPreview from "./VideoPreview";
import ConfirmationBox from "./ConfirmationPopup";
import API from "../interceptor/API";
import LikesIcon from "./icons/LikesIcon";
import ClicksIcon from "./icons/ClicksIcon";
import ViewsIcon from "./icons/ViewsIcon";
import ViewsFinishedIcon from "./icons/ViewsFinishedIcon";
import ProgressIcon from "./icons/ProgressIcon";

export function VariantComparision({ stats, series, variantID = null }) {
  const renderBlock = (variant) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ border: "1px solid var(--borderColor)", borderRadius: "5px" }}
        >
          <Box
            sx={{
              px: 2,
              py: 2,
              display: "flex",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              #{variant.id}
            </Typography>
            <Typography
              sx={{
                border: "1px solid var(--borderColor)",
                bgcolor: "var(--grayBackground)",
                fontSize: "10px",
                fontWeight: 400,
                borderRadius: "5px",
                px: 1,
              }}
            >
              <ClicksIcon /> {stats[variant?.id]?.clicks} clicks
            </Typography>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>
                  <RowData>
                    <LikesIcon />
                    Likes
                  </RowData>
                </TableCell>
                <TableCell>
                  <RowData>
                    <ClicksIcon />
                    Clicks
                  </RowData>
                </TableCell>
                <TableCell>
                  <RowData>
                    <ViewsIcon />
                    Views
                  </RowData>
                </TableCell>
                <TableCell>
                  <RowData>
                    <ViewsFinishedIcon />
                    Views Finished
                  </RowData>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variant?.VideoVariation?.map((video, index) => {
                if (stats.hasOwnProperty(`${video.videoId}-${variant.id}`)) {
                  const statsValue = stats[`${video.videoId}-${variant.id}`];

                  return (
                    <TableRow key={video.videoId}>
                      <TableCell>
                        <TableData>#{video.videoId}</TableData>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <TableData>{statsValue?.likes}</TableData>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <TableData>{statsValue?.clicks}</TableData>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <TableData>{statsValue?.views} </TableData>
                          {index > 0 ? (
                            <>
                              <ProgressIcon />
                              <TableData>
                                (
                                {Math.round(
                                  (
                                    statsValue?.views /
                                    stats[
                                      `${
                                        variant.VideoVariation[index - 1]
                                          ?.videoId
                                      }-${variant.id}`
                                    ]?.viewsFinished
                                  ).toFixed(2) * 100
                                )}
                                %)
                              </TableData>
                            </>
                          ) : null}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <TableData>{statsValue?.viewsFinished}</TableData>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={video.videoId}>
                      <TableCell colSpan={5}>
                        <CompareBlock>
                          <CompareBlockTitle>No Stats Found</CompareBlockTitle>
                        </CompareBlock>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  };

  if (variantID) {
    return series?.variations?.map((variant) => {
      if (variantID === variant?.id) {
        return renderBlock(variant);
      }
    });
  }

  return series?.variations?.map((variant) => {
    return renderBlock(variant);
  });
}

const SeriesVariationDetails = ({
  series,
  setSeries,
  updateVariation,
  setRefresh,
  preventDrag,
  setPreventDrag,
  videoStats,
  toggleCompareAnalyticsModal,
  isCompareAnalyticsModalOpen,
  isCompareAnalyticsVariantId,
  checkPermission,
  handleDataDelete,
  setDataToDelete,
  setDataTypeToDelete,
  deleteConfirmation,
  deleteConfirmationMessage,
  setDeleteConfirmation,
  setDeleteConfirmationMessage,
}) => {
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [noteModal, setNoteModal] = useState(null);
  const [trailerLoading, setTrailerLoading] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  // - Add Variantions
  const addVariations = async () => {
    try {
      const { data, status } = await API.post(
        `/series/${series.id}/variation`,
        { userId: 1 }
      );
      if (data && status === 200) {
        // wait for 1 second
        setTimeout(() => {
          setRefresh({});
          enqueueSnackbar("Variation created successfully", {
            variant: "success",
          });
        }, 1000);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // - Upload Video by Input Select
  const uploadVideo = async (evt) => {
    try {
      const { files } = evt?.target;
      if (!files) return;

      setUploading(true);
      const formData = new FormData();
      formData.append("seriesId", series.id);

      // Append each file to formData
      for (let i = 0; i < files?.length; i++) {
        formData.append("videos", files[i]);
      }

      const { data, status } = await API.post("/videos/upload", formData);

      if (data && status === 200) {
        // wait for 1 second and refresh
        setTimeout(() => {
          setRefresh({});
        }, 1000);
      }
    } catch (err) {
      console.error("uploadVideo", { err });
    } finally {
      setUploading(false);
    }
  };

  // - Upload Video by URL
  const uploadVideoByUrl = async () => {
    try {
      const url = document.getElementById("dropbox-url").value;
      if (!url) return;

      setUploading(true);
      const { data, status } = await API.post("/videos/upload-from-url", {
        url,
        seriesId: series.id,
      });

      if (data && status === 200) {
        // wait for 1 second and refresh
        setTimeout(() => {
          setRefresh({});
        }, 1000);
      }
    } catch (err) {
      console.error("uploadVideoByUrl", { err });
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteConfirmation = (type, data) => {
    setDeleteConfirmationMessage(
      `Are you sure you want to delete this ${type}?`
    );
    setDataToDelete(data);
    setDataTypeToDelete(type);
    setDeleteConfirmation(true);
  };

  const handleOpenModal = (noteId) => {
    const note = series?.videos?.find((video) => video.id === noteId) || {};
    setNoteModalOpen(note);
  };

  const handleCloseModal = () => {
    setNoteModalOpen(null);
  };

  const handleUpdateNote = (updatedVideo) => {
    const newSeries = { ...series };
    newSeries.videos = newSeries?.videos?.map((video) =>
      video?.id === updatedVideo.id ? updatedVideo : video
    );
    setSeries(newSeries);
    enqueueSnackbar("Note updated successfully", { variant: "success" });
  };

  return (
    <Box mt={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
          Variations ({series?.variations?.length})
        </Typography>
        <Box display="flex" gap={2}>
          {series?.variations?.length > 0 && (
            <Button
              variant="text"
              sx={{
                borderRadius: "5px",
                border: "1px solid #E3E7EB",
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                backgroundColor: "#fff",
              }}
              onClick={() => toggleCompareAnalyticsModal()}
            >
              <AnalyticsIcon fill="black" /> &nbsp; Compare Analytics
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              borderRadius: "5px",
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
            }}
            onClick={() => addVariations()}
          >
            <AddvariationIcon /> &nbsp;{" "}
            {series.variations?.length > 0
              ? "Add Variation"
              : "Create Variation to choose video order"}
          </Button>
        </Box>
      </Box>

      {series?.variations?.map((variation, index) => (
        <Variation
          updateVariation={updateVariation}
          setSeries={setSeries}
          series={series}
          variation={variation}
          index={index}
          setRefresh={setRefresh}
          preventDrag={preventDrag}
          setPreventDrag={setPreventDrag}
          videoStats={videoStats}
          toggleCompareAnalyticsModal={toggleCompareAnalyticsModal}
        />
      ))}

      {/* add a video */}
      <Box mt={4}>
        {(() => {
          const isUserAllowedVideoUpload = checkPermission("addVideos");

          if (!isUserAllowedVideoUpload) {
            return null;
          }

          if (uploading) {
            return (
              <div>
                Uploading <CircularProgress />
                {percentageUploaded !== 0 && percentageUploaded !== 100 && (
                  <Progress>{percentageUploaded}</Progress>
                )}
                <p>
                  It will take a few minutes to process the video and it to
                  appear here. Please be patient
                </p>
              </div>
            );
          }

          return (
            <>
              <Box
                bgcolor="white"
                borderRadius="5px"
                width="100%"
                pb={3}
                style={{
                  boxShadow: "0px 1px 2px 0px #A4ACB933",
                }}
              >
                <Typography
                  px={3}
                  py={2}
                  sx={{ fontSize: "14px", fontWeight: 600 }}
                >
                  Upload a video
                </Typography>
                <div style={{ borderBottom: "1px solid #E3E7EB" }} />
                <Box sx={{ px: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 6,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 4,
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <FormLabel sx={{ fontSize: "12px", fontWeight: 400 }}>
                        Insert URL Link
                      </FormLabel>
                      <TextField
                        type="text"
                        id="dropbox-url"
                        placeholder="Dropbox URL"
                        sx={{
                          "input::placeholder": {
                            textAlign: "center",
                          },
                          "& input": {
                            textAlign: "center",
                            paddingTop: "10px",
                            background: "#ffffff",
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: "10px", fontWeight: 400 }}>
                        Please insert the link to your video file here
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          mt: 1,
                        }}
                        onClick={() => uploadVideoByUrl()}
                      >
                        Upload from URL
                      </Button>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#505762",
                      }}
                    >
                      or
                    </Typography>

                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          border: "1px dashed black",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          py: 4,
                          mt: 1,
                          width: "100%",
                        }}
                      >
                        <FileUploadIcon />

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 600,
                            color: "#605BFF",
                            mt: 2,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          Select file to upload
                        </Typography>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          accept="video/*"
                          onChange={uploadVideo}
                        />

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 500,
                            color: "#505762",
                            mt: 1,
                            textAlign: "center",
                          }}
                        >
                          Any file up to 1GB
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          );
        })()}
      </Box>

      <Box mt={4}>
        <Typography variant="h6">
          Videos ({series?.videos?.length} found)
        </Typography>
        <br />

        <VideosHolder>
          {series?.videos
            ?.filter((video) => !video.uploading)
            ?.map((video) => (
              <VideoPreview
                video={video}
                playingVideoId={playingVideoId}
                setPlayingVideoId={setPlayingVideoId}
                setRefresh={setRefresh}
                videoStats={videoStats}
                setNoteModal={setNoteModal}
                setTrailerLoading={setTrailerLoading}
                trailerLoading={trailerLoading}
                handleDeleteConfirmation={handleDeleteConfirmation}
                handleOpenModal={handleOpenModal}
                noteModalOpen={noteModalOpen}
                setNoteModalOpen={setNoteModalOpen}
                handleUpdateNote={handleUpdateNote}
                noteModal={noteModal}
                series={series}
              />
            ))}

          {series?.videos
            ?.filter((video) => video.uploading)
            ?.map((video, index) => (
              <div
                style={{
                  display: "flex",
                  border: "1px solid lightgray",
                  padding: "10px",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "300px",
                  height: "400px",
                  justifyContent: "center",
                }}
              >
                Processing...
              </div>
            ))}
        </VideosHolder>
      </Box>

      {/* modal that opens when a note button is clicked */}

      <div
        style={{
          display: noteModal ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "600px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              paddingLeft: "25px",
              marginTop: "10px",
            }}
          >
            Note
          </span>
          <div style={{ borderBottom: "1px solid var(--borderColor)" }} />
          <Box px={3}>
            <textarea
              style={{
                height: "140px",
                width: "100%",
                fontSize: "16px",
              }}
              id="note"
              defaultValue={
                series?.videos?.find((video) => video?.id === noteModal)
                  ?.note || ""
              }
            />
            <Box
              py={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDireciton: "row",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setNoteModal(null);
                }}
                style={{
                  padding: "10px",
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  fetchWithUserToken(
                    `${process.env.REACT_APP_API_ENDPOINT}/videos/${noteModal}`,
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        note: document.getElementById("note").value,
                      }),
                    }
                  ).then(async (res) => {
                    const updatedVideo = await res.json();
                    const newSeries = { ...series };
                    newSeries.videos = newSeries?.videos?.map((video) => {
                      if (video.id === updatedVideo.id) {
                        return updatedVideo;
                      }
                      return video;
                    });
                    setSeries(newSeries);

                    enqueueSnackbar("Note updated successfully", {
                      variant: "success",
                    });
                    setNoteModal(null);
                  });

                  setNoteModal(null);
                }}
                style={{
                  padding: "10px",
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </div>
      </div>
      {/* end modal */}

      {/* Compare Analytics Modal  */}
      <Dialog
        className="dialog"
        onClose={toggleCompareAnalyticsModal}
        open={isCompareAnalyticsModalOpen}
      >
        {isCompareAnalyticsModalOpen ? (
          <>
            <p className="modalTitle" style={{ padding: "10px 26px" }}>
              Analytics for Variants & SubVariants
            </p>

            <Divider />

            <DialogContent>
              {videoStats ? (
                <Flex direction="column" gap={30} alignItems="flex-start">
                  <VariantComparision
                    stats={videoStats}
                    series={series}
                    variantID={isCompareAnalyticsVariantId}
                  />
                </Flex>
              ) : null}
            </DialogContent>
          </>
        ) : null}
      </Dialog>

      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={handleDataDelete}
        message={deleteConfirmationMessage}
      />
    </Box>
  );
};

export default SeriesVariationDetails;

const Buttons = styled.button`
  height: 40px;
  border-radius: 10px;
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap}px;
  `
      : null}
  ${(props) =>
    props.justifyContent
      ? ` justify-content: ${props.justifyContent}; `
      : ` justify-content: flex-start; `}
  ${(props) =>
    props.direction ? `flex-direction : ${props.direction};` : null}
  ${(props) =>
    props.alignItems
      ? `align-items: ${props.alignItems};`
      : `align-items: center;`}
`;
const Progress = styled.div`
  margin-top: 10px;
`;

const Section = styled.div`
  background: #f9f9f9;
  border-radius: 12px;
  border: 1px solid #eee;
  width: 100%;
  margin-bottom: 15px;
  padding: 12px 24px;
`;

const FileInput = styled.input`
  display: none;
`;

const VideosHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32.333333%);
  gap: 20px;
  padding-bottom: 40px;
  width: 100%;
`;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

const RowData = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const CompareBlock = styled.div`
  width: 140px;
  border: 1px solid #eee;
  text-align: left;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${(props) =>
    props.height
      ? `
    height: ${props.height}px;
  `
      : `
    height: 110px;
  `}
`;

const CompareBlockTitle = styled.div``;
