import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Box,
  Button,
  Card,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import CreateChannel from "../modals/CreateChannel";
import API from "../interceptor/API";
import { CHANNELS_TABLE_HEAD } from "../utils/constants";
import moment from "moment";
import styled from "styled-components";
import EditIcon from "../components/icons/EditIcon";
import Delete from "../components/icons/Delete";
import { enqueueSnackbar } from "notistack";
import ConfirmationBox from "../components/ConfirmationPopup";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import Loader from "../components/Loader";
import ChannelsIcon from "../components/icons/ChannelsIcon";
import CustomPagination from "../components/CustomPagination";

const Channels = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [channels, setChannels] = useState([]);
  const [adminChannels, setAdminChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [channelToDelete, setChannelToDelete] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [userId, setUserId] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      const id = localStorage.getItem("userId");
      setUserId(id);
    }
  }, []);

  const fetchChannels = async () => {
    try {
      const { data, status } = await API.get(`/channels?page=${activePage}`);
      if (data && status === 200) {
        setChannels(data?.channels);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdminChannels = async () => {
    try {
      const { data, status } = await API.get("/admin/channels");
      if (data && status === 200) {
        setAdminChannels(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChannels();
    fetchAdminChannels();
  }, [activePage]);

  const handleDeleteConfirmation = (channel) => {
    setChannelToDelete(channel);
    setDeleteConfirmation(true);
  };

  const deleteChannel = async () => {
    try {
      if (channelToDelete?.adminId !== userId) {
        enqueueSnackbar({
          message: "You can't delete channel created by other admin!",
          variant: "error",
        });
        setDeleteConfirmation(false);
      } else {
        const { data, status } = await API.delete(
          `/channels/${channelToDelete?.id}`
        );
        if (data && status === 200) {
          enqueueSnackbar({ message: data?.message, variant: "success" });
          setDeleteConfirmation(false);
        }
        fetchChannels();
        fetchAdminChannels();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEditModal = (channel) => {
    if (channel?.adminId !== userId) {
      enqueueSnackbar({
        message: "You can't edit channel created by other admin!",
        variant: "error",
      });
    } else {
      setOpen(true);
      setSelectedChannel(channel);
    }
  };

  const filteredUsersChannels = channels?.filter(
    (item) => item?.userId !== "" && item?.userId !== null
  );

  const filteredAdminChannels = channels?.filter(
    (item) => item?.adminId !== "" && item?.adminId !== null
  );

  const channelsToBeMapped =
    selectedTab == 0 ? filteredAdminChannels : filteredUsersChannels;
  const channelsHeadings =
    selectedTab == 0 ? CHANNELS_TABLE_HEAD : CHANNELS_TABLE_HEAD?.slice(0, 6);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout pageTitle={"Channels"} icon={<ChannelsIcon />}>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="pageHeading">Channels</h1>

        <Button
          className="primaryButton"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddvariationIcon /> &nbsp; Create Channel
        </Button>
      </Box>

      <Tabs
        role="tabs"
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        style={{
          background: "transparent",
          width: "20%",
          marginTop: "20px",
        }}
      >
        <Tab label="By Admins" role="tab" />
        <Tab label="By Users" data-testid="simple-tab" role="tab" />
      </Tabs>

      <Card
        sx={{
          borderRadius: "5px",
          mt: 3,
        }}
      >
        {channelsToBeMapped?.length > 0 ? (
          <Table>
            <TableHead sx={{ backgroundColor: "lightgray" }}>
              <TableRow>
                {channelsHeadings.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {channelsToBeMapped?.map((channel, index) => (
                <TableRow
                  key={index}
                  style={{
                    padding: "20px",
                  }}
                >
                  <TableCell>
                    <TableData>{channel?.id}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>{channel?.name}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>{channel?.slug}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>{channel?.adminId}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>{channel?.userId}</TableData>
                  </TableCell>

                  <TableCell>
                    <TableData>
                      {moment(channel?.createdAt).format("YYYY-MM-DD")}
                    </TableData>
                  </TableCell>
                  {selectedTab === 0 && (
                    <TableCell>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          component="span"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            openEditModal(channel);
                          }}
                        >
                          <EditIcon />
                        </Typography>

                        <Typography
                          component="span"
                          sx={{ cursor: "pointer" }}
                          data-testid={`delete-btn-${index}`}
                          onClick={() => handleDeleteConfirmation(channel)}
                        >
                          <Delete />
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            p={2}
            fontSize="12px"
            fontWeight={500}
            textAlign={"center"}
            width={"100%"}
            color={"#6466E9"}
          >
            No Data Found!
          </Box>
        )}
      </Card>

      <CustomPagination
        activePage={activePage}
        totalPages={totalPages}
        handleChange={handlePageChange}
      />

      <CreateChannel
        open={open}
        setOpen={setOpen}
        fetchChannels={fetchChannels}
        fetchAdminChannels={fetchAdminChannels}
        selectedChannel={selectedChannel}
      />

      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={deleteChannel}
        message="Are you sure you want to delete this channel?"
      />
    </Layout>
  );
};

export default Channels;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
