import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  Handle,
  Position,
  useReactFlow,
} from "reactflow";
import fetchWithUserToken from "../utils/fetchWithUserToken";
import { Box, TextField, Typography } from "@mui/material";

export function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const { setEdges } = useReactFlow();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const handleChange = (type, event) => {
    setEdges((edges) =>
      edges.map((edge) =>
        edge.id === id
          ? { ...edge, data: { ...edge.data, [type]: event.target.value } }
          : edge
      )
    );

    fetchWithUserToken(
      `${process.env.REACT_APP_API_ENDPOINT}/interactive-stories/edge/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          [type]: event.target.value,
        }),
      }
    );
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
            display: "flex",
            flexDirection: "column",
          }}
          className="nodrag nopan"
        >
          <TextField
            size="small"
            variant="standard"
            defaultValue={data?.label}
            sx={{
              width: 100,
              fontSize: 8,
              transform: "scale(0.6)",
            }}
            placeholder="Option name"
            onChange={(e) => handleChange("label", e)}
            onClick={(e) => e.stopPropagation()} // Prevent edge click when clicking the input
          />
          <TextField
            size="small"
            variant="standard"
            type="number"
            InputProps={{
              endAdornment: <Typography variant="caption">Coins</Typography>,
            }}
            defaultValue={data?.coins}
            sx={{
              mt: -1,
              width: 100,
              fontSize: 8,
              transform: "scale(0.6)",
            }}
            onChange={(e) => handleChange("coins", e)}
            onClick={(e) => e.stopPropagation()} // Prevent edge click when clicking the input
          />
          <TextField
            InputProps={{
              endAdornment: <Typography variant="caption">Diamonds</Typography>,
            }}
            size="small"
            variant="standard"
            type="number"
            defaultValue={data?.diamonds}
            sx={{
              mt: -1,
              width: 100,
              fontSize: 8,
              transform: "scale(0.6)",
            }}
            onChange={(e) => handleChange("diamonds", e)}
            onClick={(e) => e.stopPropagation()} // Prevent edge click when clicking the input
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function StartNode() {
  return (
    <>
      <Handle type="source" position={Position.Bottom} id="a" />
      <Box
        sx={{
          p: 2,
          border: "1px solid #ddd",
          borderRadius: 10,
          bgcolor: "white",
        }}
      >
        <Typography variant="body1">Start</Typography>
      </Box>
    </>
  );
}

export function StateNode({ data }) {
  return (
    <>
      <Handle type="target" position={Position.Top} id="b" />
      <Box
        sx={{
          p: 2,
          border: "1px solid #ddd",
          borderRadius: 10,
          bgcolor: data.state === "WIN" ? "green" : "red",
        }}
      >
        <Typography
          variant="body1"
          maxWidth={200}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.state === "WIN" ? "Win" : "Lose"}
          {data.label && `: ${data.label}`}
        </Typography>
      </Box>
    </>
  );
}
