import { Box, Modal, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const SimulationAnalytics = ({
  openAnalyticsModal,
  setOpenAnalyticsModal,
  spinAnalytics,
}) => {
  return (
    <Modal
      open={openAnalyticsModal}
      onClose={() => {
        setOpenAnalyticsModal(false);
      }}
      style={{ overflow: "hidden" }}
      className="analyticsModal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "auto",
          borderRadius: "10px",
          height: "600px",
          border: "1px solid var(--borderColor)",
          p: 4,
        }}
      >
        <Typography variant="h6" data-testid="modal-title">
          Spinning Wheel Analytics
        </Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            borderTop: "1px solid var(--borderColor)",
          }}
        >
          {Object.values(spinAnalytics).map((item, index) => (
            <ResponsiveContainer width="100%" height={300}>
              <div
                style={{
                  marginLeft: "40px",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                Tab-{index + 1}
              </div>
              <LineChart data={item} margin={{ top: 20, right: 30, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="noOfSpin" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="diamond"
                  stroke="#8884d8"
                  name="Diamonds"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="coins"
                  stroke="#fec658"
                  name="Coins"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default SimulationAnalytics;
