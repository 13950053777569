import React from "react";

const AlertIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.76973 6.85881C5.47894 3.83352 6.3339 2.32123 7.5069 1.93164C8.15166 1.71761 8.84831 1.71761 9.49307 1.93164C10.6661 2.32123 11.521 3.83352 13.2302 6.85881C14.9402 9.88339 15.7944 11.3964 15.5387 12.6289C15.397 13.3075 15.0499 13.9223 14.5456 14.3856C13.629 15.2292 11.9191 15.2292 8.49998 15.2292C5.08086 15.2292 3.37094 15.2292 2.45436 14.3863C1.94807 13.9187 1.60081 13.3045 1.46128 12.6296C1.20486 11.3971 2.05982 9.8841 3.76973 6.85881Z"
        stroke={fill || "#4A4E5A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.67147 12.0417V9.20833C8.67147 8.87471 8.67147 8.70754 8.56805 8.60342C8.46393 8.5 8.29747 8.5 7.96313 8.5M8.49438 6.375H8.50076"
        stroke={fill || "#4A4E5A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
