import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import ReactHlsPlayer from "react-hls-player";

const InteractiveStoryVideoModal = ({
  selectedVideoUrl,
  setSelectedVideoUrl,
}) => {
  return (
    <Dialog
      open={!!selectedVideoUrl}
      onClose={() => setSelectedVideoUrl(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Video</DialogTitle>
      <DialogContent>
        {selectedVideoUrl && (
          <ReactHlsPlayer
            src={selectedVideoUrl}
            autoPlay={false}
            controls={true}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSelectedVideoUrl(null)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InteractiveStoryVideoModal;
