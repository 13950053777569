import React from "react";

const Delete = ({ onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <svg
        width="19"
        height="19"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.99999 12.6667C3.99999 13.0203 4.14047 13.3594 4.39052 13.6095C4.64057 13.8595 4.97971 14 5.33333 14H10.6667C11.0203 14 11.3594 13.8595 11.6095 13.6095C11.8595 13.3594 12 13.0203 12 12.6667V4.66667H3.99999V12.6667ZM5.33333 6H10.6667V12.6667H5.33333V6ZM10.3333 2.66667L9.66666 2H6.33333L5.66666 2.66667H3.33333V4H12.6667V2.66667H10.3333Z"
          fill="#FF0000"
        />
      </svg>
    </div>
  );
};

export default Delete;
