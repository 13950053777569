import React from "react";

const EditIcon = ({ height, width }) => {
  return (
    <div style={{ cursor: "pointer" }}>
      <svg
        width={width || 19}
        height={height || 19}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.875 4.74998L14.25 7.12498M10.2916 15.8333H16.625M3.95829 12.6667L3.16663 15.8333L6.33329 15.0417L15.5055 5.8694C15.8024 5.57248 15.9691 5.16983 15.9691 4.74998C15.9691 4.33014 15.8024 3.92749 15.5055 3.63057L15.3694 3.4944C15.0725 3.19757 14.6698 3.03082 14.25 3.03082C13.8301 3.03082 13.4275 3.19757 13.1305 3.4944L3.95829 12.6667Z"
          stroke="#6466F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
