import React, { useState } from "react";
import API from "../interceptor/API";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const useNotifications = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [conditions, setConditions] = useState([]);
  const [actions, setActions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [trigger, setTrigger] = useState("MANUAL_TRIGGER");
  const [campaignName, setCampaignName] = useState("");
  const [refresh, setRefresh] = useState({});
  const [currentVariant, setCurrentVariant] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [triggerOptions, setTriggerOptions] = useState({});

  const fetchNotificationDetails = async () => {
    try {
      const { data, status } = await API.get(`/notifications/${id}`);
      if (data && status === 200) {
        setConditions(
          data.conditions.map((c) => ({ id: c.type, value: c.data }))
        );
        setActions(data.actions.map((a) => ({ id: a.type, value: a.data })));
        setVariants(
          data.variants.map((v) =>
            v.actions.map((a) => ({ id: a.type, value: a.data }))
          )
        );
        setTrigger(data.trigger);
        setTriggerOptions(data.triggerOptions);
        setCampaignName(data.title);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        title: campaignName,
        conditions,
        actions,
        trigger,
        variants,
        triggerOptions,
      };

      const url = `/notifications${id ? `/${id}` : ""}`;
      const method = id ? "put" : "post";

      let response;
      if (method === "put") {
        response = await API.put(url, payload);
      } else {
        response = await API.post(url, payload);
      }

      const { data, status } = response;
      if (data && status) {
        enqueueSnackbar("Notification Campaign Created Successfully!", {
          variant: "success",
        });
        navigate("/notifications");
        setRefresh({});
        setConditions([]);
        setActions([]);
        setCurrentVariant(0);
        setVariants([]);
        setTrigger("MANUAL_TRIGGER");
        setCampaignName("");
        setActiveStep(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    conditions,
    actions,
    variants,
    trigger,
    campaignName,
    fetchNotificationDetails,
    handleSave,
    handleNext,
    handleBack,
    activeStep,
    setCampaignName,
    setTrigger,
    setConditions,
    setActions,
    currentVariant,
    setCurrentVariant,
    setVariants,
    id,
    triggerOptions,
    setTriggerOptions,
  };
};

export default useNotifications;
