import React from "react";

const NotificationsIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 4.56165V6.9204"
        stroke={fill || "#4A4E5A"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.51422 1.41669C5.90755 1.41669 3.79672 3.52752 3.79672 6.13419V7.62169C3.79672 8.10335 3.59838 8.82585 3.35047 9.23669L2.45088 10.7384C1.89838 11.6663 2.28088 12.7004 3.30088 13.0404C6.68672 14.1667 10.3488 14.1667 13.7346 13.0404C14.6909 12.7217 15.1017 11.6025 14.5846 10.7384L13.685 9.23669C13.4371 8.82585 13.2388 8.09627 13.2388 7.62169V6.13419C13.2317 3.54169 11.1067 1.41669 8.51422 1.41669Z"
        stroke={fill || "#4A4E5A"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.8589 13.3308C10.8589 14.6271 9.79636 15.6896 8.50011 15.6896C7.85552 15.6896 7.26052 15.4204 6.83552 14.9954C6.41052 14.5704 6.14136 13.9754 6.14136 13.3308"
        stroke={fill || "#4A4E5A"}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default NotificationsIcon;
