import React from "react";

const AnalyticsIcon = ({ fill }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16532 2.36768C2.72809 2.36768 2.37366 2.72234 2.37366 3.15934V13.451C2.37366 15.1998 3.79145 16.6177 5.54032 16.6177H15.832C16.269 16.6177 16.6237 16.263 16.6237 15.826C16.6237 15.389 16.269 15.0343 15.832 15.0343H5.54032C4.66585 15.0343 3.95699 14.3258 3.95699 13.451V3.15934C3.95699 2.72234 3.60256 2.36768 3.16532 2.36768ZM11.082 3.95101V5.53434H13.9019L11.7003 7.73597C11.629 7.80801 11.4066 7.90934 11.3044 7.90934H10.0679C9.54616 7.90934 8.90253 8.15872 8.53361 8.52764L5.76294 11.2985C5.45379 11.608 5.45379 12.1273 5.76294 12.4369C5.91755 12.5913 6.1294 12.6593 6.33199 12.6593C6.53458 12.6593 6.74643 12.5913 6.90104 12.4369L9.67203 9.66605C9.74328 9.594 9.96574 9.49268 10.0679 9.49268H11.3044C11.8262 9.49268 12.4698 9.2433 12.8387 8.87438L15.0403 6.67275V9.49268H16.6237V4.74268C16.6237 4.30568 16.269 3.95101 15.832 3.95101H11.082Z"
        fill={fill || "#6466F1"}
      />
    </svg>
  );
};

export default AnalyticsIcon;
