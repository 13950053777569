import React from "react";

const AdminIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49997 16.1146C8.32997 16.1146 8.15998 16.0934 7.99706 16.0438C4.32081 15.0309 1.65747 11.5954 1.65747 7.8696V4.76001C1.65747 3.96668 2.23123 3.10963 2.96789 2.80505L6.91331 1.19002C7.9404 0.772108 9.06663 0.772108 10.0866 1.19002L14.0321 2.80505C14.7687 3.10963 15.3425 3.96668 15.3425 4.76001V7.8696C15.3425 11.5884 12.6721 15.0238 9.00288 16.0438C8.83997 16.0934 8.66997 16.1146 8.49997 16.1146ZM8.49997 1.94795C8.09622 1.94795 7.69955 2.02587 7.31705 2.18171L3.37165 3.79669C3.03165 3.93835 2.71997 4.39877 2.71997 4.7671V7.87669C2.71997 11.1279 5.05747 14.1313 8.28039 15.0238C8.42206 15.0663 8.57789 15.0663 8.71955 15.0238C11.9425 14.1313 14.28 11.1279 14.28 7.87669V4.7671C14.28 4.39877 13.9683 3.93835 13.6283 3.79669L9.6829 2.18171C9.3004 2.02587 8.90372 1.94795 8.49997 1.94795Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M8.49992 9.3854C7.42325 9.3854 6.552 8.51415 6.552 7.43748C6.552 6.36081 7.42325 5.48956 8.49992 5.48956C9.57659 5.48956 10.4478 6.36081 10.4478 7.43748C10.4478 8.51415 9.57659 9.3854 8.49992 9.3854ZM8.49992 6.55206C8.01117 6.55206 7.6145 6.94873 7.6145 7.43748C7.6145 7.92623 8.01117 8.3229 8.49992 8.3229C8.98867 8.3229 9.38534 7.92623 9.38534 7.43748C9.38534 6.94873 8.98867 6.55206 8.49992 6.55206Z"
        fill={fill || "#4A4E5A"}
      />
      <path
        d="M8.5 11.5104C8.20958 11.5104 7.96875 11.2696 7.96875 10.9792V8.85419C7.96875 8.56377 8.20958 8.32294 8.5 8.32294C8.79042 8.32294 9.03125 8.56377 9.03125 8.85419V10.9792C9.03125 11.2696 8.79042 11.5104 8.5 11.5104Z"
        fill={fill || "#4A4E5A"}
      />
    </svg>
  );
};

export default AdminIcon;
