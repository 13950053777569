import { Box, Typography } from "@mui/material";
import React from "react";
import Typo from "../Typography/Typo";
import { color } from "@mui/system";

const Header = ({ pageTitle, icon }) => {
  return (
    <Box
      sx={{
        px: 4,
        py: 1,
        borderBottom: "1px solid #EDEDED",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {icon}

      <Typo as="p" fontType="13_Regular" style={{ color: "#4A4E5A" }}>
        {pageTitle}
      </Typo>
    </Box>
  );
};

export default Header;
