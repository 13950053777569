import {
  Button,
  Card,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { IconAmpersand } from "@tabler/icons-react";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import Delete from "../components/icons/Delete";
import EditIcon from "../components/icons/EditIcon";
import NotificationsIcon from "../components/icons/NotificationsIcon";
import PlayIcon from "../components/icons/PlayIcon";
import StatsIcon from "../components/icons/StatsIcon";
import API from "../interceptor/API";
import NotificationStats from "../modals/NotificationStats";
import {
  NOTIFICATIONS_CONDITION_LIST,
  NOTIFICATION_ACTION_LIST,
} from "../utils/constants";
import CustomPagination from "../components/CustomPagination";

const Notifications = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [refresh, setRefresh] = useState({});
  const [loadingNotification, setLoadingNotification] = useState(null);
  const [statsOpen, setStatsOpen] = useState(null);
  const [stats, setStats] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchNotifications = async () => {
    try {
      const { data, status } = await API.get(
        `/notifications?page=${activePage}`
      );
      if (data && status === 200) {
        setCampaigns(data?.campaigns);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      const { data, status } = await API.get(
        `notifications/${statsOpen}/stats`
      );
      if (data && status === 200) {
        setStats(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [refresh, activePage]);

  useEffect(() => {
    if (statsOpen) {
      fetchStats();
    }
  }, [statsOpen]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <NotificationStats
        statsOpen={!!statsOpen}
        setStatsOpen={setStatsOpen}
        setStats={setStats}
        stats={stats}
      />

      <Layout pageTitle="Notification Campaigns" icon={<NotificationsIcon />}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={4}
        >
          <p className="pageHeading">Notification Campaigns</p>
          <Button
            variant="contained"
            className="primaryButton"
            onClick={() => navigate("/notifications/campaign")}
          >
            <AddvariationIcon /> &nbsp; New notifications campaign
          </Button>
        </Box>
        <Card
          sx={{
            overflow: "auto",
            border: "1px solid var(--borderColor)",
            borderRadius: "5px",
            mt: 3,
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "lightgray" }}>
              <TableCell>Title</TableCell>
              <TableCell>Conditions</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Trigger</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Stats</TableCell>
            </TableHead>

            {campaigns?.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>
                  <TableData>{campaign.title}</TableData>
                </TableCell>
                <TableCell>
                  <TableData>
                    {campaign.conditions.map((condition, i) => (
                      <>
                        {i > 0 && (
                          <IconAmpersand
                            size={18}
                            style={{
                              marginBottom: -2,
                              marginLeft: 2,
                              marginRight: 2,
                            }}
                          />
                        )}
                        {
                          NOTIFICATIONS_CONDITION_LIST.find(
                            (c) => c.id === condition.type
                          )?.name
                        }{" "}
                        {condition.type === "HAS_ANY_EVENT" ||
                        condition.type === "DOESNT_HAVE_ANY_EVENT"
                          ? JSON.stringify(condition.data)
                          : condition.data}{" "}
                        {NOTIFICATIONS_CONDITION_LIST.find(
                          (c) => c.id === condition.type
                        )?.unit !== "message"
                          ? NOTIFICATIONS_CONDITION_LIST.find(
                              (c) => c.id === condition.type
                            )?.unit
                          : ""}
                      </>
                    ))}
                  </TableData>
                </TableCell>
                <TableCell>
                  <TableData>
                    {campaign.actions.map((action, i) => (
                      <>
                        {i > 0 && (
                          <IconAmpersand
                            size={18}
                            style={{
                              marginBottom: -2,
                              marginLeft: 2,
                              marginRight: 2,
                            }}
                          />
                        )}
                        {
                          NOTIFICATION_ACTION_LIST.find(
                            (a) => a.id === action.type
                          )?.name
                        }{" "}
                        {action.type === "PUSH"
                          ? JSON.stringify(action.data)
                          : action.data}{" "}
                        {NOTIFICATION_ACTION_LIST.find(
                          (a) => a.id === action.type
                        )?.unit !== "message"
                          ? NOTIFICATION_ACTION_LIST.find(
                              (a) => a.id === action.type
                            )?.unit
                          : ""}
                      </>
                    ))}
                  </TableData>
                </TableCell>

                <TableCell>
                  <TableData>
                    {campaign.trigger === "MANUAL_TRIGGER"
                      ? "Manual trigger"
                      : campaign.trigger === "SCHEDULED"
                      ? "Scheduled"
                      : campaign.trigger === "EVENT_TRIGGER"
                      ? "Event trigger"
                      : ""}
                  </TableData>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/notifications/campaign?id=${campaign.id}`)
                      }
                    >
                      <EditIcon />
                    </Typography>

                    {campaign.trigger === "MANUAL_TRIGGER" && (
                      <Tooltip title="Send notification to all users that match the conditions">
                        {loadingNotification === campaign.id ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={async () => {
                              setLoadingNotification(campaign.id);
                              const { data } = await API.post(
                                `/notifications/${campaign.id}/send`
                              );
                              if (data) {
                                setLoadingNotification(null);
                                setRefresh({});
                              }
                            }}
                          >
                            <PlayIcon />
                          </Typography>
                        )}
                      </Tooltip>
                    )}

                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const { status } = await API.delete(
                          `/notifications/${campaign.id}`
                        );
                        if (status === 204) {
                          setRefresh({});
                          enqueueSnackbar({
                            variant: "success",
                            message:
                              "Notification Campaign deleted successfully!",
                          });
                        }
                      }}
                    >
                      <Delete />
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    onClick={() => setStatsOpen(campaign.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <StatsIcon />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Card>
        <CustomPagination
          activePage={activePage}
          totalPages={totalPages}
          handleChange={handlePageChange}
        />
      </Layout>
    </>
  );
};

export default Notifications;

export const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

/**scheduler different file */
