import { Box, Table } from "@mui/material";
import React from "react";
import WheelTableHead from "./WheelTableHead";
import WheelTableBody from "./WheelTableBody";

const WheelConfigTable = ({
  rowData,
  handleEdit,
  handleDeleteConfirmation,
  handleDeleteWheelConfig,
}) => {
  return (
    <Box sx={{ overflow: "auto" }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <WheelTableHead />
        <WheelTableBody
          rowData={rowData}
          handleEdit={handleEdit}
          handleDeleteConfirmation={handleDeleteConfirmation}
          handleDeleteWheelConfig={handleDeleteWheelConfig}
        />
      </Table>
    </Box>
  );
};

export default WheelConfigTable;
