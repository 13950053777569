import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ConfirmationBox from "../components/ConfirmationPopup";
import DownArrow from "../components/icons/DownArrow";
import UpArrow from "../components/icons/UpArrow";
import API from "../interceptor/API";
import AddvariationIcon from "./icons/AddvariationIcon";
import AnalyticsIcon from "./icons/AnalyticsIcon";
import Delete from "./icons/Delete";
import HideIcon from "./icons/HideIcon";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Variation = ({
  series,
  setSeries,
  variation,
  setRefresh,
  preventDrag,
  setPreventDrag,
  videoStats,
  toggleCompareAnalyticsModal,
}) => {
  const [showTable, setShowTable] = useState(true);
  const [subVariationModalOpen, setSubVariationModalOpen] =
    React.useState(false);
  const [subVariations, setSubVariations] = React.useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataTypeToDelete, setDataTypeToDelete] = useState(null);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    useState(false);

  const handleShowTable = () => {
    setShowTable(!showTable);
  };

  const fetchSubVariations = async () => {
    try {
      const { data, status } = await API.get(
        `/variation/${variation.id}/variation`
      );
      if (data && status === 200) {
        setSubVariations(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // get all subvariations for the selected variation
    fetchSubVariations();
  }, [subVariationModalOpen]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    // setPreventDrag(true);

    const items = reorder(
      variation.VideoVariation,
      result.source.index,
      result.destination.index
    );

    const newVariation = {
      ...variation,
      VideoVariation: items,
    };

    setSeries({
      ...series,
      variations: series.variations.map((v) =>
        v.id === variation.id ? newVariation : v
      ),
    });

    const { data } = await API.put(`/variation/${variation.id}/reorder`, {
      videoIds: items.map((item) => item.video.id),
    });
    if (data) {
      setRefresh({});
      enqueueSnackbar("Video reordered successfully", { variant: "success" });
      // wait for 5 seconds to re-enable drag
      setTimeout(() => {
        setPreventDrag(false);
      }, 5000);
    }
  };

  const deleteVariation = async (value) => {
    const { data, status } = await API.delete(
      `/variation/${value.id}/variation`
    );
    if (data && status) {
      setRefresh({});
      enqueueSnackbar("Variation deleted successfully", {
        variant: "success",
      });
    }
  };

  const deleteSubVariation = async (value) => {
    const { data, status } = await API.delete(
      `/variation/${value.id}/variation`
    );
    if (data && status) {
      setRefresh({});
      enqueueSnackbar("Sub-Variation deleted successfully", {
        variant: "success",
      });
    }
  };

  const deleteVariationVideo = async (value) => {
    const { data, status } = await API.delete(
      `/variation/${value?.id}/variation/video`,
      {
        videoId: data.video.id,
      }
    );
    if (data && status) {
      setRefresh({});
      enqueueSnackbar("Video removed from variation", {
        variant: "success",
      });
    }
  };

  const handleDeleteConfirmation = (type, data) => {
    setDeleteConfirmationMessage(
      `Are you sure you want to delete this ${type}?`
    );
    setDataToDelete(data);
    setDataTypeToDelete(type);
    setDeleteConfirmation(true);
  };

  const handleDataDelete = async () => {
    if (dataTypeToDelete === "variation") {
      await deleteVariation(dataToDelete);
    }
    if (dataTypeToDelete === "Sub Variation") {
      await deleteSubVariation(dataToDelete);
    }
    if (dataTypeToDelete === "variation video") {
      await deleteVariationVideo(dataToDelete);
    }
  };

  return (
    <div>
      <style>
        {`
        table, th, td {
          border: 1px solid #eeeeee;
          border-collapse: collapse;
          background-color: white;
        }`}
      </style>

      <Box
        sx={{ backgroundColor: "white", border: "1px solid #E3E7EB", mt: 2 }}
      >
        {/* header */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: 1.5,
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/visible.png"
              style={{
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = `/users?variation=${variation.id}`;
              }}
            />

            <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
              Sub - Variations ({variation?.subVariation?.length})
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "5px",
                border: "1px solid #E3E7EB",
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                height: "30px",
              }}
              onClick={async () => {
                const { data, status } = await API.post(
                  `/variation/${variation.id}/variation`
                );
                if (data && status) {
                  setRefresh({});
                  enqueueSnackbar("Sub-Variation created successfully", {
                    variant: "success",
                  });
                }
              }}
            >
              <AddvariationIcon fill="black" /> &nbsp; Add sub-variation
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              p: 1.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                fontSize: "12px",
                fontWeight: 400,
                cursor: "pointer",
              }}
              onClick={async () => {
                const { data, status } = await API.put(
                  `variation/${variation.id}`,
                  {
                    active: !variation.active,
                  }
                );
                if (data && status === 200) {
                  setRefresh({});
                  enqueueSnackbar("Variation updated successfully", {
                    variant: "success",
                  });
                }
              }}
            >
              <HideIcon />
              {variation.active ? "Hide" : "Show"}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                fontSize: "12px",
                fontWeight: 400,
                cursor: "pointer",
              }}
              onClick={() => toggleCompareAnalyticsModal(variation.id)}
            >
              <AnalyticsIcon fill="black" /> Analytics
            </Typography>
            <Select
              sx={{
                padding: "4px",
                width: "auto",
                height: "30px",
              }}
              defaultValue={" "}
              onChange={async (e) => {
                if (!e.target.value.toString().trim()) return;
                const { data, status } = await API.put(
                  `/variation/${variation.id}/variation`,
                  {
                    videoId: Number(e.target.value),
                  }
                );
                if (data && status === 200) {
                  setRefresh({});
                  enqueueSnackbar("Video added to variation", {
                    variant: "success",
                  });
                }
              }}
            >
              <MenuItem value={" "}>Add Video</MenuItem>
              {series?.videos
                .filter(
                  (video) =>
                    !variation.VideoVariation.map(
                      (videoVariation) => videoVariation.video.id
                    ).includes(video.id)
                )
                .map((video) => (
                  <MenuItem value={video.id}>
                    {video.note} - {video.title}
                  </MenuItem>
                ))}
            </Select>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleDeleteConfirmation("variation", variation);
              }}
            >
              <Delete />
            </Box>
            {showTable ? (
              <UpArrow
                style={{
                  color: "#6466f1",
                  fontSize: "30px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={handleShowTable}
              />
            ) : (
              <DownArrow
                style={{
                  color: "#6466f1",
                  fontSize: "30px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={handleShowTable}
              />
            )}
          </Box>
        </Box>
        {showTable && (
          <div style={{ overflow: "auto" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <table
                    className="variationsTable"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <Box sx={{ px: 6 }}>Title</Box>
                        </th>

                        <th>
                          <Box sx={{ px: 6 }}>Note</Box>
                        </th>
                        <th>
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                px: 2,
                                py: 1,
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                    px: 2,
                                    py: 1,
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                        backgroundColor: "white",
                                        border: "1px solid #E3E7EB",
                                        borderRadius: "30px",
                                        px: 1,
                                      }}
                                    >
                                      <IconButton
                                        onClick={async () => {
                                          const { data, status } =
                                            await API.post(
                                              `/variation/${variation.id}/copy`
                                            );
                                          if (data && status === 200) {
                                            setRefresh({});
                                            enqueueSnackbar(
                                              "Variation copied successfully",
                                              {
                                                variant: "success",
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTswA6ldRnnvyuD2RUd6eSCJvwRQSyC3GrodQFncLOQLg&s"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                          }}
                                        />
                                      </IconButton>
                                      <span style={{ fontSize: "12px" }}>
                                        {variation?.name}
                                      </span>
                                    </Box>

                                    <Typography
                                      mt={1}
                                      display="flex"
                                      alignItems="center"
                                      color="#6466F1"
                                      justifyContent="center"
                                      sx={{ cursor: "pointer" }}
                                      onClick={async () => {
                                        const { data, status } = await API.put(
                                          `/variation/${variation.id}`,
                                          {
                                            active: !variation.active,
                                          }
                                        );
                                        if (data && status === 200) {
                                          setRefresh({});
                                          enqueueSnackbar(
                                            "Variation updated successfully",
                                            {
                                              variant: "success",
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <HideIcon fill="#6466F1" />
                                      {variation.active ? "Hide" : "Show"}
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <Typography>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {videoStats?.[variation.id]?.clicks}
                                      </span>{" "}
                                      clicks
                                    </Typography>
                                    <Typography
                                      mt={1}
                                      color="#FF0000"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleDeleteConfirmation(
                                          "Variation",
                                          variation
                                        );
                                      }}
                                    >
                                      <Delete />
                                      Delete
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <div
                              style={{ borderBottom: "1px solid #E3E7EB" }}
                            />

                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRight: "1px solid var(--borderColor)",
                                }}
                              >
                                {" "}
                                Coins{" "}
                              </Typography>

                              <Typography
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRight: "1px solid var(--borderColor)",
                                }}
                              >
                                {" "}
                                Ads{" "}
                              </Typography>

                              <Typography
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRight: "1px solid var(--borderColor)",
                                }}
                              >
                                {" "}
                                Unlock (Min){" "}
                              </Typography>
                            </Box>
                          </Box>
                        </th>
                        {variation?.subVariation?.map((subVariation, index) => (
                          <th>
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                  px: 2,
                                  py: 1,
                                }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "nowrap",
                                      backgroundColor: "white",
                                      border: "1px solid #E3E7EB",
                                      borderRadius: "30px",
                                      px: 1,
                                    }}
                                  >
                                    <IconButton
                                      onClick={async () => {
                                        const { data, status } = await API.post(
                                          `/variation/${subVariation.id}/copy`
                                        );
                                        if (data && status) {
                                          setRefresh({});
                                          enqueueSnackbar(
                                            "Variation copied successfully",
                                            {
                                              variant: "success",
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTswA6ldRnnvyuD2RUd6eSCJvwRQSyC3GrodQFncLOQLg&s"
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                      />
                                    </IconButton>
                                    <span style={{ fontSize: "12px" }}>
                                      {subVariation?.name}
                                    </span>
                                  </Box>

                                  <Typography
                                    mt={1}
                                    display="flex"
                                    alignItems="center"
                                    color="#6466F1"
                                    justifyContent="center"
                                    sx={{ cursor: "pointer" }}
                                    onClick={async () => {
                                      const { data, status } = await API.put(
                                        `/variation/${subVariation.id}`,
                                        {
                                          active: !subVariation.active,
                                        }
                                      );
                                      if (data && status === 200) {
                                        setRefresh({});
                                        enqueueSnackbar(
                                          "Variation updated successfully",
                                          {
                                            variant: "success",
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <HideIcon fill="#6466F1" />
                                    {subVariation.active ? "Hide" : "Show"}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {videoStats?.[variation.id]?.clicks}
                                    </span>{" "}
                                    clicks
                                  </Typography>
                                  <Typography
                                    mt={1}
                                    color="#FF0000"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleDeleteConfirmation(
                                        "Sub Variation",
                                        subVariation
                                      );
                                    }}
                                  >
                                    <Delete />
                                    Delete
                                  </Typography>
                                </Box>
                              </Box>

                              <div
                                style={{
                                  borderBottom: "1px solid #E3E7EB",
                                }}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  px: 2,
                                  py: 1,
                                }}
                              >
                                <Typography> Coins </Typography>

                                <Typography> Ads </Typography>

                                <Typography> Unlock (Min) </Typography>
                              </Box>
                            </Box>
                          </th>
                        ))}
                        <th>
                          <Box sx={{ px: 6 }}>Action</Box>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {variation?.VideoVariation.map(
                        (videoVariation, index) => (
                          <Draggable
                            isDragDisabled={preventDrag}
                            key={videoVariation?.id}
                            draggableId={videoVariation?.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <td>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    alignContent="center"
                                    gap="5px"
                                    px={2}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {videoVariation.video.id}{" "}
                                    </span>
                                    <Typography
                                      variant="caption"
                                      ml={1}
                                      sx={{
                                        border: "1px solid var(--borderColor)",
                                        borderRadius: "20px",
                                        paddingX: "10px",
                                        backgroundColor: "#F4F6F8",
                                      }}
                                    >
                                      {Math.floor(
                                        videoVariation.video.duration / 60
                                      )}
                                      :
                                      {videoVariation.video.duration % 60 < 10
                                        ? "0"
                                        : ""}
                                      {videoVariation.video.duration % 60}
                                    </Typography>
                                  </Box>
                                </td>
                                <td>
                                  <Box px={2}>
                                    <Typography variant="caption">
                                      {videoVariation.video.note}
                                    </Typography>
                                  </Box>
                                </td>
                                <td
                                  style={{
                                    opacity: variation.active ? 1 : 0.3,
                                    padding: "10px",
                                  }}
                                >
                                  <Box>
                                    {videoStats ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-evenly",
                                          alignItems: "center",
                                          gap: "5px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            margin: "auto",
                                            borderRight:
                                              "1px solid var(--borderColor)",
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              width: "80%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {
                                              videoStats?.[
                                                `${videoVariation.video.id}-${variation.id}`
                                              ]?.views
                                            }
                                          </Typography>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            margin: "auto",
                                            borderRight:
                                              "1px solid var(--borderColor)",
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              width: "80%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {
                                              videoStats?.[
                                                `${videoVariation.video.id}-${variation.id}`
                                              ]?.viewsFinished
                                            }
                                          </Typography>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            margin: "auto",
                                            borderRight:
                                              "1px solid var(--borderColor)",
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              width: "80%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {Math.round(
                                              (
                                                videoStats?.[
                                                  `${videoVariation.video.id}-${variation.id}`
                                                ]?.viewsFinished /
                                                videoStats?.[
                                                  `${videoVariation.video.id}-${variation.id}`
                                                ]?.views
                                              ).toFixed(2) * 100
                                            )}
                                            %
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Box px={2} py={1}>
                                        <LinearProgress px={2} />
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <TextField
                                      type="number"
                                      fullWidth
                                      defaultValue={
                                        videoVariation.cost === null
                                          ? videoVariation.video.coins
                                          : videoVariation.cost
                                      }
                                      className="tdBorder"
                                      sx={{
                                        textAlign: "center",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRight:
                                          "1px solid var(--borderColor)",
                                        "& input": {
                                          padding: "8px",
                                          width: "40px",
                                          textAlign: "center",
                                        },
                                      }}
                                      // update videoVariation with new cost
                                      onChange={async (e) => {
                                        const { data, status } = await API.put(
                                          `/videoVariation/${videoVariation.id}`,
                                          {
                                            cost: e.target.value,
                                          }
                                        );
                                        if (data && status === 200) {
                                          enqueueSnackbar(
                                            "Variation updated successfully",
                                            {
                                              variant: "success",
                                            }
                                          );
                                        }
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      type="number"
                                      defaultValue={
                                        videoVariation.adsCount === null
                                          ? videoVariation.video.adsCount
                                          : videoVariation.adsCount
                                      }
                                      className="tdBorder"
                                      sx={{
                                        textAlign: "center",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRight:
                                          "1px solid var(--borderColor)",
                                        "& input": {
                                          padding: "8px",
                                          width: "40px",
                                          textAlign: "center",
                                        },
                                      }}
                                      // update videoVariation with new adsCount
                                      onChange={async (e) => {
                                        setSeries({
                                          ...series,
                                          variations: series.variations.map(
                                            (v) =>
                                              v.id === variation.id
                                                ? {
                                                    ...variation,
                                                    VideoVariation:
                                                      variation.VideoVariation.map(
                                                        (v) =>
                                                          v.id ===
                                                          videoVariation.id
                                                            ? {
                                                                ...videoVariation,
                                                                adsCount:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : v
                                                      ),
                                                  }
                                                : v
                                          ),
                                        });
                                        await API.put(
                                          `/videoVariation/${videoVariation.id}`,
                                          {
                                            adsCount: e.target.value,
                                          }
                                        );
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      type="number"
                                      defaultValue={
                                        videoVariation.unlockAfterMin === null
                                          ? videoVariation.video.unlockAfterMin
                                          : videoVariation.unlockAfterMin
                                      }
                                      className="tdBorder"
                                      sx={{
                                        textAlign: "center",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRight:
                                          "1px solid var(--borderColor)",
                                        "& input": {
                                          padding: "8px",
                                          width: "40px",
                                          textAlign: "center",
                                        },
                                      }}
                                      // update videoVariation with new unlockAfterMin
                                      onChange={async (e) => {
                                        setSeries({
                                          ...series,
                                          variations: series.variations.map(
                                            (v) =>
                                              v.id === variation.id
                                                ? {
                                                    ...variation,
                                                    VideoVariation:
                                                      variation.VideoVariation.map(
                                                        (v) =>
                                                          v.id ===
                                                          videoVariation.id
                                                            ? {
                                                                ...videoVariation,
                                                                unlockAfterMin:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : v
                                                      ),
                                                  }
                                                : v
                                          ),
                                        });
                                        await API.put(
                                          `/videoVariation/${videoVariation.id}`,
                                          {
                                            unlockAfterMin: e.target.value,
                                          }
                                        );
                                      }}
                                    />
                                  </Box>
                                </td>
                                {variation?.subVariation?.map(
                                  (subVariation) => (
                                    <td>
                                      {(() => {
                                        const vv =
                                          subVariation.VideoVariation.find(
                                            (v) => {
                                              return (
                                                v.video.id ===
                                                videoVariation.video.id
                                              );
                                            }
                                          );

                                        return (
                                          <Box
                                            sx={{
                                              opacity: subVariation.active
                                                ? 1
                                                : 0.3,
                                            }}
                                          >
                                            <Box>
                                              {videoStats ? (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-evenly",
                                                    alignItems: "center",
                                                    gap: 1,
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography variant="caption">
                                                      {
                                                        videoStats?.[
                                                          `${videoVariation.video.id}-${subVariation.id}`
                                                        ]?.views
                                                      }
                                                    </Typography>
                                                  </Box>
                                                  <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography variant="caption">
                                                      {
                                                        videoStats?.[
                                                          `${videoVariation.video.id}-${subVariation.id}`
                                                        ]?.viewsFinished
                                                      }
                                                    </Typography>
                                                  </Box>

                                                  <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography variant="caption">
                                                      {Math.floor(
                                                        (
                                                          videoStats?.[
                                                            `${videoVariation.video.id}-${subVariation.id}`
                                                          ]?.viewsFinished /
                                                          videoStats?.[
                                                            `${videoVariation.video.id}-${subVariation.id}`
                                                          ]?.views
                                                        ).toFixed(2) * 100
                                                      )}
                                                      %
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              ) : (
                                                <Box px={2} py={1}>
                                                  <LinearProgress px={2} />
                                                </Box>
                                              )}
                                            </Box>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "5px",
                                              }}
                                            >
                                              <TextField
                                                fullWidth
                                                type="number"
                                                defaultValue={vv?.cost}
                                                className="tdBorder"
                                                sx={{
                                                  textAlign: "center",
                                                  width: "100%",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  borderRight:
                                                    "1px solid var(--borderColor)",
                                                  "& input": {
                                                    padding: "8px",
                                                    width: "40px",
                                                    textAlign: "center",
                                                  },
                                                }}
                                                onChange={async (e) => {
                                                  await API.put(
                                                    `/videoVariation/${vv.id}`,
                                                    {
                                                      variationId:
                                                        subVariation.id,
                                                      cost: e.target.value,
                                                    }
                                                  );
                                                }}
                                              />
                                              <TextField
                                                fullWidth
                                                type="number"
                                                defaultValue={vv?.adsCount}
                                                className="tdBorder"
                                                sx={{
                                                  textAlign: "center",
                                                  width: "100%",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  borderRight:
                                                    "1px solid var(--borderColor)",
                                                  "& input": {
                                                    padding: "8px",
                                                    width: "40px",
                                                    textAlign: "center",
                                                  },
                                                }}
                                                onChange={async (e) => {
                                                  await API.put(
                                                    `/videoVariation/${vv.id}`,
                                                    {
                                                      variationId:
                                                        subVariation.id,
                                                      adsCount: e.target.value,
                                                    }
                                                  );
                                                }}
                                              />
                                              <TextField
                                                fullWidth
                                                type="number"
                                                defaultValue={
                                                  vv?.unlockAfterMin
                                                }
                                                className="tdBorder"
                                                sx={{
                                                  textAlign: "center",
                                                  width: "100%",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  borderRight:
                                                    "1px solid var(--borderColor)",
                                                  "& input": {
                                                    padding: "8px",
                                                    width: "40px",
                                                    textAlign: "center",
                                                  },
                                                }}
                                                onChange={async (e) => {
                                                  await API.put(
                                                    `/videoVariation/${vv.id}`,
                                                    {
                                                      variationId:
                                                        subVariation.id,
                                                      unlockAfterMin:
                                                        e.target.value,
                                                    }
                                                  );
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        );
                                      })()}
                                    </td>
                                  )
                                )}
                                <td>
                                  <Typography
                                    style={{
                                      width: "100%",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      handleDeleteConfirmation(
                                        "variation video",
                                        {
                                          video: {
                                            id: videoVariation.video.id,
                                          },
                                          id: variation.id,
                                        }
                                      );
                                    }}
                                  >
                                    <Delete />
                                  </Typography>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </Box>

      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={handleDataDelete}
        message={deleteConfirmationMessage}
      />
    </div>
  );
};

export default Variation;
