import React from "react";

const HideIcon = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 10.2917C7.01242 10.2917 7.48042 10.2359 7.90725 10.1395L6.95554 9.18774C6.80767 9.19912 6.65763 9.20833 6.5 9.20833C3.60154 9.20833 2.47867 7.12508 2.20675 6.49999C2.41093 6.04435 2.68669 5.6243 3.02358 5.25578L2.26633 4.49854C1.43325 5.40149 1.11746 6.31095 1.1115 6.32883C1.07414 6.44006 1.07414 6.56046 1.1115 6.6717C1.12288 6.70745 2.36546 10.2917 6.5 10.2917ZM6.5 2.70833C5.50496 2.70833 4.68758 2.92283 4.00617 3.2397L2.00796 1.24203L1.24204 2.00795L10.992 11.758L11.758 10.992L9.96017 9.19424C11.3761 8.13745 11.8815 6.69445 11.889 6.6717C11.9264 6.56046 11.9264 6.44006 11.889 6.32883C11.8771 6.29253 10.6345 2.70833 6.5 2.70833ZM9.19317 8.42724L7.95817 7.19224C8.06108 6.98099 8.125 6.74862 8.125 6.49999C8.125 5.61112 7.38888 4.87499 6.5 4.87499C6.25138 4.87499 6.019 4.93891 5.80829 5.04237L4.82896 4.06304C5.36657 3.87855 5.93163 3.78678 6.5 3.79166C9.39846 3.79166 10.5213 5.87491 10.7933 6.49999C10.6297 6.87483 10.1617 7.76858 9.19317 8.42724Z"
        fill={fill || "black"}
      />
    </svg>
  );
};

export default HideIcon;
