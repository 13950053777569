import React from "react";
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
import Header from "./Header";

const Layout = ({ children, pageTitle, icon }) => {
  return (
    <div
      className="dashboardLayout bg-black overflow-auto flex"
      style={{ height: "100%" }}
    >
      <Sidebar />
      <div
        className="content w-full overflow-x-hidden"
        style={{ background: "#F4F4F4" }}
      >
        <Box sx={{}}>
          <Header pageTitle={pageTitle} icon={icon} />
          <Box
            sx={{
              my: pageTitle !== "Interactive Story Details" ? 2 : 0,
              pb: 0,
              borderRadius: 2,
              px: pageTitle !== "Interactive Story Details" ? 12 : 0,
            }}
          >
            {children}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Layout;
