export default function fetchWithUserToken(url, options) {
  const token = localStorage.getItem("token");
  if (!token) {
    return "No token found";
  }

  return fetch(url, {
    ...(options || {}),
    headers: {
      ...((options && options.headers) || {}),
      Authorization: `Bearer ${token}`,
    },
  });
}
