import { enqueueSnackbar } from "notistack";
import React from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import ReactHlsPlayer from "react-hls-player";
import Delete from "./icons/Delete";
import API from "../interceptor/API";

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* height: 356px !important; */
  gap: 20px;
`;

const VideoPreview = ({
  video,
  playingVideoId,
  setPlayingVideoId,
  setRefresh,
  videoStats,
  setNoteModal,
  setTrailerLoading,
  trailerLoading,
  handleDeleteConfirmation,
}) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #E3E7EB",
        boxShadow: "0px 1px 2px 0px #A4ACB933",
      }}
    >
      <Box>
        <Box
          style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "340px",
            display: "flex",
            gap: "3px",
          }}
        >
          <Typography sx={{ fontSize: "11px", fontWeight: 500 }}>
            ({video.id}) {video.title}
          </Typography>
          <Typography
            sx={{
              bgcolor: "#F4F6F8",
              border: "1px solid #E3E7EB",
              borderRadius: "15px",
              px: 1,
              fontSize: "9px",
              fontWeight: 400,
            }}
          >
            {Math.floor(video.duration / 60)}:
            {video.duration % 60 < 10 ? "0" : ""}
            {video.duration % 60}{" "}
          </Typography>
        </Box>
        {!localStorage.getItem("permissions")?.includes("editVideos")
          ? null
          : video?.note && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  padding: 0,
                  marginBottom: "10px",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    cursor: "pointer",
                    height: "30px",
                  }}
                  onClick={() => setNoteModal(video.id)}
                >
                  {video?.note?.slice(0, 70)}
                </p>
              </div>
            )}
        <Box mb={2} width="100%" display="flex" mt={1}>
          {!localStorage
            .getItem("permissions")
            ?.includes("editVideos") ? null : (
            <label
              htmlFor="rotation"
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={async () => {
                const newRotation =
                  video.rotation !== "LANDSCAPE" ? "LANDSCAPE" : "PORTRAIT";
                const { data, status } = await API.put(`/videos/${video.id}`, {
                  rotation: newRotation,
                });
                if (data && status === 200) {
                  setRefresh({});
                }
              }}
            >
              <span
                style={{
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                Landscape
              </span>
              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "40px",
                  height: "20px",
                  borderRadius: "20px",
                  backgroundColor:
                    video.rotation !== "LANDSCAPE" ? "#000" : "#ccc",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "2px",
                    left: video.rotation !== "LANDSCAPE" ? "22px" : "2px",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    transition: "left 0.2s",
                  }}
                />
              </span>
              <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                Portrait
              </span>
            </label>
          )}
        </Box>
        <div style={{ borderBottom: "1px solid #E3E7EB" }} />

        <div
          style={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              <img
                src="https://img.icons8.com/?size=256&id=21448&format=png"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />{" "}
              Coins
            </Box>
            <TextField
              type="number"
              defaultValue={video.coins}
              sx={{
                "input::placeholder": {
                  textAlign: "center",
                },
                "& input": {
                  textAlign: "center",
                  paddingTop: "10px",
                  background: "#ffffff",
                },
              }}
              style={{
                width: "80px",
                maxHeight: "40px",
                borderRadius: "5px",
              }}
              onChange={async (e) => {
                const { data, status } = await API.put(`/videos/${video.id}`, {
                  coins: e.target.value,
                });
                if (data && status === 200) {
                  enqueueSnackbar("Coins updated successfully", {
                    variant: "success",
                  });
                }
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              <img
                src="https://img.icons8.com/?size=256&id=92740&format=png"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />{" "}
              Ads
            </Box>
            <TextField
              type="number"
              defaultValue={video.adsCount}
              sx={{
                "input::placeholder": {
                  textAlign: "center",
                },
                "& input": {
                  textAlign: "center",
                  paddingTop: "10px",
                  background: "#ffffff",
                },
              }}
              style={{
                width: "80px",
                maxHeight: "40px",
                borderRadius: "5px",
              }}
              onChange={async (e) => {
                const { data, status } = await API.put(`/videos/${video.id}`, {
                  adsCount: e.target.value,
                });
                if (data && status === 200) {
                  enqueueSnackbar("Ads updated successfully", {
                    variant: "success",
                  });
                }
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              <img
                src="https://img.icons8.com/ios/50/000000/clock.png"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />{" "}
              Unlock After
            </Box>
            <TextField
              type="number"
              defaultValue={video.unlockAfterMin}
              sx={{
                "input::placeholder": {
                  textAlign: "center",
                },
                "& input": {
                  textAlign: "center",
                  paddingTop: "10px",
                  background: "#ffffff",
                },
              }}
              style={{
                width: "80px",
                maxHeight: "40px",
                borderRadius: "5px",
              }}
              onChange={async (e) => {
                const { data, status } = await API.put(`/videos/${video.id}`, {
                  unlockAfterMin: e.target.value,
                });
                if (data && status === 200) {
                  enqueueSnackbar("Timer updated successfully", {
                    variant: "success",
                  });
                }
              }}
            />
          </Box>
        </div>

        <input
          type="file"
          id={`video${video.id}`}
          name="filename"
          placeholder="Upload video"
          style={{
            display: "none",
          }}
          onChange={async (e) => {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("trailer", file);
            // add videoId to form data
            formData.append("videoId", video.id);
            setTrailerLoading(video.id);
            enqueueSnackbar("Uploading trailer... Don't close the page", {
              variant: "info",
            });
            const { data, status } = await API.post(
              `/videos/${video.id}/trailer`,
              formData
            );
            if (data && status === 200) {
              setRefresh({});
            }
          }}
        />
        {!localStorage
          .getItem("permissions")
          ?.includes("addTrailer") ? null : video?.Trailer ? (
          <Box display="flex" marginLeft="0" flexDirection="column" gap={1}>
            <Typography
              variant="caption"
              sx={{
                maxWidth: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {video?.Trailer?.title}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleDeleteConfirmation("videoTrailer", video)}
              sx={{
                padding: 1,
                maxHeight: "30px",
              }}
            >
              Remove trailer
            </Button>
          </Box>
        ) : video?.id === trailerLoading ? (
          <CircularProgress
            sx={{
              mr: 1,
            }}
          />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              document.getElementById(`video${video.id}`).click();
            }}
            sx={{
              padding: 1,
              maxHeight: "40px",
              width: "100%",
              mt: 2,
              borderRadius: "5px",
            }}
          >
            Add trailer
          </Button>
        )}

        <Button
          sx={{ borderRadius: "5px", mt: 1, width: "100%" }}
          variant="contained"
          onClick={async () => {
            const { data, status } = await API.put(
              `/videos/${video.id}/refresh`
            );
            if (data && status === 200) {
              setRefresh({});
              enqueueSnackbar("Video refreshed successfully", {
                variant: "success",
              });
            }
          }}
        >
          <img
            src="https://img.icons8.com/ios/50/000000/refresh.png"
            style={{
              width: "20px",
              height: "20px",
              filter: "invert(1)",
            }}
          />{" "}
          &nbsp; Refresh
        </Button>

        {!localStorage
          .getItem("permissions")
          ?.includes("deleteVideos") ? null : (
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation("video", video)}
          >
            <Delete /> &nbsp;
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, color: "#FF0000" }}
            >
              Remove Video
            </Typography>
          </Button>
        )}
      </Box>

      <VideoContainer key={video.id}>
        <div
          style={{
            width: "100%",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              height: "100%",
              backgroundColor: "black",
              borderRadius: "7px",
              overflow: "hidden",
              maxWidth: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            {playingVideoId === video.id ? (
              <ReactHlsPlayer
                src={video.url}
                autoPlay={false}
                controls={true}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                {/* playing icon triangle */}
                <svg
                  onClick={() => setPlayingVideoId(video.id)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) rotate(90deg)",
                    cursor: "pointer",
                  }}
                  width="20"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    // triangle
                    d="M25 0L50 50H0L25 0Z"
                    fill="white"
                  />
                </svg>

                <img src={video.thumbnail} width={"100%"} height={"100%"} />
              </>
            )}
          </div>
        </div>
      </VideoContainer>
      {videoStats && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/visible.png"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
            <Typography variant="caption">
              {videoStats?.[video.id]?.views}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/bookmark-ribbon--v1.png"
              ƒ
              style={{
                width: "20px",
                height: "15px",
                cursor: "pointer",
              }}
            />
            <Typography variant="caption">
              {videoStats?.[video.id]?.saves}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/like.png"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
            <Typography variant="caption">
              {videoStats?.[video.id]?.likes}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/lock.png"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
            <Typography variant="caption">
              {videoStats?.[video.id]?.unlocks}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default VideoPreview;
