import React, { useEffect, useMemo, useState } from "react";
import API from "../interceptor/API";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const useEditSeries = () => {
  const COST_PER_ADD = 10;

  const { id } = useParams();
  const [series, setSeries] = useState([]);
  const [videoStats, setVideoStats] = useState(null);
  const [coverExperiment, setCoverExperiment] = useState(null);
  const [refresh, setRefresh] = useState({});
  const [badgeColor, setBadgeColor] = useState("#fffff");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataTypeToDelete, setDataTypeToDelete] = useState(null);
  const [trailerLoading, setTrailerLoading] = useState(false);
  const [seriesTrailerLoading, setSeriesTrailerLoading] = useState(false);
  const [editingBadge, setEditingBadge] = useState(false);
  const [isCompareAnalyticsModalOpen, setIsCompareAnalyticsModalOpen] =
    useState(false);
  const [isCompareAnalyticsVariantId, setIsCompareAnalyticsVariantId] =
    useState(null);
  const [adsEvents, setAdsEvents] = useState([]);

  const fetchSeriesDetails = async () => {
    try {
      const { data, status } = await API.get(`/series/${id}?backend=true`);
      if (data && status === 200) {
        setSeries(data);
      }
    } catch (error) {
      console.error("Error fetching series data:", error);
      setSeries(null);
    }
  };

  const findVideoIds = useMemo(() => {
    return series?.videos?.map((v) => v.id) || [];
  }, [series]);

  const findVariants = useMemo(() => {
    return series?.variations?.map((s) => s.id) || [];
  }, [series]);

  const fetchEventsByVideoIds = async () => {
    try {
      const payload = {
        videoIds: findVideoIds,
        variantIds: findVariants,
      };

      const { data, status } = await API.post("/events/videoIds", payload);
      if (data && status === 200) {
        setAdsEvents(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (findVideoIds?.length > 0 && findVariants?.length > 0) {
      fetchEventsByVideoIds();
    }
  }, [findVideoIds, findVariants]);

  const videoMap = new Map();
  series?.videos?.forEach((video) => {
    if (!videoMap.has(video.id)) {
      videoMap.set(video.id, []);
    }
    videoMap.get(video.id).push(video);
  });

  console.log(videoMap, "videoMap:::::");

  const groupedData = adsEvents?.reduce((acc, event) => {
    const partId = event.partIdInt;
    if (!acc[partId]) {
      acc[partId] = {
        eventDetails: [],
        videoDetails: videoMap.get(partId) || [],
      };
    }
    acc[partId].eventDetails.push(event);
    return acc;
  }, {});

  const calculateAdRevenue = (groupedData) => {
    const revenueData = {};
    Object.keys(groupedData).forEach((partId) => {
      const { eventDetails, videoDetails } = groupedData[partId];
      if (videoDetails?.length > 0) {
        const video = videoDetails[0];
        const numberOfEvents = eventDetails?.length;
        const numberOfAds = video?.adsCount;
        const revenue = numberOfEvents * numberOfAds * COST_PER_ADD;

        revenueData[partId] = {
          videoId: video?.id,
          videoTitle: video?.title,
          totalEvents: numberOfEvents,
          adsCount: numberOfAds,
          revenue,
        };
      }
    });
    return revenueData;
  };

  const revenueReport = calculateAdRevenue(groupedData);

  console.log(revenueReport, "revenueReport::::::");
  console.log(groupedData, "groupedData:::::::");

  const fetchStats = async () => {
    try {
      const { data, status } = await API.get(`/videos/stats/${id}`);
      if (data && status === 200) {
        setVideoStats(data);
      }
    } catch (error) {
      console.error("Error fetching video stats:", error);
      setVideoStats(null);
    }
  };

  const fetchExperiment = async () => {
    if (series) {
      const { data, status } = await API.get(
        `/experiment?name=cover-experiment-${id}`
      );
      if (data && status === 200) {
        setCoverExperiment(data);
      }
    }
  };

  const updateVariation = (newVariation) => {
    const newSeries = { ...series };
    newSeries.variations = newSeries?.variations?.map((variation) => {
      if (variation.id === newVariation.id) {
        return newVariation;
      }
      return variation;
    });
    setSeries(newSeries);
  };

  const updateSeriesTitle = async () => {
    try {
      const value = document.getElementById("title")?.value;
      if (!value) return;

      const response = await API.put(`/series/${id}`, { title: value });

      if (response) {
        setRefresh({});
        enqueueSnackbar("Series title updated successfully", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateRotation = async (series) => {
    try {
      const newRotation =
        series.rotation !== "LANDSCAPE" ? "LANDSCAPE" : "PORTRAIT";

      const response = await API.put(`series/${series.id}`, {
        rotation: newRotation,
      });
      if (response) {
        setRefresh({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateCoverImage = async (evt) => {
    try {
      const file = evt?.target?.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("cover", file);

      const response = await API.put(`/series/${series.id}/cover`, formData);

      if (response) {
        setRefresh({});
        enqueueSnackbar("Cover updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateHorizontalCoverImage = async (evt) => {
    try {
      const file = evt?.target?.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("cover", file);

      const response = await API.put(
        `/series/${series.id}/horizontal-cover`,
        formData
      );

      if (response) {
        setRefresh({});
        enqueueSnackbar("Horizontal cover updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateBadgeInfo = async () => {
    try {
      const value = document.getElementById("badge")?.value;
      if (!value) return;
      const payload = {
        badgeText: value,
        badgeColor: badgeColor,
      };
      const response = await API.put(`/series/${series.id}`, payload);
      if (response) {
        setRefresh({});
        enqueueSnackbar("Badge updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setEditingBadge(false);
    }
  };

  const removeBadgeInfo = async () => {
    try {
      const payload = {
        badgeText: null,
        badgeColor: null,
      };
      const response = await API.put(`/series/${series?.id}`, payload);
      if (response) {
        setRefresh({});
        enqueueSnackbar("Badge removed successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setEditingBadge(false);
    }
  };

  const changeBadgeColor = (color) => {
    setBadgeColor(color.hex);
  };

  const handleDeleteConfirmation = (type, data) => {
    setDeleteConfirmationMessage(
      `Are you sure you want to delete this ${type}?`
    );
    setDataToDelete(data);
    setDataTypeToDelete(type);
    setDeleteConfirmation(true);
  };

  const handleDataDelete = async () => {
    if (dataTypeToDelete === "videoTrailer") {
      await deleteVideoTrailer(dataToDelete);
    }
    if (dataTypeToDelete === "video") {
      await deleteVideo(dataToDelete);
    }
    if (dataTypeToDelete === "seriesTrailer") {
      await deleteSeriesTrailer(dataToDelete);
    }
  };

  const deleteVideoTrailer = async (value) => {
    const { data, status } = await API.delete(`/videos/${value.id}/trailer`);
    if (data && status) {
      setRefresh({});
      enqueueSnackbar("Trailer removed successfully", {
        variant: "error",
      });
      setTrailerLoading(false);
    }
  };

  const deleteVideo = async (value) => {
    const { data, status } = await API.delete(`/videos/${value.id}`);
    if (data && status === 200) {
      setRefresh({});
      enqueueSnackbar("Video deleted successfully", {
        variant: "success",
      });
    }
  };

  const deleteSeriesTrailer = async (value) => {
    const { data, status } = await API.delete(`/series/${value.id}/trailer`);
    if (data && status) {
      setRefresh({});
      enqueueSnackbar("Series Trailer removed successfully", {
        variant: "error",
      });
      setSeriesTrailerLoading(false);
    }
  };

  const toggleCompareAnalyticsModal = (variantId) => {
    if (variantId && !isCompareAnalyticsModalOpen) {
      setIsCompareAnalyticsVariantId(variantId);
    } else {
      setIsCompareAnalyticsVariantId(null);
    }

    setIsCompareAnalyticsModalOpen(!isCompareAnalyticsModalOpen);
  };

  return {
    fetchSeriesDetails,
    series,
    fetchStats,
    videoStats,
    fetchExperiment,
    coverExperiment,
    updateVariation,
    updateSeriesTitle,
    updateRotation,
    refresh,
    updateCoverImage,
    updateHorizontalCoverImage,
    updateBadgeInfo,
    removeBadgeInfo,
    changeBadgeColor,
    badgeColor,
    handleDeleteConfirmation,
    setDataTypeToDelete,
    setDataToDelete,
    deleteConfirmation,
    setDeleteConfirmation,
    deleteVideoTrailer,
    deleteVideo,
    deleteSeriesTrailer,
    toggleCompareAnalyticsModal,
    isCompareAnalyticsModalOpen,
    isCompareAnalyticsVariantId,
    trailerLoading,
    seriesTrailerLoading,
    deleteConfirmationMessage,
    editingBadge,
    id,
    setSeriesTrailerLoading,
    setRefresh,
    setEditingBadge,
    setSeries,
    setIsCompareAnalyticsModalOpen,
    handleDataDelete,
    setDeleteConfirmationMessage,
  };
};

export default useEditSeries;
