import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const CreateSpinConfigurationModal = ({
  createSpinModalOpen,
  setCreateSpinModalOpen,
  isEdited,
  configFields,
  setConfigFields,
  typeValues,
  updateSpinningDetails,
  createConfig,
  handleTypeChange,
  isConfigCreating,
}) => {
  return (
    <Modal
      open={createSpinModalOpen}
      onClose={() => {
        setCreateSpinModalOpen(false);
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          py: 2,
        }}
      >
        <p className="modalTitle">
          {isEdited ? "Edit" : "Create"} Spinning Wheel Configuration
        </p>
        <TextField
          label="Label"
          fullWidth
          value={configFields.label}
          onChange={(e) =>
            setConfigFields({ ...configFields, label: e.target.value })
          }
        />
        <TextField
          label="Value"
          fullWidth
          type="number"
          value={configFields.value}
          onChange={(e) =>
            setConfigFields({ ...configFields, value: e.target.value })
          }
        />
        <TextField
          label="Probability"
          fullWidth
          type="number"
          value={configFields.percentageDrop}
          onChange={(e) =>
            setConfigFields({
              ...configFields,
              percentageDrop: e.target.value,
            })
          }
        />
        <FormControlLabel
          label="Is Divisible"
          control={<Checkbox />}
          onChange={() => {
            setConfigFields({
              ...configFields,
              isDivisible: !configFields.isDivisible,
            });
          }}
          checked={configFields.isDivisible}
        />

        <Select
          value={configFields.type}
          onChange={handleTypeChange}
          fullWidth
          label="Type"
          sx={{ height: "45px" }}
        >
          {typeValues.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          color="primary"
          className="primaryButton"
          onClick={isEdited ? updateSpinningDetails : createConfig}
        >
          {isConfigCreating ? (
            <CircularProgress style={{ color: "white" }} size={24} />
          ) : isEdited ? (
            "Update"
          ) : (
            "Create"
          )}
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateSpinConfigurationModal;
