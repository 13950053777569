import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Footer = () => {
  const handleGoPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.herogram.animereel&hl=en";
  };

  const handleGoToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/in/app/zumi-movies-and-action/id6476808915";
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box mt={5}>
        <Typography
          className="redHat"
          sx={{ fontSize: "60px", fontWeight: 500 }}
          variant="p"
          data-testid="footer-text"
        >
          Get the <span style={{ fontWeight: 900 }}>Zumi</span> app
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/appStore.svg"
            style={{ height: "120px", cursor: "pointer" }}
            onClick={handleGoToAppStore}
            alt="appStore"
          />
          <img
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
            style={{ height: "55px", cursor: "pointer" }}
            onClick={handleGoPlayStore}
            alt="playStore"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
