import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const NotificationStats = ({ statsOpen, setStatsOpen, stats, setStats }) => {
  return (
    <Dialog
      open={!!statsOpen}
      onClose={() => {
        setStatsOpen(null);
        setStats(null);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Notification campaign stats</DialogTitle>
      <DialogContent>
        {stats?.variantStats ? (
          <Table>
            <TableHead sx={{ backgroundColor: "#f0f0f0" }}>
              <TableRow>
                <TableCell>Variant</TableCell>
                <TableCell>Users reached</TableCell>
                <TableCell>Notifications clicked</TableCell>
                <TableCell>Open rate %</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="all" sx={{ py: 2 }}>
                <TableCell>All variants</TableCell>
                <TableCell>{stats?.totalNotifications}</TableCell>
                <TableCell>{stats?.totalOpenRate}</TableCell>
                <TableCell>{stats?.totalOpenRate}</TableCell>
              </TableRow>
              {stats?.variantStats.map((variant) => (
                <TableRow
                  key={variant.variantId}
                  sx={{ backgroundColor: "#fff" }}
                >
                  <TableCell>Variant {variant.variantId}</TableCell>
                  <TableCell>{variant.totalNotifications}</TableCell>
                  <TableCell>{variant.totalOpenRate}</TableCell>
                  <TableCell>{variant.totalOpenRate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Skeleton variant="rectangular" height={200} width="100%" />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setStatsOpen(null)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationStats;
