import React from "react";
import styles from "./typography.module.css";

const stylesMap = {
  "14_Regular": styles.regular_14,
  "11_Regular": styles.regular_11,
  "13_Regular": styles.regular_13,
  "24_Regular": styles.regular_24,
  "58_Regular": styles.regular_58,
  "16_Regular": styles.regular_16,
  "18_Regular": styles.regular_18,
  "10_Regular": styles.regular_10,
};

const Typo = ({
  as = "p",
  children,
  fontType = "12Regular",
  style,
  ...rest
}) => {
  const Wrapper = as;
  const variantClass = stylesMap[fontType];

  return (
    <Wrapper className={variantClass} style={style} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Typo;
