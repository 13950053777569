import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import StoriesIcon from "../components/icons/StoriesIcon";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReactHlsPlayer from "react-hls-player";
import { useParams } from "react-router-dom";
import API from "../interceptor/API";
import Loader from "../components/Loader";
import Typo from "../components/Typography/Typo";

const ViewInteractiveStory = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isMainVideoCompleted, setIsMainVideoCompleted] = useState(false);
  const [isChoiceVideoCompleted, setIsChoiceVideoCompleted] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [isChoiceVideoSelected, setIsChoiceVideoSelected] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  useEffect(() => {
    if (localStorage?.getItem("currentVideoIndex")) {
      const index = JSON.parse(localStorage.getItem("currentVideoIndex"));
      setVideoIndex(index);
    }
  }, [videoIndex]);

  const handleVideoEnd = () => {
    if (!isMainVideoCompleted) {
      setIsMainVideoCompleted(true);
    } else if (isChoiceVideoSelected && !isChoiceVideoCompleted) {
      setIsChoiceVideoCompleted(true);
    }
  };

  const fetchStoryDetails = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(`/interactive-stories/${id}`);
      if (data && status === 200) {
        setNodes(data?.nodes);
        setVideos(data?.videos);
        setEdges(data?.edges);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStoryDetails();
  }, []);

  const groupedVideos = () => {
    const mainVideos = videos
      ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .filter((video) => video?.title?.includes("Main_"));

    const groupedVideos = mainVideos?.map((main) => {
      const mainNumber = main.title.split("_")[1];

      const choices = videos?.filter((video) => {
        return video?.title?.startsWith(`Choices_${mainNumber[0]}`);
      });
      return {
        main,
        choices,
      };
    });
    return groupedVideos;
  };

  const groups = groupedVideos();

  useEffect(() => {
    if (groups?.length > 0) {
      setSelectedVideo(groups[videoIndex]?.main?.url);
    }
  }, [videos, videoIndex]);

  const handleVideoSelect = (url) => {
    setSelectedVideo(url);
    setIsChoiceVideoSelected(true);
    setIsMainVideoCompleted(true);
    setIsChoiceVideoCompleted(false);
  };

  useEffect(() => {
    if (isChoiceVideoCompleted && videoIndex < groups?.length - 1) {
      setIsMainVideoCompleted(false);
      setIsChoiceVideoCompleted(false);
      setIsChoiceVideoSelected(false);
      setVideoIndex((prev) => prev + 1);
    }
  }, [isChoiceVideoCompleted]);

  useEffect(() => {
    localStorage.setItem("currentVideoIndex", videoIndex);
  }, [videoIndex]);

  useEffect(() => {
    const lastWatchedIndex = groups?.findIndex(
      (group, index) => index === videoIndex && !isMainVideoCompleted
    );
    if (lastWatchedIndex !== -1) {
      setVideoIndex(lastWatchedIndex);
    }
  }, [groups]);

  const filteredNodes = nodes?.filter((node) =>
    groups[videoIndex]?.choices?.find((choice) => choice.id === node.videoId)
  );

  const filteredEdges = edges
    ?.filter((edge) => filteredNodes?.find((n) => n.id === edge.toId))
    ?.filter((edge) => edge?.label !== "");

  const choicesLabels = filteredEdges?.map((edge) => {
    const matchingNode = filteredNodes?.find((node) => node.id === edge.toId);
    return {
      ...edge,
      url: matchingNode?.video?.url,
    };
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Layout pageTitle={"Interactive Story Details"} icon={<StoriesIcon />}>
      {isMainVideoCompleted && !isChoiceVideoSelected ? (
        <Box
          sx={{ position: "relative", height: "calc(100vh - 67px)", top: 0 }}
        >
          <img
            src={
              "https://videolist.ams3.digitaloceanspaces.com/Choices_3A-1720157899329_thumbnail.jpg"
            }
            alt="Cover"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "fit-content",
              background: "#0002094F",
              color: "white",
              backdropFilter: "blur(5px)",
              height: "100vh",
              objectFit: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typo
              as="h1"
              fontType="58_Regular"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                marginBottom: "8px",
              }}
            >
              Choose one of the options
            </Typo>

            <Grid container spacing={3} sx={{ width: "50%", mt: 1 }}>
              {choicesLabels?.map((choice) => (
                <Grid item xs={12} sm={6} md={6} key={choice.id}>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "var(--lighGray)",
                      cursor: "pointer",
                      mt: 1,
                      bgcolor: "var(--lightWhite)",
                      color: "black",
                      p: 1.5,
                      borderRadius: "12px",
                    }}
                    onClick={() => handleVideoSelect(choice?.url)}
                  >
                    {choice?.label}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 58px)",
            position: "relative",
          }}
        >
          <ReactHlsPlayer
            src={selectedVideo}
            autoPlay={!isMainVideoCompleted || isChoiceVideoSelected}
            controls={true}
            muted={false}
            onEnded={handleVideoEnd}
            style={{
              width: "100%",
              objectFit: "cover",
              height: "100%",
            }}
          />
        </Box>
      )}
    </Layout>
  );
};

export default ViewInteractiveStory;
