import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const ConfirmButton = ({ onClick }) => (
  <Button
    variant="contained"
    color="error"
    size="small"
    onClick={onClick}
    sx={{ marginRight: 1 }}
  >
    Yes
  </Button>
);

const CancelButton = ({ onClick }) => (
  <Button variant="contained" size="small" onClick={onClick}>
    No
  </Button>
);

const ConfirmationBox = ({ open, onClose, onConfirm, message }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 4,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {message}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <ConfirmButton onClick={onConfirm} data-testid="confirm-btn" />
          <CancelButton onClick={onClose} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationBox;
