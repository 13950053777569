import React from "react";

const ChannelsIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71752 9.60938C7.61454 9.60938 5.8775 11.1929 5.8775 13.1206V31.8751C5.8775 33.8028 7.61454 35.3878 9.71752 35.3878H35.2824C37.3854 35.3878 39.1224 33.8014 39.1224 31.8751V13.1206C39.1224 11.1929 37.3854 9.60938 35.2824 9.60938H9.71752ZM9.71752 11.9531H35.2824C36.0127 11.9531 36.5656 12.4512 36.5656 13.1206V31.8751C36.5656 32.5446 36.0127 33.0441 35.2824 33.0441H9.71752C8.98721 33.0441 8.43432 32.5446 8.43432 31.8751V13.1206C8.43432 12.4512 8.98725 11.9531 9.71752 11.9531ZM29.1461 17.8095C28.9223 17.8095 28.7018 17.8622 28.5069 17.9648C28.2128 18.12 27.9971 18.3749 27.9092 18.6752C27.8213 18.9755 27.8661 19.2963 28.0355 19.5658C29.178 21.3808 29.178 23.6177 28.0355 25.4327V25.4312C27.8661 25.7008 27.8213 26.0216 27.9092 26.3204C27.9971 26.6207 28.2128 26.8771 28.5069 27.0308C28.8009 27.1861 29.1493 27.2271 29.4769 27.1466C29.8044 27.066 30.0825 26.8682 30.2519 26.5987C31.8499 24.0616 31.8499 20.9344 30.2519 18.3986C30.025 18.0353 29.6031 17.811 29.1461 17.8095ZM15.8572 17.8124C15.7469 17.8124 15.6366 17.8271 15.5312 17.8534C15.2004 17.9311 14.9175 18.1274 14.7465 18.3984C13.1485 20.9355 13.1485 24.0627 14.7465 26.5985C14.9159 26.8695 15.194 27.0658 15.5231 27.1478C15.8507 27.2283 16.2007 27.1873 16.4947 27.0306C16.7888 26.8768 17.0029 26.6204 17.0924 26.3202C17.1803 26.0213 17.1355 25.7005 16.9661 25.431C15.8235 23.6161 15.8235 21.3791 16.9661 19.5641V19.5656C17.1355 19.2961 17.1803 18.9753 17.0924 18.675C17.0029 18.3747 16.7887 18.1198 16.4947 17.9645C16.2998 17.8635 16.0809 17.811 15.8572 17.8124ZM22.4999 21.3281C21.7936 21.3281 21.2215 21.8525 21.2215 22.4999C21.2215 22.8105 21.3558 23.1078 21.5955 23.3276C21.8352 23.5473 22.1596 23.6718 22.4999 23.6718C22.8387 23.6718 23.1631 23.5473 23.4028 23.3276C23.6425 23.1078 23.7783 22.8105 23.7783 22.4999C23.7783 22.1879 23.6425 21.8906 23.4028 21.6708C23.1631 21.4511 22.8387 21.3281 22.4999 21.3281Z"
        fill="black"
      />
    </svg>
  );
};

export default ChannelsIcon;
