import AttractionsIcon from "@mui/icons-material/Attractions";
import { Button, Card, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationBox from "../components/ConfirmationPopup";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import WheelConfigTable from "../components/WheelConfigTable";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import API from "../interceptor/API";
import EditWheelSimulation from "../modals/EditWheelSimulation";
import SimulationAnalytics from "../modals/SimulationAnalytics";
import SpinAllModal from "../modals/SpinAllModal";
import SpinTabsModal from "../modals/SpinTabsModal";
import SimulationIcon from "../components/icons/SimulationIcon";

const SpinningWheelConfiguration = () => {
  const pageSize = 100;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [createSpinModalOpen, setCreateSpinModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [spinToDelete, setSpinToDelete] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isConfigCreating, setIsConfigCreating] = useState(false);
  const [openSpinModal, setOpenSpinModal] = useState(false);
  const [openAnalyticsModal, setOpenAnalyticsModal] = useState(false);
  const [countOfDiamonds, setCountOfDiamonds] = useState(0);
  const [countOfCoins, setCountOfCoins] = useState(0);
  const [totalSpins, setTotalSpins] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [availableSpins, setAvailableSpins] = useState(10);
  const [spinHistory, setSpinHistory] = useState([]);
  const [activeSpinItem, setActiveSpinItem] = useState("tab-1");
  const [spinAll, setSpinAll] = useState(false);
  const [closeSpinAllPopup, setCloseSpinAllPopup] = useState(false);
  const [wheelConfigDataByTab, setWheelConfigDataByTab] = useState({
    "tab-1": [],
  });
  const [spinAnalytics, setSpinAnalytics] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const [configFields, setConfigFields] = useState({
    label: "",
    value: 0,
    percentageDrop: 0,
    type: "",
  });

  const handleDeleteConfirmation = (reward) => {
    setSpinToDelete(reward);
    setDeleteConfirmation(true);
  };

  const handleAddConfig = () => {
    setCreateSpinModalOpen(true);
    setIsEdited(false);
    setConfigFields({
      label: "",
      value: 0,
      percentageDrop: 0,
      type: "",
    });
  };

  const handleEditConfig = (editedConfigFields, index) => {
    setWheelConfigDataByTab((prevState) => {
      const newState = { ...prevState };
      newState[activeSpinItem] = newState[activeSpinItem].map((config, i) => {
        if (i === index) {
          // Update the configFields at the specified index with the edited values

          return { ...editedConfigFields, updatedAt: new Date().toISOString() };
        }
        return config;
      });
      localStorage.setItem("tableData", JSON.stringify(newState));
      return newState;
    });
    setCreateSpinModalOpen(false);
    enqueueSnackbar("Wheel Configuration Updated successfully!", {
      variant: "success",
    });
  };

  const fetchSpinningWheels = async () => {
    const savedData = localStorage.getItem("tableData");
    const parsedData = savedData && JSON.parse(savedData);
    try {
      const { data } = await API.get(`/wheelConfig?page=${page}&pageSize=100`);
      if (parsedData) {
        setWheelConfigDataByTab(parsedData);
      } else {
        localStorage.setItem(
          "tableData",
          JSON.stringify({ "tab-1": data?.wheelConfigs })
        );
        setWheelConfigDataByTab({ "tab-1": data?.wheelConfigs });
      }
      setTotalPages(Math.ceil(data?.pagination?.totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching series data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSpinningWheels();
  }, [page, refresh]);

  const createConfig = async () => {
    if (activeSpinItem !== "tab-1") {
      setWheelConfigDataByTab((prevState) => {
        const newState = { ...prevState };
        if (!newState[activeSpinItem]) {
          newState[activeSpinItem] = [];
        }
        newState[activeSpinItem].push({
          ...configFields,
          createdAt: new Date().toISOString(),
        });
        localStorage.setItem("tableData", JSON.stringify(newState));
        return newState;
      });

      setCreateSpinModalOpen(false);
      enqueueSnackbar("Wheel Configuration Created Successfully!", {
        variant: "success",
      });
      setConfigFields({
        label: "",
        value: "",
        percentageDrop: "",
        type: "",
      });

      return;
    }
    try {
      setIsConfigCreating(true);
      const { data, status } = await API.post(`/wheelConfig`, configFields);
      if (data && status === 200) {
        setRefresh({});
        setCreateSpinModalOpen(false);
        enqueueSnackbar("Wheel Configuration Created Successfully!", {
          variant: "success",
        });
        setConfigFields({});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfigCreating(false);
    }
  };

  const handleDeleteWheelConfig = async (index) => {
    if (activeSpinItem !== "tab-1") {
      setWheelConfigDataByTab((prevState) => {
        const newState = { ...prevState };
        newState[activeSpinItem] = newState[activeSpinItem].filter(
          (_, i) => i !== index
        );
        localStorage.setItem("tableData", JSON.stringify(newState));
        return newState;
      });
      return;
    }
    try {
      const response = API.delete(`/wheelConfig/${spinToDelete.id}`);
      if (response) {
        setRefresh({});
        enqueueSnackbar("Deleted Successfully!", {
          variant: "success",
        });
        setConfigFields({});
      }
    } catch (error) {
      console.error("Error deleting reward:", error);
      enqueueSnackbar("Failed to delete wheel configuration", {
        variant: "error",
      });
    } finally {
      setDeleteConfirmation(false);
    }
  };

  const fetchSpinningDetails = async (id) => {
    setSelectedId(id);
    try {
      const { data, status } = await API.get(`/wheelConfig/${id}`);
      if (data && status === 200) {
        setConfigFields({
          label: data?.label,
          value: data?.value,
          percentageDrop: data?.percentageDrop,
          type: data?.type,
        });
      }
    } catch (error) {
      console.error("Error fetching series data:", error);
    }
  };

  const updateSpinningDetails = async () => {
    if (activeSpinItem !== "tab-1") {
      handleEditConfig(configFields, configFields.index);

      return;
    }
    try {
      setIsConfigCreating(true);
      const response = await API.put(
        `/wheelConfig/${selectedId}`,
        configFields
      );
      if (response) {
        setRefresh({});
        setCreateSpinModalOpen(false);
        enqueueSnackbar("Wheel Configuration Updated successfully!", {
          variant: "success",
        });
        setConfigFields({});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfigCreating(false);
    }
  };

  // Define the segments array with custom probabilities

  const handleTypeChange = (e) => {
    setConfigFields({
      ...configFields,
      type: e.target.value,
    });
  };

  useEffect(() => {
    const storedTotalSpins = localStorage.getItem("totalSpins");
    const storedDiamondCounts = localStorage.getItem("diamondCounts");
    const storedCoinCounts = localStorage.getItem("coinCounts");
    const storedRemainingCounts = localStorage.getItem("remainingSpins");
    const spinHistories = JSON.parse(localStorage.getItem("spinHistory"));
    const winningResult = JSON.parse(localStorage.getItem("winningResult"));

    if (storedTotalSpins) {
      setTotalSpins(parseInt(storedTotalSpins));
    }
    if (storedDiamondCounts) {
      setCountOfDiamonds(parseInt(storedDiamondCounts));
    }
    if (storedCoinCounts) {
      setCountOfCoins(parseInt(storedCoinCounts));
    }
    if (storedRemainingCounts) {
      setAvailableSpins(parseInt(storedRemainingCounts));
    }
    if (spinHistories) {
      setSpinHistory(spinHistories);
    }
    if (winningResult) {
      setSpinAnalytics(winningResult);
    }
  }, []);

  const handleFinished = (segment, id) => {
    enqueueSnackbar(`You win ${segment?.name} ${segment?.type}`, {
      variant: "success",
    });

    const remainingSpins = availableSpins - 1;
    setAvailableSpins(parseInt(remainingSpins));
    localStorage.setItem("remainingSpins", remainingSpins);

    const newTotalSpins = totalSpins + 1;
    setTotalSpins(newTotalSpins);
    localStorage.setItem("totalSpins", newTotalSpins);

    let newDiamondSpins = countOfDiamonds;

    let newCoinSpins = countOfCoins;

    if (segment.type === "Duplicate") {
      setMultiplier(parseInt(segment.value));
    } else if (segment?.type === "Diamonds") {
      newDiamondSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    } else if (segment?.type === "Coins") {
      newCoinSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    } else if (segment?.type === "Experience") {
      newCoinSpins += parseInt(segment?.value) * multiplier;
      setMultiplier(1);
    }

    const winningResult = localStorage.getItem("winningResult");
    const parsedResult = winningResult ? JSON.parse(winningResult) : {};

    const availableKeys = Object.keys(parsedResult);

    if (availableKeys.includes(id)) {
      const updatedData = {
        ...parsedResult,
        [id]: [
          ...parsedResult[id],
          {
            noOfSpin: parsedResult[id].length + 1,
            diamond:
              segment.type === "Diamonds"
                ? parsedResult[id][parsedResult[id].length - 1].diamond +
                  parseInt(segment.value)
                : parsedResult[id][parsedResult[id].length - 1].diamond,
            coins:
              segment.type === "Coins"
                ? parsedResult[id][parsedResult[id].length - 1].coins +
                  parseInt(segment.value)
                : parsedResult[id][parsedResult[id].length - 1].diamond,
          },
        ],
      };
      setSpinAnalytics(updatedData);
      localStorage.setItem("winningResult", JSON.stringify(updatedData));
    } else {
      const updatedData = {
        ...parsedResult,
        [id]: [
          {
            noOfSpin: 1,
            diamond: segment.type === "Diamonds" ? parseInt(segment.value) : 0,
            coins: segment.type === "Coins" ? parseInt(segment.value) : 0,
          },
        ],
      };
      setSpinAnalytics(updatedData);
      localStorage.setItem("winningResult", JSON.stringify(updatedData));
    }

    const updatedSpinHistory = [
      ...spinHistory,
      {
        noOfSpin: newTotalSpins,
        diamond:
          segment.type === "Diamonds" ? newDiamondSpins : countOfDiamonds,
        coins: segment.type === "Coins" ? newCoinSpins : countOfCoins,
      },
    ];

    setSpinHistory(updatedSpinHistory);

    // Update counts of diamonds and coins
    setCountOfDiamonds(newDiamondSpins);
    localStorage.setItem("diamondCounts", newDiamondSpins);

    setCountOfCoins(newCoinSpins);
    localStorage.setItem("coinCounts", newCoinSpins);

    // Store spin history in localStorage
    localStorage.setItem("spinHistory", JSON.stringify(updatedSpinHistory));
  };

  const handleEdit = (config) => {
    setCreateSpinModalOpen(!createSpinModalOpen);
    setIsEdited(true);
    if (activeSpinItem !== "tab-1") {
      return setConfigFields(config);
    }
    fetchSpinningDetails(config?.id);
    setSpinToDelete(config);
  };

  const typeValues = ["Coins", "Diamonds", "Repeat", "Free", "Duplicate"];

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout
      pageTitle="Spinning Wheel Simulations"
      icon={<SimulationIcon fill="#4A4E5A" />}
    >
      <>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <p className="pageHeading" data-testid="page-title">
            Spinning Wheel Simulations
          </p>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              className="secondaryButton"
              onClick={() => {
                setOpenAnalyticsModal(!openAnalyticsModal);
              }}
              data-testid="analytics-btn"
            >
              <AnalyticsIcon fill="black" />
              &nbsp; Analytics
            </Button>
            <Button
              variant="contained"
              className="primaryButton"
              onClick={() => {
                setActiveSpinItem("tab-1");
                setOpenSpinModal(!openSpinModal);
                setSpinAll(false);
              }}
              data-testid="spin-wheel-btn"
            >
              <AttractionsIcon style={{ color: "white", fontSize: "20px" }} />
              &nbsp; Show Spin Wheel
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            borderRadius: "10px",
            mt: 3,
          }}
        >
          <WheelConfigTable
            rowData={wheelConfigDataByTab["tab-1"]}
            handleEdit={handleEdit}
            handleDeleteConfirmation={handleDeleteConfirmation}
          />
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "center",
              m: "20px",
            }}
          />
        </Card>
      </>

      <EditWheelSimulation
        createSpinModalOpen={createSpinModalOpen}
        setCreateSpinModalOpen={setCreateSpinModalOpen}
        configFields={configFields}
        setConfigFields={setConfigFields}
        typeValues={typeValues}
        isConfigCreating={isConfigCreating}
        isEdited={isEdited}
        handleTypeChange={handleTypeChange}
        updateSpinningDetails={updateSpinningDetails}
        createConfig={createConfig}
      />
      <SpinTabsModal
        openSpinModal={openSpinModal}
        setOpenSpinModal={setOpenSpinModal}
        wheelConfigDataByTab={wheelConfigDataByTab}
        activeSpinItem={activeSpinItem}
        setActiveSpinItem={setActiveSpinItem}
        handleFinished={handleFinished}
        handleAddConfig={handleAddConfig}
        handleEdit={handleEdit}
        createSpinModalOpen={createSpinModalOpen}
        spinAll={spinAll}
        setSpinAll={setSpinAll}
        handleDeleteWheelConfig={handleDeleteWheelConfig}
        closeSpinAllPopup={closeSpinAllPopup}
        setCloseSpinAllPopup={setCloseSpinAllPopup}
        setWheelConfigDataByTab={setWheelConfigDataByTab}
      />

      <SpinAllModal
        closeSpinAllPopup={closeSpinAllPopup}
        setCloseSpinAllPopup={setCloseSpinAllPopup}
        setSpinAll={setSpinAll}
      />

      <SimulationAnalytics
        openAnalyticsModal={openAnalyticsModal}
        setOpenAnalyticsModal={setOpenAnalyticsModal}
        spinAnalytics={spinAnalytics}
      />

      <ConfirmationBox
        open={deleteConfirmation}
        onConfirm={handleDeleteWheelConfig}
        onClose={() => setDeleteConfirmation(false)}
        message="Are you sure you want to delete this spin wheel configuration?"
      />
    </Layout>
  );
};

export default SpinningWheelConfiguration;
