import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import API from "../interceptor/API";

const CreateDailyTask = ({
  open,
  close,
  setDayTasks,
  setCreateTaskModalOpen,
  setForceRefresh,
}) => {
  const [dailyTaskFields, setDailyTaskFields] = useState({
    name: "",
    description: "",
    coins: 0,
  });

  const createDailyTask = async () => {
    try {
      const { data, status } = await API.post(
        "/rewards/create-day-tasks",
        dailyTaskFields
      );
      if (data && status === 200) {
        setDayTasks(data);
        setCreateTaskModalOpen(false);
        setForceRefresh({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          py: 2,
        }}
      >
        <h2 data-testid="modal-title" className="modalTitle">
          Create Daily Task
        </h2>
        <TextField
          label="Name"
          fullWidth
          value={dailyTaskFields.name}
          onChange={(e) =>
            setDailyTaskFields({ ...dailyTaskFields, name: e.target.value })
          }
        />
        <TextField
          label="Description"
          fullWidth
          value={dailyTaskFields.description}
          onChange={(e) =>
            setDailyTaskFields({
              ...dailyTaskFields,
              description: e.target.value,
            })
          }
        />
        <TextField
          label="Coins"
          fullWidth
          type="number"
          value={dailyTaskFields.coins}
          onChange={(e) =>
            setDailyTaskFields({ ...dailyTaskFields, coins: e.target.value })
          }
        />

        <Button
          sx={{ mb: 2 }}
          className="primaryButton"
          variant="contained"
          color="primary"
          data-testid="create-modal-btn"
          onClick={() => createDailyTask()}
        >
          Create
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateDailyTask;
