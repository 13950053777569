import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ConfirmationBox from "../components/ConfirmationPopup";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import AlertIcon from "../components/icons/AlertIcon";
import Delete from "../components/icons/Delete";
import SnoozeIcon from "../components/icons/SnoozeIcon";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import API from "../interceptor/API";
import CreateNewAlert from "../modals/CreateNewAlert";
import { ALERTS_TABLE_HEAD } from "../utils/constants";
import CustomPagination from "../components/CustomPagination";

const Alerts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [alerts, setAlerts] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);

  const fetchAlerts = async () => {
    try {
      const { data, status } = await API.get(`/alerts?page=${activePage}`);
      if (data && status === 200) {
        setAlerts(data?.alerts);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, [forceRefresh, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleDeleteConfirmation = (alert) => {
    setAlertToDelete(alert);
    setDeleteConfirmation(true);
  };

  const handleDeleteAlert = async () => {
    try {
      const response = await API.delete(`alerts/${alertToDelete.id}`);
      if (response) {
        setForceRefresh(!forceRefresh);
      }
    } catch (error) {
      console.error("Error deleting alert:", error);
      enqueueSnackbar("Failed to delete alert", { variant: "error" });
    } finally {
      setDeleteConfirmation(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout pageTitle="Alerts" icon={<AlertIcon />}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <h1 className="pageHeading" data-testid="page-title">
          Alerts
        </h1>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <p style={{ fontSize: "12px", fontWeight: 400 }}>
            (compared to 24 hours ago)
          </p>
          <Button
            variant="contained"
            className="primaryButton"
            onClick={() => setOpen(true)}
            data-testid="create-alert-btn"
          >
            <AddvariationIcon /> &nbsp; Create New Alert
          </Button>
        </Box>
      </Box>

      <Card
        sx={{
          borderRadius: "5px",
          mt: 3,
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "lightgray" }}>
            {ALERTS_TABLE_HEAD.map((item, index) => (
              <TableCell key={index}>{item}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            {alerts?.length > 0 &&
              alerts?.map?.((alert, index) => (
                <TableRow
                  key={index}
                  style={{
                    padding: "20px",
                  }}
                >
                  <TableCell data-testid={`event-${index}`}>
                    <TableData>{alert?.event}</TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>{alert?.platform}</TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>{alert?.period} minutes</TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>More than {alert?.percentageDrop}%</TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>{alert?.name}</TableData>
                  </TableCell>
                  <TableCell>
                    <TableData>
                      {moment(alert?.createdAt).format("YYYY-MM-DD")}
                    </TableData>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Tooltip title="Snooze for 30 minutes">
                        <Typography
                          sx={{ cursor: "pointer" }}
                          onClick={async () => {
                            const response = await API.post(
                              `alerts/${alert.id}/snooze`
                            );
                            if (response) {
                              setForceRefresh(!forceRefresh);
                            }
                          }}
                          data-testid={`snooze-btn-${index}`}
                        >
                          <SnoozeIcon />
                        </Typography>
                      </Tooltip>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        data-testid={`delete-btn-${index}`}
                        onClick={() => handleDeleteConfirmation(alert)}
                      >
                        <Delete />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>

      <CustomPagination
        activePage={activePage}
        totalPages={totalPages}
        handleChange={handlePageChange}
      />

      <CreateNewAlert open={open} setOpen={setOpen} />

      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={handleDeleteAlert}
        message="Are you sure you want to delete this alert?"
      />
    </Layout>
  );
};

export default Alerts;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

export const InputStyle = {
  textAlign: "center",
  height: "40px",
  "& input": {
    padding: "8px",
    height: "40px",
  },
  "input::placeholder": {
    color: "var(--placeholderTextColor)",
  },
};
