import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { Fragment } from "react";
import styled from "styled-components";
import { SIDEBAR_ITEMS } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import Typo from "../Typography/Typo";
import { color } from "@mui/system";

const Sidebar = () => {
  const location = useLocation();

  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    window.location.href = "/series";
  };

  return (
    <Holder>
      <a href="/series">
        <Logo>
          <img src="/images/logo.svg" alt="logo" />
        </Logo>
      </a>
      <Select
        placeholder="Select"
        value={"Admin"}
        sx={{
          maxHeight: 40,
          width: "100%",
          mt: 3,
          background: "#F4F4F4",
          borderRadius: "5px",
        }}
        size="small"
      >
        <MenuItem
          value="Admin"
          sx={{ ml: "3px", fontSize: "14px", fontWeight: 500 }}
        >
          <MenuItemContent>
            <img src="/images/profile.svg" alt="profile" />
            Admin
          </MenuItemContent>
        </MenuItem>
        <div
          style={{
            marginTop: "10px",
            borderBottom: "1px solid #EDEDED",
          }}
        />
        <MenuItem
          value="SJ"
          sx={{ ml: "3px", fontSize: "14px", fontWeight: 500 }}
          onClick={handleLogout}
        >
          <MenuItemContent>
            <img src="/images/logout.svg" alt="profile" />
            Log Out
          </MenuItemContent>
        </MenuItem>
      </Select>

      <div
        style={{
          marginTop: "10px",
          borderBottom: "1px solid #EDEDED",
        }}
      />

      <LinksHolder>
        <Links>
          {SIDEBAR_ITEMS.filter((item) =>
            item?.items?.some((subItem) =>
              subItem?.allowed?.some((allowed) =>
                localStorage.getItem("permissions")?.includes(allowed)
              )
            )
          ).map((item, index) => (
            <Fragment key={item.title}>
              <Box key={index}>
                <Typo
                  data-testid={`nav-item-${index}`}
                  as="h2"
                  fontType="11_Regular"
                  style={{ color: "#767676" }}
                >
                  {item?.title}
                </Typo>
                <div style={{ marginTop: "14px" }}>
                  {item?.items
                    ?.filter((subItem) =>
                      subItem?.allowed?.some((allowed) =>
                        localStorage.getItem("permissions")?.includes(allowed)
                      )
                    )
                    ?.map((subItem, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            border:
                              location?.pathname?.includes(subItem?.href) &&
                              `1px solid #EDEDED`,
                            px: 1,
                            py: "4px",
                            background:
                              location?.pathname?.includes(subItem?.href) &&
                              "#F4F4F4",
                            borderRadius: "5px",
                            mt: "3px",
                            ":hover": {
                              backgroundColor: "#F4F4F4",
                            },
                          }}
                        >
                          <SubMenu href={subItem.href}>
                            {React.cloneElement(subItem.icon, {
                              fill: location?.pathname?.includes(subItem?.href)
                                ? "#605BFF"
                                : "#26262A",
                            })}

                            <Typography
                              sx={{
                                color: location?.pathname?.includes(
                                  subItem?.href
                                )
                                  ? `#605BFF`
                                  : "#26262A",
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {subItem.title}
                            </Typography>
                          </SubMenu>
                        </Box>
                      );
                    })}
                </div>
              </Box>
              {SIDEBAR_ITEMS?.length !== index + 1 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    borderBottom: "1px solid #EDEDED",
                  }}
                />
              )}
            </Fragment>
          ))}
        </Links>
      </LinksHolder>
    </Holder>
  );
};

export default Sidebar;

const SubMenu = styled.a`
  display: flex;
  align-items: center;
  // margin-top: 10px;
  gap: 8px;
  text-decoration: none;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  // background-color: #f4f4f4;
`;

const Holder = styled.div`
  width: 248px;
  position: fixed;
  left: 0;
  padding-top: 20px;
  bottom: 0;
  top: 0;
  height: 100vh;
  border-right: 1px solid #eee;
  overflow: scroll;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #fff;
`;

const LinksHolder = styled.div``;

const Logo = styled.div`
  // background: #eee;
  width: 100%;
  height: 40px;
  display: flex;
  font-size: 30px;
  font-weight: 900;
  color: #6366f1;
`;

const Links = styled.ul`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CustomLink = styled.a`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition-duration: 0.2s;
  border: 1px solid transparent;

  &:hover {
    transition-duration: 0.2s;
    background-color: #eee;
    border-color: #ccc;
  }

  ${(props) =>
    props.active
      ? `
    background-color: #6366f1 !important;
    border-color: #6366f1 !important;

    svg {
        path {
            fill: #fff !important;
        }
    }
  `
      : null}
`;
