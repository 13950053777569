import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import API from "../interceptor/API";

const SpinStatsModal = ({
  openStatsModal,
  setOpenStatsModal,
  selectedUserId,
}) => {
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [range, setRange] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  const dateFormatter = (time) => {
    // return as hours:minutes
    return `${Math.floor(time / 1000 / 60 / 60) % 24}
        ${(Math.floor(time / 1000 / 60) % 60).toString().padStart(2, "0")}:${(
      Math.floor(time / 1000) % 60
    )
      .toString()
      .padStart(2, "0")}`;
  };

  const handleMouseDown = (e) => {
    setStartPoint(e.activeLabel);
  };

  const handleMouseMove = (e) => {
    startPoint && setEndPoint(e?.activeLabel);
  };

  const handleMouseUp = (e) => {
    setRange({
      startDate: new Date(Math.min(startPoint, e.activeLabel)),
      endDate: new Date(Math.max(startPoint, e.activeLabel)),
      key: "selection",
    });
    setStartPoint(null);
    setEndPoint(null);
  };

  const fetchSpinDetails = async () => {
    setLoadingAnalytics(true);
    try {
      const { data, status } = await API.get(
        `/users/${selectedUserId}/with-spins`
      );
      if (data && status === 200) {
        setUserData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAnalytics(false);
    }
  };

  useEffect(() => {
    setRange(null);
    if (!selectedUserId) {
      return;
    }
    fetchSpinDetails();
  }, [selectedUserId]);

  return (
    <Modal
      open={openStatsModal}
      onClose={() => {
        setOpenStatsModal(false);
      }}
      style={{ overflow: "hidden" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 650,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
        }}
      >
        <h2 className="modalTitle" data-testid="spin-analytics">
          User's Spin Analytics
        </h2>
        {loadingAnalytics ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
              alignItems: "center",
            }}
          >
            {range ? (
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <Typography>
                  {`Selected range: ${dateFormatter(
                    range.startDate
                  )} - ${dateFormatter(range.endDate)}`}
                </Typography>
                <Button onClick={() => setRange(null)}>Clear range</Button>
              </Box>
            ) : (
              <Typography>Click and drag to select a range</Typography>
            )}

            <ResponsiveContainer
              width="100%"
              height={250}
              style={{ marginTop: "20px" }}
            >
              <ComposedChart
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              >
                <XAxis
                  dataKey="date"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={dateFormatter}
                  // make smaller
                  tick={{ fontSize: 12 }}
                  // show more ticks
                  interval={0}
                  // show more ticks
                  tickCount={20}
                />
                <YAxis
                  orientation="left"
                  stroke="#ff7300"
                  type="number"
                  domain={[100, "auto"]}
                  tick={{ fontSize: "12px" }}
                />
                <Line
                  data={userData?.diamonds
                    ?.map((d) => ({
                      date: d.date - userData?.diamonds[0].date,
                      diamonds: d.diamonds / 100,
                    }))
                    ?.filter(
                      (d) =>
                        !range ||
                        (d.date >= range.startDate && d.date <= range.endDate)
                    )}
                  type="monotone"
                  dataKey="diamonds"
                  // yellow stroke
                  stroke="#ffc658"
                  name="Diamonds"
                />
                <Line
                  data={userData?.coins
                    ?.map((d) => ({
                      date: d.date - userData?.coins[0].date,
                      coins: d?.coins / 100,
                    }))
                    ?.filter(
                      (d) =>
                        !range ||
                        (d.date >= range.startDate && d.date <= range.endDate)
                    )}
                  type="monotone"
                  dataKey="coins"
                  // black stroke
                  stroke="#000000"
                  name="Coins"
                />
                {startPoint && endPoint && (
                  <ReferenceArea x1={startPoint} x2={endPoint} />
                )}
                {/* render x amount of lines based on data.videos (date property) */}
                {userData?.videos?.map((video, index) => (
                  <ReferenceLine
                    key={index}
                    x={video.date - userData.diamonds[0].date}
                    // red stroke
                    stroke="#ff0000"
                    strokeDasharray="3 3"
                  />
                ))}
                <Tooltip />
                <Legend />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SpinStatsModal;
