import { Box, Button, IconButton, TextField, Typography } from "@mui/material";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthCard from "../components/AuthCard";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import API from "../interceptor/API";

const Login2 = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleUserDetails = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const { username, password } = formData;

  const handleLogin = async () => {
    try {
      const { data, status } = await API.post("/login-admin", formData);
      if (data && status === 200) {
        if (data && status === 200) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("permissions", data.permissions);
          localStorage.setItem("userId", data.id);
          localStorage.setItem("role", "admin");
          window.location.href = "/series";
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <AuthCard
        title="Sign In"
        description=" Join us now for exclusive benefits, personalized services, and
              seamless experience!"
      >
        <Typography>Username</Typography>
        <TextField
          label="Username"
          onChange={(e) => handleUserDetails(e, "username")}
          value={username}
          data-testid="username"
        />
        <Typography>Password</Typography>
        <TextField
          label="Password"
          type={showPassword ? "password" : "text"}
          onChange={(e) => handleUserDetails(e, "password")}
          value={password}
          data-testid="password"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff sx={{ fontSize: "14px" }} />
                ) : (
                  <Visibility sx={{ fontSize: "14px" }} />
                )}
              </IconButton>
            ),
          }}
        />

        {/* error message */}
        {error && (
          <Box sx={{ color: "red", textAlign: "center", fontSize: "12px" }}>
            Invalid username or password
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          className="primaryButton"
          onClick={handleLogin}
        >
          Login
        </Button>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "12px",
            fontWeight: 400,
            color: "var(--subHeadingColor)",
          }}
        >
          Don’t have an account?{" "}
          <span
            style={{
              color: "var(--primaryBlue)",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/register")}
          >
            Signup
          </span>
        </Typography>
      </AuthCard>
    </>
  );
};

export default Login2;
