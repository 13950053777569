import React from "react";

const ClicksIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.85 9C5.05 8.95833 4.375 8.65 3.825 8.075C3.275 7.5 3 6.80833 3 6C3 5.16667 3.29167 4.45833 3.875 3.875C4.45833 3.29167 5.16667 3 6 3C6.80833 3 7.5 3.275 8.075 3.825C8.65 4.375 8.95833 5.05 9 5.85L7.95 5.5375C7.84167 5.0875 7.60833 4.71883 7.25 4.4315C6.89167 4.14417 6.475 4.00033 6 4C5.45 4 4.97917 4.19583 4.5875 4.5875C4.19583 4.97917 4 5.45 4 6C4 6.475 4.14383 6.89167 4.4315 7.25C4.71917 7.60833 5.08783 7.84167 5.5375 7.95L5.85 9ZM6.45 10.975C6.375 10.9917 6.3 11 6.225 11H6C5.30833 11 4.65833 10.8687 4.05 10.606C3.44167 10.3433 2.9125 9.98717 2.4625 9.5375C2.0125 9.08783 1.65633 8.55867 1.394 7.95C1.13167 7.34133 1.00033 6.69133 1 6C0.999667 5.30867 1.131 4.65867 1.394 4.05C1.657 3.44133 2.01317 2.91217 2.4625 2.4625C2.91183 2.01283 3.441 1.65667 4.05 1.394C4.659 1.13133 5.309 1 6 1C6.691 1 7.341 1.13133 7.95 1.394C8.559 1.65667 9.08817 2.01283 9.5375 2.4625C9.98683 2.91217 10.3432 3.44133 10.6065 4.05C10.8698 4.65867 11.001 5.30867 11 6V6.225C11 6.3 10.9917 6.375 10.975 6.45L10 6.15V6C10 4.88333 9.6125 3.9375 8.8375 3.1625C8.0625 2.3875 7.11667 2 6 2C4.88333 2 3.9375 2.3875 3.1625 3.1625C2.3875 3.9375 2 4.88333 2 6C2 7.11667 2.3875 8.0625 3.1625 8.8375C3.9375 9.6125 4.88333 10 6 10H6.15L6.45 10.975ZM10.2625 11.25L8.125 9.1125L7.5 11L6 6L11 7.5L9.1125 8.125L11.25 10.2625L10.2625 11.25Z"
        fill="#70777F"
      />
    </svg>
  );
};

export default ClicksIcon;
