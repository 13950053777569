import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import API from "../interceptor/API";

const processData = (data) => {
  const endpoints = {};
  data.forEach((item) => {
    const key = item.endpoint;
    if (!endpoints[key]) {
      endpoints[key] = [];
    }
    // Convert string values to numbers
    endpoints[key].push({
      hourly: item.hourly,
      count: parseInt(item.count, 10), // Convert count to a number
      average_time: parseFloat(item.average_time), // Convert average_time to a number
      max_time: parseFloat(item.max_time), // Convert max_time to a number
      min_time: parseFloat(item.min_time), // Convert min_time to a number
    });
  });
  return endpoints;
};

const ApiStats = () => {
  const [apiStats, setApiStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchApiStats = async () => {
    try {
      const { data, status } = await API.get("/api-stats");
      if (data && status === 200) {
        const processedData = processData(data);
        const sortedEndpoints = Object.keys(processedData)
          .map((key) => ({
            endpoint: key,
            data: processedData[key],
            lastHourCount:
              processedData[key][processedData[key].length - 1].count,
          }))
          .sort((a, b) => b.lastHourCount - a.lastHourCount); // Sort based on the last hour's count

        // Convert back to an object if necessary or use directly as an array
        const sortedData = {};
        sortedEndpoints.forEach((item) => {
          sortedData[item.endpoint] = item.data;
        });
        setApiStats(sortedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApiStats();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout pageTitle="API Stats" icon={<AnalyticsIcon fill="#4A4E5A" />}>
      <div style={{ width: "100%", height: 300, marginTop: "30px" }}>
        <h1 className="pageHeading" data-testid="api-stats">
          API Stats
        </h1>
        <Card
          sx={{
            borderRadius: "5px",
            border: "1px solid var(--borderColor)",
            mt: 3,
          }}
        >
          {Object.keys(apiStats).map((endpoint) => (
            <div key={endpoint} style={{ padding: "10px 20px" }}>
              <h3 className="pageHeading" style={{ marginBottom: "20px" }}>
                {endpoint}
              </h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={apiStats[endpoint]}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="hourly" tick={{ fontSize: "10px" }} />
                  <YAxis
                    label={{
                      value: "Time (ms)",
                      angle: -90,
                      position: "insideLeft",
                    }}
                    tick={{ fontSize: "10px" }}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={{
                      value: "Count",
                      angle: -90,
                      position: "insideRight",
                    }}
                    tick={{ fontSize: "10px" }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="average_time"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="max_time" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="min_time" stroke="#ff7300" />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="count"
                    stroke="#ffc658"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ))}
        </Card>
      </div>
    </Layout>
  );
};

export default ApiStats;
