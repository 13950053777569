import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import API from "../interceptor/API";
import SpinStatsModal from "../modals/SpinStatsModal";
import SpinStats from "../components/icons/SpinStats";
import CustomPagination from "../components/CustomPagination";

const UserSpinStats = () => {
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openStatsModal, setOpenStatsModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchSpinUsers = async () => {
    try {
      setLoadingUsers(true);
      const { data, status } = await API.get(`/spin-users?page=${activePage}`);
      if (data && status === 200) {
        setUsers(data?.users);
        setTotalPages(data?.pagination?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    fetchSpinUsers();
  }, [activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  if (loadingUsers) {
    return <Loader />;
  }

  return (
    <Layout pageTitle={"User Spin Stats"} icon={<SpinStats />}>
      <Box mt={4}>
        <h2 className="pageHeading" data-testid="page-title">
          User Spin Stats
        </h2>
      </Box>
      <Card
        sx={{
          mt: 3,
          borderRadius: "5px",
          border: "1px solid var(--borderColor)",
        }}
      >
        <Table>
          <TableHead>
            <TableCell>User ID</TableCell>
            <TableCell>Spin Count</TableCell>
          </TableHead>
          <TableBody>
            {users?.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  padding: "20px",
                  cursor: "pointer",
                }}
              >
                <TableCell
                  onClick={() => {
                    setSelectedUserId(item.id);
                    setOpenStatsModal(!openStatsModal);
                  }}
                >
                  <TableData data-testid={`id-${index}`}>{item.id}</TableData>
                </TableCell>
                <TableCell data-testid={`spin-count-${index}`}>
                  <TableData>{item?.spinCount}</TableData>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SpinStatsModal
          selectedUserId={selectedUserId}
          openStatsModal={openStatsModal}
          setOpenStatsModal={setOpenStatsModal}
        />
      </Card>
      <CustomPagination
        activePage={activePage}
        totalPages={totalPages}
        handleChange={handlePageChange}
      />
    </Layout>
  );
};

export default UserSpinStats;

const TableData = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
