import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationBox from "../components/ConfirmationPopup";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import AddvariationIcon from "../components/icons/AddvariationIcon";
import Delete from "../components/icons/Delete";
import RewardsIcon from "../components/icons/RewardsIcon";
import CreateDailyTask from "../modals/CreateDailyTask";
import API from "../interceptor/API";
import EditIcon from "../components/icons/EditIcon";

const DailyRewards = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dayTasks, setDayTasks] = useState([]);
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [rewardsToDelete, setRewardToDelete] = useState(null);

  const fetchDayTasks = async () => {
    try {
      const { data, status } = await API.get("/rewards/get-day-tasks");
      if (data && status === 200) {
        setDayTasks(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDayTasks();
  }, [forceRefresh]);

  const handleDeleteConfirmation = (reward) => {
    setRewardToDelete(reward);
    setDeleteConfirmation(true);
  };

  const handleDeleteReward = async () => {
    try {
      const { data, status } = await API.delete(
        `/rewards/daily-task/${rewardsToDelete.id}`
      );

      if (data && status === 200) {
        setDayTasks(data);
        setForceRefresh({});
      }
    } catch (error) {
      console.error("Error deleting reward:", error);
      enqueueSnackbar("Failed to delete reward", { variant: "error" });
    } finally {
      setDeleteConfirmation(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout pageTitle="Daily Rewards" icon={<RewardsIcon fill="#4A4E5A" />}>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              data-testid="page-title"
              sx={{ fontSize: "14px", fontWeight: 700 }}
            >
              Daily Rewards
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: "var(--subHeadingColor)",
                mt: 1,
              }}
            >
              Daily rewards are tasks that users can complete to earn rewards.
              The tasks are reset every day.
            </Typography>
          </Box>
          <Button
            variant="contained"
            className="primaryButton"
            onClick={() => setCreateTaskModalOpen(true)}
            data-testid="create-task-btn"
          >
            <AddvariationIcon /> &nbsp; Create Daily Task
          </Button>
        </Box>

        <Grid container mt={3} spacing={3}>
          {dayTasks?.length > 0 &&
            dayTasks?.map((task, index) => (
              <Grid item key={index} xs={12} sm={4} md={3}>
                <Card
                  sx={{
                    px: 3,
                    py: 2,
                    borderRadius: "5px",
                    border: "1px solid var(--borderColor)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                      data-testid={`reward-name-${index}`}
                    >
                      {task.name}
                    </Typography>
                    {task.deletable && (
                      <Typography data-testid={`delete-btn-${index}`}>
                        <Delete
                          onClick={() => handleDeleteConfirmation(task)}
                        />
                      </Typography>
                    )}
                    <Typography data-testid={`edit-icon-${index}`}>
                      <EditIcon onClick={() => console.log("edit")} />
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 500,
                      color: "var(--subHeadingColor)",
                      mt: "4px",
                    }}
                  >
                    {task.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>

        <Box mt={2}>
          {dayTasks.length === 0 && (
            <Typography variant="body1">No daily tasks available.</Typography>
          )}
        </Box>
      </Box>
      <CreateDailyTask
        open={createTaskModalOpen}
        close={() => setCreateTaskModalOpen(false)}
        setDayTasks={setDayTasks}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        setForceRefresh={setForceRefresh}
      />
      <ConfirmationBox
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={handleDeleteReward}
        message="Are you sure you want to delete this reward?"
      />
    </Layout>
  );
};

export default DailyRewards;
